var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _a, _FloatingViewer_viewer, _FloatingViewer_close, _FloatingViewer_open, _FloatingViewer_fullScreen, _FloatingViewer_curr_pages, _FloatingViewer_all_pages, _FloatingViewer_selected_color, _FloatingViewer_MAX_PAGE, _FloatingViewer_MIN_PAGE, _FloatingViewer_pageContainer, _FloatingViewer_cb, _FloatingViewer_button_cb, _FloatingViewer_toggle;
import $ from "cash-dom";
import Canvas from "./canvas";
export default class FloatingViewer {
    constructor(loadingFinishedCB, toggleNextButtonCB, silent = false) {
        _FloatingViewer_viewer.set(this, void 0);
        _FloatingViewer_close.set(this, void 0);
        _FloatingViewer_open.set(this, void 0);
        _FloatingViewer_fullScreen.set(this, void 0);
        _FloatingViewer_curr_pages.set(this, void 0);
        _FloatingViewer_all_pages.set(this, void 0);
        _FloatingViewer_selected_color.set(this, void 0);
        _FloatingViewer_MIN_PAGE.set(this, 20);
        _FloatingViewer_pageContainer.set(this, void 0);
        _FloatingViewer_cb.set(this, void 0);
        _FloatingViewer_button_cb.set(this, void 0);
        this.initArrow = () => {
            if (localStorage.getItem('arrow') !== 'false') {
                $('.help-fl').removeClass('d-none');
                $('.help-fl').addClass('enter');
            }
        };
        this.getCurrentWebPage = () => {
            const splitted = window.location.pathname.split("/");
            return splitted[splitted.length - 1];
        };
        this.removeAllListener = () => {
            __classPrivateFieldGet(this, _FloatingViewer_pageContainer, "f").each((i, div) => {
                $(div).off('click');
            });
        };
        this.onPageClickBind = () => {
            this.removeAllListener();
            __classPrivateFieldGet(this, _FloatingViewer_pageContainer, "f").each((i, div) => {
                $(div).on('click', (e) => {
                    var _b;
                    if ($(e.target).hasClass('hover-delete')) {
                        const index = parseInt(((_b = $(e.target).attr('id')) === null || _b === void 0 ? void 0 : _b.split('-')[2]) || '-1');
                        $('#' + e.target.id).removeClass('hover-delete');
                        if (this.floatingData.pages[index] !== null) {
                            $(e.target).empty();
                            this.floatingData.pages[index] = null;
                            this.floatingData.images[index] = null;
                            if (this.getCurrentWebPage() === 'pages' && !this.isMinimumSelected()) {
                                __classPrivateFieldGet(this, _FloatingViewer_button_cb, "f").call(this, false);
                            }
                        }
                        this.updateSelectedPageCount();
                        this.save(true);
                        this.renderFloatingViewerPages(true);
                    }
                });
            });
        };
        this.isCoverSelected = () => {
            return this.floatingData.pages[0] !== null;
        };
        this.isMinimumSelected = () => {
            return this.floatingData.pages.filter((p) => {
                return p !== null;
            }).length >= __classPrivateFieldGet(this, _FloatingViewer_MIN_PAGE, "f");
        };
        this.createEmptyFloatingPages = () => {
            __classPrivateFieldGet(this, _FloatingViewer_pageContainer, "f").empty();
            __classPrivateFieldGet(this, _FloatingViewer_pageContainer, "f").append($(`<div id="floating-page-cover" style="display: flex"></div>`));
            for (let i = 0; i <= __classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE) - 1; i++) {
                let pageDiv = $(`<div id="floating-page-${i}" style="display: flex"></div>`);
                __classPrivateFieldGet(this, _FloatingViewer_pageContainer, "f").append(pageDiv);
            }
            this.onPageClickBind();
        };
        this.renderFloatingViewerPages = (update = false) => {
            var _b, _c;
            if (this.floatingData !== null) {
                for (let i = 0; i <= __classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE) - 1; i++) {
                    if (!update) {
                        if (this.floatingData.pages[i] !== null) {
                            /*const container = 'floating-page-' + i;
    
                            const canvas = new Canvas(container, load.pages[i], 'smallCover', false, null,null);
                            this.pages[i] = canvas.getStage();*/
                            if (((_b = this.floatingData.images[i]) === null || _b === void 0 ? void 0 : _b.small) !== null && ((_c = this.floatingData.images[i]) === null || _c === void 0 ? void 0 : _c.big) !== null) {
                                this.updateFloatingPageItem(i);
                            }
                        }
                    }
                    else {
                        if (this.floatingData.images[i] !== null) {
                            const pageDiv = $(`#floating-page-${i}`);
                            const nextDiv = $(`#floating-page-${i + 1}`);
                            $('.pages > div').removeClass('hover-delete');
                            if (pageDiv.contents().length === 0 && nextDiv.contents().length !== 0) {
                                pageDiv.append(nextDiv.contents());
                                nextDiv.empty();
                                //nextDiv.removeClass('hover-delete')
                            }
                            $('.pages > div').each((i, div) => {
                                if ($(div).contents().length !== 0) {
                                    $(div).addClass('hover-delete');
                                }
                            });
                        }
                    }
                }
            }
        };
        this.updateFloatingPageItem = (id) => {
            var _b, _c, _d, _e;
            const div = $(`#floating-page-${id === -1 ? 'cover' : id}`);
            if (div.length === 0)
                return;
            if (this.floatingData.images[id] || (id === -1 && this.floatingData.coverImage)) {
                div.empty();
                let smallIMG = new Image();
                let bigIMG = new Image();
                smallIMG.onload = function () {
                    div.append(smallIMG);
                    div.addClass('hover-delete');
                    $(smallIMG).addClass('small-img-preview d-block');
                };
                bigIMG.onload = function () {
                    div.append(bigIMG);
                    div.addClass('hover-delete');
                    $(bigIMG).addClass('big-img-preview d-none');
                };
                smallIMG.src = (id !== -1 ? (_b = this.floatingData.images[id]) === null || _b === void 0 ? void 0 : _b.small : (_c = this.floatingData.coverImage) === null || _c === void 0 ? void 0 : _c.small) || "";
                bigIMG.src = (id !== -1 ? (_d = this.floatingData.images[id]) === null || _d === void 0 ? void 0 : _d.big : (_e = this.floatingData.coverImage) === null || _e === void 0 ? void 0 : _e.big) || "";
            }
        };
        _FloatingViewer_toggle.set(this, (bool, className) => {
            if (bool && !__classPrivateFieldGet(this, _FloatingViewer_viewer, "f").hasClass(className)) {
                __classPrivateFieldGet(this, _FloatingViewer_viewer, "f").addClass(className);
                $('body').removeClass('noscroll');
                if (className === 'open' && this.isFullScreen)
                    $('body').addClass('noscroll');
            }
            else {
                if (className === 'open')
                    $('body').removeClass('noscroll');
                __classPrivateFieldGet(this, _FloatingViewer_viewer, "f").removeClass(className);
            }
        });
        this.visible = (toggle) => {
            if (this.valid) {
                this.disableScrollFor(1000, __classPrivateFieldGet(this, _FloatingViewer_pageContainer, "f"));
                __classPrivateFieldGet(this, _FloatingViewer_toggle, "f").call(this, toggle, 'open');
            }
            else
                throw new Error('Not valid instance!');
        };
        this.disableScrollFor = (time, el) => {
            el.addClass('noscroll');
            setTimeout(() => {
                el.removeClass('noscroll');
            }, time);
        };
        this.fullScreen = (toggle) => {
            if (this.valid) {
                this.isFullScreen = toggle;
                __classPrivateFieldGet(this, _FloatingViewer_toggle, "f").call(this, toggle, 'full');
                if (toggle) {
                    $('.navbar').addClass('forceZ');
                    this.disableScrollFor(1000, __classPrivateFieldGet(this, _FloatingViewer_pageContainer, "f"));
                    $('.pages').addClass('full');
                    $('.big-img-preview').removeClass('d-none');
                    $('.small-img-preview').addClass('d-none');
                    $('body').addClass('noscroll');
                }
                else {
                    $('.navbar').removeClass('forceZ');
                    this.disableScrollFor(1000, __classPrivateFieldGet(this, _FloatingViewer_pageContainer, "f"));
                    $('body').removeClass('noscroll');
                    $('.pages').removeClass('full');
                    $('.big-img-preview').addClass('d-none');
                    $('.small-img-preview').removeClass('d-none');
                }
            }
            else
                throw new Error('Not valid instance!');
        };
        this.scalePage = (page, state, width = null, height = null, scaleX = null, scaleY = null) => {
            if (page !== null) {
                const container = $(page.attrs.container);
                if (state === 'up') {
                    container.css('width', width === null ? 350 : width);
                    container.css('height', height === null ? 400 : height);
                    page.scale({ x: scaleX === null ? 1 : scaleX, y: scaleY === null ? 1 : scaleY });
                    page.setAttrs({ width: width === null ? 350 : width, height: height === null ? 400 : height });
                }
                else if (state === 'down') {
                    container.css('width', 50);
                    container.css('height', 57);
                    page.scale({ x: 1 / 7, y: 1 / 7 });
                    page.setAttrs({ width: 50, height: 57 });
                }
                else {
                    if (height !== null && width !== null) {
                        const C_NUM = 3.7795275591;
                        const new_width = width * C_NUM;
                        const new_height = height * C_NUM;
                        const new_scale_X = new_width / 350;
                        const new_scale_Y = new_height / 400;
                        page.scale({ x: new_scale_X, y: new_scale_Y });
                        page.setAttrs({ width: new_width, height: new_height });
                    }
                }
            }
        };
        this.setColor = (color, save = true) => {
            if (color < 0 || color > 4 || color === null)
                color = 0;
            __classPrivateFieldGet(this, _FloatingViewer_selected_color, "f").html(this.COLOR_MAP[color]);
            $('#colors > div').removeClass('selected');
            $('#colors > div[data-color-id="' + color + '"]').addClass('selected');
            this.floatingData.selectedColor = this.COLOR_MAP[color];
            save && this.save();
        };
        this.updateSelectedPageCount = () => {
            let validPages = 0;
            this.floatingData.pages.map(x => x !== null && validPages++);
            __classPrivateFieldGet(this, _FloatingViewer_curr_pages, "f").html("" + validPages);
        };
        this.toggleHoverDelete = (toggle, domId) => {
            let pageContainer = $('#' + domId);
            if (pageContainer) {
                toggle ? pageContainer.addClass('hover-delete') : pageContainer.removeClass('hover-delete');
            }
        };
        this.getNextEmptySpace = () => {
            for (let i = 0; i < this.floatingData.pages.length; i++) {
                if (this.floatingData.pages[i] === null)
                    return i;
            }
            return -1;
        };
        this.addPage = (page, isCover = false) => {
            var _b;
            let clone = page.clone();
            let domId = 'floating-page-cover';
            let pageId;
            if (isCover) {
                (_b = $('#' + domId + 'cover').children()[0]) === null || _b === void 0 ? void 0 : _b.remove();
                this.floatingData.cover = clone;
            }
            else {
                pageId = this.getNextEmptySpace();
                if (pageId !== -1) {
                    domId = `floating-page-${pageId}`;
                    this.floatingData.pages[pageId] = clone;
                }
            }
            if (isCover || pageId !== -1) {
                this.toggleHoverDelete(true, domId);
                this.updateSelectedPageCount();
                this.scalePage(clone, 'up', 700, 800, 2, 2);
                const bigData = clone.toDataURL();
                this.scalePage(clone, 'down');
                const smallData = clone.toDataURL();
                this.scalePage(clone, 'custom', 150, 210);
                if (!isCover) {
                    this.floatingData.images[pageId] = {
                        big: bigData,
                        small: smallData
                    };
                }
                else {
                    this.floatingData.coverImage = {
                        big: bigData,
                        small: smallData
                    };
                }
                let smallIMG = new Image();
                let bigIMG = new Image();
                const pageDiv = $('#' + domId);
                smallIMG.onload = function () {
                    pageDiv.empty();
                    pageDiv.append(smallIMG);
                    pageDiv.addClass('hover-delete');
                    $(smallIMG).addClass('small-img-preview d-block');
                };
                bigIMG.onload = function () {
                    pageDiv.append(bigIMG);
                    pageDiv.addClass('hover-delete');
                    $(bigIMG).addClass('big-img-preview d-none');
                };
                smallIMG.src = smallData;
                bigIMG.src = bigData;
                this.save();
                //this.renderFloatingViewerPages(true);
            }
        };
        this.createIMGPreviewFromCanvasObject = (data) => {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                const id = Math.floor(Math.random() * 500);
                const canvasId = 'hidden_canvas_' + id;
                const div = $(`<div id="${canvasId}" style="position: absolute; bottom: 9999px"></div>`);
                $('body').append(div);
                const c = new Canvas(canvasId, data, "preview", false, null, () => {
                    this.scalePage(c.getStage(), 'up', 700, 800, 2, 2);
                    const bigData = c.getStage().toDataURL();
                    this.scalePage(c.getStage(), 'down');
                    const smallData = c.getStage().toDataURL();
                    div.detach();
                    resolve({ small: smallData, big: bigData });
                }, null);
            }));
        };
        this.addLoaderToLoadablePages = (cover, pages) => {
            if (cover !== null) {
                $('#floating-page-cover').append(`<img id="floating-page-cover-loader" src="${window.location.origin}/images/loader.gif" width="50px" height="50px" />`);
            }
            for (let i = 0; i < pages.length; i++) {
                if (pages[i] !== null) {
                    $('#floating-page-' + i).append(`<img id="floating-page-${i}-loader" src="${window.location.origin}/images/loader.gif" width="50px" height="50px" />`);
                }
            }
        };
        this.save = (rearrange = false) => {
            if (rearrange)
                this.reArrangeOrder();
            let floatingViewerObject = {
                pages: this.fillWithNull(this.floatingData.pages),
                color: this.floatingData.selectedColor,
                cover: this.floatingData.cover
            };
            return new Promise((resolve, reject) => {
                fetch(window.location.origin + '/session/floating_viewer', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') || ""
                    },
                    body: JSON.stringify(floatingViewerObject)
                })
                    .then(response => response.json())
                    .then(r => {
                }).catch(err => reject(err));
            });
        };
        this.fillWithNull = (pagesArray) => {
            let noData = 0;
            if (pagesArray.length !== __classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE)) {
                for (let i = 0; i < __classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE); i++) {
                    if (!pagesArray[i] && i <= __classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE) - (noData + 1)) {
                        pagesArray.push(null);
                    }
                    else {
                        noData = i;
                    }
                }
            }
            return pagesArray;
        };
        this.reArrangeOrder = () => {
            const images = this.floatingData.images.filter((i) => {
                return i !== null;
            });
            let newImagesArray = [...images, ...(new Array(__classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE) - images.length).fill(null))];
            const pages = this.floatingData.pages.filter((p) => {
                return p !== null;
            });
            let newPagesArray = [...pages, ...(new Array(__classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE) - images.length).fill(null))];
            this.floatingData.pages = newPagesArray;
            this.floatingData.images = newImagesArray;
        };
        this.getLocation = () => {
            return window.location.pathname.split('/')[2] === 'covers' ? 'covers' : 'pages';
        };
        this.loadData = () => {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                fetch(window.location.origin + '/session/floating_viewer').then(response => response.json())
                    .then((r) => __awaiter(this, void 0, void 0, function* () {
                    this.floatingData.pages = this.fillWithNull(r.pages);
                    this.floatingData.cover = r.cover;
                    this.floatingData.selectedColor = r.color;
                    this.setColor(this.COLOR_MAP.findIndex(e => e === r.color), false);
                    this.addLoaderToLoadablePages(r.cover, r.pages);
                    if (this.getLocation() === 'covers') {
                        __classPrivateFieldGet(this, _FloatingViewer_button_cb, "f").call(this, true);
                    }
                    if (r.cover !== null) {
                        const urls = yield this.createIMGPreviewFromCanvasObject(JSON.parse(r.cover));
                        this.floatingData.coverImage = { small: urls.small, big: urls.big };
                        this.updateFloatingPageItem(-1);
                    }
                    for (let i = 0; i < r.pages.length; i++) {
                        if (r.pages[i] !== null) {
                            const urls = yield this.createIMGPreviewFromCanvasObject(JSON.parse(r.pages[i]));
                            this.floatingData.images[i] = { small: urls.small, big: urls.big };
                            this.updateFloatingPageItem(i);
                            this.updateSelectedPageCount();
                        }
                    }
                    resolve('Loading finished!');
                })).catch(err => reject(err));
            }));
            /*const pages:string | null = localStorage.getItem('floatingViewer_pages');
            const color:string  | null= localStorage.getItem('floatingViewer_selected_color');
            let imagesArray: any[] = [];
            for (let i = 0; i < this.#MAX_PAGE; i++) {
                const small = localStorage.getItem('floatingViewer_images_small_' + i);
                const big = localStorage.getItem('floatingViewer_images_big_' + i);
    
                imagesArray[i] = {
                    small: small === '' ? null : small,
                    big: big === '' ? null : big,
                }
            }
            if(pages && imagesArray.length === this.#MAX_PAGE && color) {
                try {
                    const pagesArray = JSON.parse(atob(pages as string));
                    const selectedColor = parseInt(color as string);
    
    
                    this.floatingData = {
                        pages: pagesArray,
                        images: imagesArray,
                        selectedColor
                    };
    
    
                } catch (error) {
                    console.error(error)
                }
            }*/
        };
        this.getPages = (type) => {
            var _b;
            return [(_b = this.floatingData.coverImage) === null || _b === void 0 ? void 0 : _b.big, ...this.floatingData.images.map(obj => obj !== null ? obj[type] : null)];
        };
        this.COLOR_MAP = [
            'Szürke',
            'Kék',
            'Vörös',
            'Fekete',
            'Barack',
        ];
        __classPrivateFieldSet(this, _FloatingViewer_viewer, $('.floating-viewer'), "f");
        __classPrivateFieldSet(this, _FloatingViewer_close, $('.close'), "f");
        __classPrivateFieldSet(this, _FloatingViewer_open, $('.openArrow'), "f");
        __classPrivateFieldSet(this, _FloatingViewer_fullScreen, $('.openFull'), "f");
        __classPrivateFieldSet(this, _FloatingViewer_curr_pages, $('#curr_pages'), "f");
        __classPrivateFieldSet(this, _FloatingViewer_all_pages, $('#all_pages'), "f");
        __classPrivateFieldSet(this, _FloatingViewer_selected_color, $('#selected_color'), "f");
        this.isFullScreen = false;
        this.valid = false;
        this.selectedColor = 0;
        __classPrivateFieldSet(this, _FloatingViewer_pageContainer, $('.pages'), "f");
        __classPrivateFieldSet(this, _FloatingViewer_cb, loadingFinishedCB, "f");
        __classPrivateFieldSet(this, _FloatingViewer_button_cb, toggleNextButtonCB, "f");
        this.floatingData = {
            pages: new Array(__classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE)).fill(null),
            images: new Array(__classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE)).fill(null),
            selectedColor: this.COLOR_MAP[0],
            cover: null,
            coverImage: null,
        };
        if (__classPrivateFieldGet(this, _FloatingViewer_viewer, "f") && __classPrivateFieldGet(this, _FloatingViewer_close, "f") && __classPrivateFieldGet(this, _FloatingViewer_open, "f") && __classPrivateFieldGet(this, _FloatingViewer_fullScreen, "f") && __classPrivateFieldGet(this, _FloatingViewer_all_pages, "f") && __classPrivateFieldGet(this, _FloatingViewer_curr_pages, "f") && __classPrivateFieldGet(this, _FloatingViewer_pageContainer, "f")) {
            !silent && __classPrivateFieldGet(this, _FloatingViewer_viewer, "f").parent().removeClass('hidden');
            this.valid = true;
            __classPrivateFieldGet(this, _FloatingViewer_close, "f").on('click', () => {
                this.visible(false);
                if (window.outerWidth < 990) {
                    this.fullScreen(false);
                }
            });
            __classPrivateFieldGet(this, _FloatingViewer_open, "f").on('click', () => {
                localStorage.setItem('arrow', 'false');
                $('.help-fl').detach();
                this.visible(true);
                if (window.outerWidth < 990) {
                    this.fullScreen(true);
                }
            });
            __classPrivateFieldGet(this, _FloatingViewer_fullScreen, "f").on('click', () => {
                this.isFullScreen ? this.fullScreen(false) : this.fullScreen(true);
            });
            if (window.outerWidth < 990) {
                __classPrivateFieldGet(this, _FloatingViewer_fullScreen, "f").addClass('hidden');
            }
            this.initArrow();
            this.setColor(0, false);
            this.createEmptyFloatingPages();
            __classPrivateFieldGet(this, _FloatingViewer_all_pages, "f").html("" + __classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE));
            this.loadData().then(res => {
                this.updateSelectedPageCount();
                __classPrivateFieldGet(this, _FloatingViewer_cb, "f").call(this, FloatingViewer.isValid(this.floatingData));
            });
        }
        else
            throw new Error('Cannot find all neccessary element!');
    }
}
_a = FloatingViewer, _FloatingViewer_viewer = new WeakMap(), _FloatingViewer_close = new WeakMap(), _FloatingViewer_open = new WeakMap(), _FloatingViewer_fullScreen = new WeakMap(), _FloatingViewer_curr_pages = new WeakMap(), _FloatingViewer_all_pages = new WeakMap(), _FloatingViewer_selected_color = new WeakMap(), _FloatingViewer_MIN_PAGE = new WeakMap(), _FloatingViewer_pageContainer = new WeakMap(), _FloatingViewer_cb = new WeakMap(), _FloatingViewer_button_cb = new WeakMap(), _FloatingViewer_toggle = new WeakMap();
_FloatingViewer_MAX_PAGE = { value: 40 };
FloatingViewer.reset = () => {
    localStorage.removeItem('floatingViewer_pages');
    localStorage.removeItem('floatingViewer_images_0');
    localStorage.removeItem('floatingViewer_images_1');
    localStorage.removeItem('floatingViewer_images_2');
    localStorage.removeItem('floatingViewer_images_3');
    localStorage.removeItem('floatingViewer_selected_color');
};
FloatingViewer.isValid = (response) => {
    if (response === null || response === undefined)
        return false;
    if (response.images === null || response.pages === null)
        return false;
    if (response.images.length !== __classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE) || response.pages.length !== __classPrivateFieldGet(FloatingViewer, _a, "f", _FloatingViewer_MAX_PAGE))
        return false;
    return true;
};
