import $ from "cash-dom";
import Canvas from "./canvas";
import FloatingViewer from "./floatingViewer";
export default class Pages {
    constructor(characterData, filterSelection = true, needFloating = true, isAdmin = false, type = "pages") {
        this.floatingViewer = null;
        this.initColorSelect = () => {
            let colors = $('#colors > div');
            if (colors && colors !== undefined) {
                colors.each((i, c) => {
                    $(c).on('click', () => {
                        var _a;
                        colors.removeClass('selected');
                        if (!$(c).hasClass('selected')) {
                            $(c).addClass('selected');
                            let colorId = parseInt($(c).data('color-id'));
                            (_a = this.floatingViewer) === null || _a === void 0 ? void 0 : _a.setColor(colorId);
                        }
                    });
                });
            }
        };
        this.initFilterSelection = () => {
            let filters = $('.filter-select');
            filters.each((i, f) => {
                $(f).on('click', () => {
                    filters.removeClass('active');
                    if (!$(f).hasClass('active')) {
                        $(f).addClass('active');
                    }
                });
            });
            this.initColorSelect();
        };
        this.enableNextButton = (toggle) => {
            if (toggle) {
                $('#select_pages').removeClass('disabled');
                $('#select_pages').removeAttr('disabled');
                $('#redirect_payment').removeClass('disabled');
                $('#redirect_payment').removeAttr('disabled');
            }
            else {
                $('#select_pages').addClass('disabled');
                $('#select_pages').attr('disabled', 'true');
                $('#redirect_payment').addClass('disabled');
                $('#redirect_payment').attr('disabled', 'true');
            }
        };
        this.render = (id, charDatas, extra) => {
            const self = this;
            if (id !== -1) {
                const name = '#page-' + id;
                const container = $(name);
                if (container && charDatas !== undefined) {
                    let canvas = new Canvas(name.slice(1, name.length), charDatas.slice(0, 2), 'cover', false, container.data('url'), () => {
                        $(container).parent().siblings('.toggle-edit').addClass('clickable');
                        const element = document.getElementById('edit_cover_' + id);
                        if (element !== null) {
                            element.setAttribute('data-bs-toggle', 'modal');
                            element.setAttribute('data-bs-target', `#cover-${id}-modal`);
                        }
                    }, extra, this.isAdmin ? false : true);
                    const modal = $(`#cover-${id}-modal`);
                    if (self.isAdmin) {
                        $(container).on('click', () => {
                            localStorage.setItem('temp_extra_data', JSON.stringify(extra));
                            const pageData = {
                                presets: charDatas,
                                bg: container.data('url'),
                                id: id,
                                variant: this.type === 'covers' ? 'cover' : 'page'
                            };
                            localStorage.setItem('temp_page_data', JSON.stringify(pageData));
                            window.location.href = "/admin/edit-page/" + pageData.variant + "/" + pageData.id;
                        });
                    }
                    else {
                        $(container).parent().siblings('.toggle-edit').on('click', () => {
                            var _a, _b;
                            if ($(container).parent().siblings('.toggle-edit').hasClass('clickable')) {
                                if (this.type === 'covers') {
                                    const editContainerName = `#cover-${id}-edit`;
                                    const maxHeight = window.innerHeight * 0.7;
                                    const canvasHeight = 400 * maxHeight / 350;
                                    const canvasWidth = 350 * maxHeight / 400;
                                    $(editContainerName).css('width', canvasWidth);
                                    $(editContainerName).css('height', canvasHeight);
                                    canvas.editMode(true, `cover-${id}-edit`, { scaleX: maxHeight / 400, scaleY: maxHeight / 350, width: canvasWidth, height: canvasHeight }, this.floatingViewer, () => {
                                        var _a;
                                        this.enableNextButton(true);
                                        (_a = this.floatingViewer) === null || _a === void 0 ? void 0 : _a.save();
                                    });
                                }
                                else {
                                    const page = canvas.getClone('page');
                                    (_a = this.floatingViewer) === null || _a === void 0 ? void 0 : _a.addPage(page);
                                    if ((_b = this.floatingViewer) === null || _b === void 0 ? void 0 : _b.isMinimumSelected()) {
                                        this.enableNextButton(true);
                                    }
                                    else {
                                        this.enableNextButton(false);
                                    }
                                }
                            }
                        });
                    }
                    modal.on('click', () => {
                        if (!modal.hasClass('show')) {
                            canvas.editMode(false, `cover-${id}-edit`, null, null, null);
                        }
                    });
                }
            }
        };
        if (filterSelection)
            this.initFilterSelection();
        if (needFloating)
            this.floatingViewer = new FloatingViewer(() => {
                var _a;
                if (type === 'covers') {
                    this.enableNextButton(true);
                }
                if (type === 'pages') {
                    if ((_a = this.floatingViewer) === null || _a === void 0 ? void 0 : _a.isMinimumSelected()) {
                        this.enableNextButton(true);
                    }
                }
            }, this.enableNextButton);
        this.type = type;
        this.covers = [];
        this.characters = [];
        this.isAdmin = isAdmin;
        $('.page-container > div').each((i, c) => {
            var _a;
            const page = {
                data: {
                    url: $(c).data('url') || null,
                    id: parseInt(((_a = $(c).attr('id')) === null || _a === void 0 ? void 0 : _a.split('-')[1]) || "-1"),
                    data: $(c).data('page') || null,
                },
                stage: null
            };
            if (page.data.id !== -1)
                this.covers.push(page);
            this.render(page.data.id, characterData, page.data.data);
        });
        $('#select_pages').on('click', () => {
            window.location.href = "pages";
        });
        $('#redirect_payment').on('click', () => {
            var _a;
            if ((_a = this.floatingViewer) === null || _a === void 0 ? void 0 : _a.isMinimumSelected())
                window.location.href = "/fizetes";
        });
    }
}
