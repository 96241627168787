import $, { Cash } from "cash-dom";
let preCreationData = {};

$("#create-1").on("click", () => startCreation(1));
$("#create-2").on("click", () => startCreation(2));

$("#edit-1").on("click", () => edit(1));
$("#edit-2").on("click", () => edit(2));

const go = (route, char) => {
    const needSlash =
        window.location.pathname[window.location.pathname.length - 1] !== "/";
    let redirectURL = `${window.location.pathname}${
        needSlash ? "/" : ""
    }${route}/${char}`;
    window.location.href = redirectURL;
};

const edit = (num) => {
    go("edit", num);
};

$("#start_create").on("click", () => {
    localStorage.setItem(
        "character_" + preCreationData.character,
        JSON.stringify(preCreationData)
    );
    go("create", preCreationData.character);
});

const nameInput = $("#nameInput");
const genderMale = $("#modal_gender_male");
const genderFemale = $("#modal_gender_female");
const bodyThin = $("#modal_body_thin");
const bodyFat = $("#modal_body_fat");

const resetModal = () => {
    nameInput.val("");
    setGender(0);
    setBodyType(0);
};

const setGender = (gender) => {
    gender === 0
        ? genderMale.addClass("active")
        : genderFemale.addClass("active");
    gender === 0
        ? genderFemale.removeClass("active")
        : genderMale.removeClass("active");
    preCreationData.gender = gender;
};

const setBodyType = (type) => {
    type === 0 ? bodyThin.addClass("active") : bodyFat.addClass("active");
    type === 0 ? bodyFat.removeClass("active") : bodyThin.removeClass("active");
    preCreationData.body = type;
};

const checkExistingCharacter = () => {
    let char_1 = localStorage.getItem("character_1");
    let char_2 = localStorage.getItem("character_2");

    if (char_1 === null) setButton("edit-1", false);
    if (char_2 === null) setButton("edit-2", false);
};

const setButton = (id, state) => {
    const btn = $("#" + id);
    state ? btn.removeClass("disabled") : btn.addClass("disabled");
};

const startCreation = (num) => {
    resetModal();
    preCreationData = {};
    preCreationData.character = num;
    preCreationData.gender = 0;
    preCreationData.body = 0;

    nameInput.on("change keyup paste", (e) => {
        preCreationData.name = nameInput.val();
    });

    genderFemale.on("click", () => {
        setGender(1);
    });

    genderMale.on("click", () => {
        setGender(0);
    });

    bodyThin.on("click", () => {
        setBodyType(0);
    });

    bodyFat.on("click", () => {
        setBodyType(1);
    });
};

checkExistingCharacter();
