var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _a, _Manage_active, _Manage_stage, _Manage_data, _Manage_visibleSVG, _Manage_hiddenSVG, _Manage_addSVG, _Manage_removeSVG;
import $ from "cash-dom";
import Konva from "konva";
import Creator from "../creator";
import { presets } from "./presetCharacters";
var characterType;
(function (characterType) {
    characterType[characterType["GIVER"] = 0] = "GIVER";
    characterType[characterType["GIVEN"] = 1] = "GIVEN";
    characterType[characterType["COPY"] = 2] = "COPY";
})(characterType || (characterType = {}));
;
//! TODO: GET CUSTOM OR DEFAULT ATTRIBUTES - done
//! TODO: ADD OPTION TO DROPDOWNS WITH EQUIPPED EYES AND MOUTHS - done
//! TODO: ROTATE - done
//! TODO: COPY CHARACTER - done
//! TODO: LOAD INTO PAGE/COVER - later
//! TODO: ADD OBJECT FR OR BG LAYER CHANGE - doing
//! TODO: EMPTY SEARCH FIELD - doing
export default class Manage {
    constructor(activeState = false, stage = null, load = null) {
        var _b, _c, _d, _e, _f, _g;
        _Manage_active.set(this, void 0);
        _Manage_stage.set(this, void 0);
        _Manage_visibleSVG.set(this, $("<svg class='wp-show' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-eye' viewBox='0 0 16 16'><path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z'/><path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z'/></svg>"));
        _Manage_hiddenSVG.set(this, $("<svg class='wp-hide' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-eye' viewBox='0 0 16 16'><path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z'/><path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z'/><path d='M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z'/></svg>"));
        _Manage_addSVG.set(this, $("<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus-circle' viewBox='0 0 16 16'><path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z'/><path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/></svg>"));
        _Manage_removeSVG.set(this, $("<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-trash3' viewBox='0 0 16 16'><path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z'/></svg>"));
        this.getAttr = (layer, asset) => {
            if (layer !== undefined) {
                const a = layer.find('.' + asset);
                return a[0].attrs;
            }
            else
                return null;
        };
        //! TODO GET SELECTED FROM DB AND SET ACCORDING TO IT THE DROPDOWNS
        this.listenDropdownChange = () => {
            var _b, _c, _d, _e, _f, _g, _h, _j;
            const giver_mouth = $('#giver_mouth');
            const giver_eye = $('#giver_eye');
            const given_mouth = $('#given_mouth');
            const given_eye = $('#given_eye');
            const attrs = [
                btoa(JSON.stringify(this.getAttr((_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[2], 'eyes'))),
                btoa(JSON.stringify(this.getAttr((_e = (_d = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e[2], 'mouth'))),
                btoa(JSON.stringify(this.getAttr((_g = (_f = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g[3], 'eyes'))),
                btoa(JSON.stringify(this.getAttr((_j = (_h = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _h === void 0 ? void 0 : _h.children) === null || _j === void 0 ? void 0 : _j[3], 'mouth'))),
            ];
            const defaults = [
                `<option data-attr="${attrs[0]}" selected>Alap szem</option>`,
                `<option data-attr="${attrs[2]}" selected>Alap Szem</option>`,
                `<option data-attr="${attrs[1]}" selected>Alap száj</option>`,
                `<option data-attr="${attrs[3]}" selected>Alap száj</option>`,
            ];
            giver_mouth.prepend(defaults[2]);
            given_mouth.prepend(defaults[3]);
            giver_eye.prepend(defaults[0]);
            given_eye.prepend(defaults[1]);
            giver_mouth.on('change', (e) => {
                var _b, _c, _d, _e, _f, _g;
                if (((_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[1]) !== undefined) {
                    if (e.target.selectedIndex === 0) {
                        const data = JSON.parse(atob(e.target.options[e.target.selectedIndex].dataset.attr));
                        Manage.addSVG((_e = (_d = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e[2], 'mouth', data.src, -1, data);
                        __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[0].mouth = "";
                    }
                    else {
                        const m = e.target.options[e.target.selectedIndex].dataset.src;
                        const mg = parseInt(e.target.options[e.target.selectedIndex].dataset.group);
                        Manage.addSVG((_g = (_f = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g[2], 'mouth', m, mg);
                        __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[0].mouth = m;
                    }
                }
            });
            giver_eye.on('change', (e) => {
                var _b, _c, _d, _e, _f, _g;
                if (((_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[1]) !== undefined) {
                    if (e.target.selectedIndex === 0) {
                        const data = JSON.parse(atob(e.target.options[e.target.selectedIndex].dataset.attr));
                        Manage.addSVG((_e = (_d = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e[2], 'eyes', data.src, -1, data);
                        __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[0].eyes = "";
                    }
                    else {
                        const ey = e.target.options[e.target.selectedIndex].dataset.src;
                        const eg = parseInt(e.target.options[e.target.selectedIndex].dataset.group);
                        Manage.addSVG((_g = (_f = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g[2], 'eyes', ey, eg);
                        __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[0].eyes = ey;
                    }
                }
            });
            given_mouth.on('change', (e) => {
                var _b, _c, _d, _e, _f, _g;
                if (((_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[2]) !== undefined) {
                    if (e.target.selectedIndex === 0) {
                        const data = JSON.parse(atob(e.target.options[e.target.selectedIndex].dataset.attr));
                        Manage.addSVG((_e = (_d = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e[3], 'mouth', data.src, -1, data);
                        __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[1].mouth = '';
                    }
                    else {
                        const m = e.target.options[e.target.selectedIndex].dataset.src;
                        const mg = parseInt(e.target.options[e.target.selectedIndex].dataset.group);
                        Manage.addSVG((_g = (_f = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g[3], 'mouth', m, mg);
                        __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[1].mouth = m;
                    }
                }
            });
            given_eye.on('change', (e) => {
                var _b, _c, _d, _e, _f, _g;
                if (((_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[2]) !== undefined) {
                    if (e.target.selectedIndex === 0) {
                        const data = JSON.parse(atob(e.target.options[e.target.selectedIndex].dataset.attr));
                        Manage.addSVG((_e = (_d = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e[3], 'eyes', data.src, -1, data);
                        __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[1].eyes = "";
                    }
                    else {
                        const ey = e.target.options[e.target.selectedIndex].dataset.src;
                        const eg = parseInt(e.target.options[e.target.selectedIndex].dataset.group);
                        Manage.addSVG((_g = (_f = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g[3], 'eyes', ey, eg);
                        __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[1].eyes = ey;
                    }
                }
            });
        };
        this.listenArmRotateButtons = (node) => {
            if (node === undefined)
                return;
            $('#arm_setting').removeClass("d-none");
            const left_edit = $('#left');
            const left_save = $('#left-save');
            const right_edit = $('#right');
            const right_save = $('#right-save');
            const z_up = $('#z-up');
            const z_down = $('#z-down');
            const char = parseInt(node.attrs.name.split('_')[1]) - 1;
            $('#arm_type_right').children().removeAttr('selected');
            $('#arm_type_left').children().removeAttr('selected');
            //! TODO SET SELECTED BASED ON SELECTED DATA
            //$('#arm_type_right').children()[Manage.#data.characters[char].armType_r === 'str' ? 0 : 1]?.setAttribute('selected', 'true');
            //$('#arm_type').children()[Manage.#data.characters[char].armType === 'str' ? 0 : 1]?.setAttribute('selected', 'true');
            const leftArmGroup = node.children.filter(g => { var _b; return ((_b = g === null || g === void 0 ? void 0 : g.attrs) === null || _b === void 0 ? void 0 : _b.name) === 'arms-group-1'; })[0];
            const rightArmGroup = node.children.filter(g => { var _b; return ((_b = g === null || g === void 0 ? void 0 : g.attrs) === null || _b === void 0 ? void 0 : _b.name) === 'arms-group-2'; })[0];
            let selected = null;
            const svgNameToShort = {
                'egyenes': 'str',
                'hajlitott': 'bnt',
                'hajlitott2': 'bnt-1',
                'hajlitott3': 'bnt-2',
            };
            $('#arm_type_left').on('change', (e) => {
                const curr_src = node.find('.arms-1')[0].getAttr('src');
                const oldExpr = curr_src.includes('egyenes') ? 'egyenes' : curr_src.includes('hajlitott3') ? 'hajlitott3' : curr_src.includes('hajlitott2') ? 'hajlitott2' : 'hajlitott';
                const newExpr = e.target.selectedIndex === 0 ? 'egyenes' : e.target.selectedIndex === 1 ? 'hajlitott' : e.target.selectedIndex === 2 ? 'hajlitott2' : 'hajlitott3';
                __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[char].armType_l = svgNameToShort[newExpr];
                Manage.addSVG(node, 'arms-1', curr_src.replace(oldExpr, newExpr), -1);
            });
            $('#arm_type_right').on('change', (e) => {
                const curr_src = node.find('.arms-1')[0].getAttr('src');
                const oldExpr = curr_src.includes('egyenes') ? 'egyenes' : curr_src.includes('hajlitott3') ? 'hajlitott3' : curr_src.includes('hajlitott2') ? 'hajlitott2' : 'hajlitott';
                const newExpr = e.target.selectedIndex === 0 ? 'egyenes' : e.target.selectedIndex === 1 ? 'hajlitott' : e.target.selectedIndex === 2 ? 'hajlitott2' : 'hajlitott3';
                __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[char].armType_r = svgNameToShort[newExpr];
                Manage.addSVG(node, 'arms-2', curr_src.replace(oldExpr, newExpr), -1);
            });
            left_edit.on('click', () => {
                this.addTransformerToNode(leftArmGroup);
                left_edit.addClass('d-none');
                left_save.removeClass('d-none');
                this.setButtonVisibility([z_down, z_up], true);
                selected = 'left';
                /*this.rotateAroundPoint(arm_1, 30, {x: this.defWidth / 4 + (char === 1 ? 15 : 0), y: this.defHeight / 4 + 30});
                this.rotateAroundPoint(hand_1, 30, {x: this.defWidth / 4 + (char === 1 ? 15 : 0), y: this.defHeight / 4 + 30});
                Manage.#data.characters[char].arms[0] = arm_1.getAttrs();
                Manage.#data.characters[char].hands[0] = hand_1.getAttrs();*/
            });
            left_save.on('click', () => {
                left_edit.removeClass('d-none');
                left_save.addClass('d-none');
                __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[char].arms[0] = Object.assign(Object.assign({}, leftArmGroup.getAttrs()), { zIndex: leftArmGroup.zIndex() });
                this.deleteTransformers();
                this.setButtonVisibility([z_down, z_up], false);
                selected = null;
            });
            right_edit.on('click', () => {
                this.addTransformerToNode(rightArmGroup);
                right_edit.addClass('d-none');
                right_save.removeClass('d-none');
                this.setButtonVisibility([z_down, z_up], true);
                selected = 'right';
                /*this.rotateAroundPoint(arm_1, 30, {x: this.defWidth / 4 + (char === 1 ? 15 : 0), y: this.defHeight / 4 + 30});
                this.rotateAroundPoint(hand_1, 30, {x: this.defWidth / 4 + (char === 1 ? 15 : 0), y: this.defHeight / 4 + 30});
                Manage.#data.characters[char].arms[0] = arm_1.getAttrs();
                Manage.#data.characters[char].hands[0] = hand_1.getAttrs();*/
            });
            right_save.on('click', () => {
                right_edit.removeClass('d-none');
                right_save.addClass('d-none');
                __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[char].arms[1] = Object.assign(Object.assign({}, rightArmGroup.getAttrs()), { zIndex: rightArmGroup.zIndex() });
                this.deleteTransformers();
                this.setButtonVisibility([z_down, z_up], false);
                selected = null;
            });
            z_down.on('click', () => {
                if (selected === null)
                    return;
                const group = selected === 'left' ? leftArmGroup : rightArmGroup;
                group.setZIndex(group.zIndex() - 1);
            });
            z_up.on('click', () => {
                if (selected === null)
                    return;
                const group = selected === 'left' ? leftArmGroup : rightArmGroup;
                group.setZIndex(group.zIndex() + 1);
            });
        };
        this.listenIndexChangeButtons = (node) => {
            var _b, _c;
            if (node === undefined)
                return;
            const up = $('#index-up');
            const down = $('#index-down');
            const fr = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.findOne('.extra-fr');
            const bg = (_c = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _c === void 0 ? void 0 : _c.findOne('.extra-bh');
            up.on('click', () => {
                if (node.attrs.name.includes('extra')) {
                    if (fr.findOne('.' + node.attrs.name) === null || fr.findOne('.' + node.attrs.name) === undefined) {
                        fr.add(node);
                    }
                }
                else {
                    node.setZIndex(node.zIndex() + 1);
                }
            });
            down.on('click', () => {
                if (node.attrs.name.includes('extra')) {
                    if (bg.findOne('.' + node.attrs.name) !== null || bg.findOne('.' + node.attrs.name) !== undefined) {
                        bg.add(node);
                    }
                }
                else {
                    node.setZIndex(node.zIndex() - 1);
                }
            });
        };
        this.listenLegTypeChange = (node) => {
            if (node === undefined)
                return;
            const char = parseInt(node.attrs.name.split('_')[1]) - 1;
            $('#leg_settings').removeClass("d-none");
            const standing_src = node.find('.bottom')[0].getAttr('src');
            $('#leg_type').on('change', (e) => {
                var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[char].legType = e.target.selectedIndex;
                const curr_src = node.find('.bottom')[0].getAttr('src');
                const curr_src_shoe_1 = node.find('.shoes-1')[0].getAttr('src');
                const curr_src_shoe_2 = node.find('.shoes-2')[0].getAttr('src');
                const pants = Manage.getNewUrlForLeg(curr_src, e.target.selectedIndex);
                const shoes = Manage.getNewUrlForShoe([curr_src_shoe_1, curr_src_shoe_2], e.target.selectedIndex);
                const b_layer = node.children.filter(c => c.find('.bottom').length !== 0);
                (_c = (_b = b_layer[0]) === null || _b === void 0 ? void 0 : _b.findOne('.bottom')) === null || _c === void 0 ? void 0 : _c.visible(true);
                (_d = node.findOne('.shoes-1')) === null || _d === void 0 ? void 0 : _d.visible(true);
                (_e = node.findOne('.shoes-2')) === null || _e === void 0 ? void 0 : _e.visible(true);
                (_f = node.findOne('.bottom')) === null || _f === void 0 ? void 0 : _f.visible(true);
                if (e.target.selectedIndex === 3) {
                    Manage.addSVG(node, 'bottom', pants[0], -1);
                    Manage.addSVG(node, 'shoes-1', shoes[0], -1);
                    Manage.addSVG(node, 'shoes-2', shoes[1], -1);
                    Manage.getGroupBySrc(standing_src).then((res) => __awaiter(this, void 0, void 0, function* () {
                        let attr = yield Manage.getAssetPosition('bottom', res);
                        //! RODO EZ CSAK WOMANNEL MEGY, KENE CREATORBA EXTRA FIELD EHHEZ A LABHOZ CSAK GYE NEM KULON GORUPBA VAN
                        //THIN FERFINEK a 31 JO, ELEG ADMINON MEGJELENITENI IGY
                        Manage.addSVG(node, 'dance', pants[1], -1, Object.assign(Object.assign({}, attr), { x: (attr === null || attr === void 0 ? void 0 : attr.x) + (char === 0 ? 92 : 41) })).then(res => {
                            // gzsolt nem tud gondolkozni - skver
                            res.attrs.x -= 62;
                            res.attrs.y += 2;
                            b_layer[0].add(res);
                            //add(res);
                        });
                    }));
                    //tanc nagyon meni
                }
                else if (e.target.selectedIndex === 4) {
                    (_h = (_g = b_layer[0]) === null || _g === void 0 ? void 0 : _g.findOne('.dance')) === null || _h === void 0 ? void 0 : _h.destroy();
                    (_k = (_j = b_layer[0]) === null || _j === void 0 ? void 0 : _j.findOne('.bottom')) === null || _k === void 0 ? void 0 : _k.visible(false);
                    (_l = node.findOne('.shoes-1')) === null || _l === void 0 ? void 0 : _l.visible(false);
                    (_m = node.findOne('.shoes-2')) === null || _m === void 0 ? void 0 : _m.visible(false);
                    (_o = node.findOne('.bottom')) === null || _o === void 0 ? void 0 : _o.visible(false);
                    const body = node.findOne('.body');
                    Manage.addSVG(node, 'body', body === null || body === void 0 ? void 0 : body.getAttr('src').replace('labakkal', 'labnelkul'), -1);
                }
                else {
                    (_q = (_p = b_layer[0]) === null || _p === void 0 ? void 0 : _p.findOne('.dance')) === null || _q === void 0 ? void 0 : _q.destroy();
                    Manage.addSVG(node, 'bottom', pants[0], -1);
                    Manage.addSVG(node, 'shoes-1', shoes[0], -1, null, e.target.selectedIndex === 1);
                    Manage.addSVG(node, 'shoes-2', shoes[1], -1, null, e.target.selectedIndex === 1);
                    //nem tanc
                }
                /*const curr_shoe_src = node.find('.shoes-1')[0].getAttr('src');
                const oldExpr = curr_src.includes('allo') ? 'allo' : curr_src.includes('ulo') ? 'ulo' : 'futo';
                const newExpr = e.target.selectedIndex === 0 ? 'allo' : e.target.selectedIndex === 1 ? 'ulo' : 'futo';
    
                const oldExpr_sh = curr_src.includes('ulo') ? 'elol' : curr_src.includes('ulo') ? 'ulo' : 'futo';
                const newExpr = e.target.selectedIndex === 0 ? 'allo' : e.target.selectedIndex === 1 ? 'ulo' : 'futo';
    */
            });
        };
        this.setButtonVisibility = (cashObj, t) => {
            if (t) {
                cashObj.forEach(c => c.removeClass('d-none'));
            }
            else {
                cashObj.forEach(c => c.addClass('d-none'));
            }
        };
        this.getFiles = () => {
            return new Promise((resolve, reject) => {
                let requestUrl = `${window.location.origin}/extras/`;
                fetch(requestUrl).then((res) => __awaiter(this, void 0, void 0, function* () {
                    let resJson = yield res.json();
                    resolve(resJson);
                })).catch(err => reject(err));
            });
        };
        this.initializePageData = () => {
            var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
            if (!__classPrivateFieldGet(Manage, _a, "f", _Manage_data).initialized) {
                __classPrivateFieldGet(Manage, _a, "f", _Manage_data).initialized = true;
                const chars = [(_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[1], (_e = (_d = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e[2]];
                if (chars[0] !== undefined && chars[1] !== undefined) {
                    const char_1 = {
                        x: -1,
                        y: -1,
                        scaleX: -1,
                        scaleY: -1,
                        rotation: -1,
                        visible: [],
                        armRotation: -1,
                        armType_l: "str",
                        armType_r: "str",
                        eyes: '',
                        mouth: '',
                        legType: 0,
                        zIndex: -1,
                        type: null,
                        name: "",
                        v: 'c'
                    };
                    const chars_assets = [this.getAllAsset(chars[0]), this.getAllAsset(chars[1])];
                    __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters.push(Object.assign(Object.assign({}, char_1), { type: characterType.GIVER, visible: chars_assets[0], name: 'character_1', arms: [null, null] }));
                    __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters.push(Object.assign(Object.assign({}, char_1), { type: characterType.GIVEN, visible: chars_assets[1], name: 'character_2', arms: [null, null] }));
                    chars === null || chars === void 0 ? void 0 : chars[0].setAttr('name', 'character_1');
                    chars === null || chars === void 0 ? void 0 : chars[1].setAttr('name', 'character_2');
                    chars === null || chars === void 0 ? void 0 : chars[1].setAttr('x', 5);
                    chars === null || chars === void 0 ? void 0 : chars[1].setAttr('y', 5);
                    if (((_g = (_f = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g[1]) !== undefined && ((_j = (_h = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _h === void 0 ? void 0 : _h.children) === null || _j === void 0 ? void 0 : _j[2]) !== undefined) {
                        this.addNodeToWorkspace((_l = (_k = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _k === void 0 ? void 0 : _k.children) === null || _l === void 0 ? void 0 : _l[1]);
                        this.addNodeToWorkspace((_o = (_m = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _m === void 0 ? void 0 : _m.children) === null || _o === void 0 ? void 0 : _o[2]);
                    }
                }
            }
            else {
                if (((_q = (_p = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _p === void 0 ? void 0 : _p.children) === null || _q === void 0 ? void 0 : _q[2]) !== undefined && ((_s = (_r = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _r === void 0 ? void 0 : _r.children) === null || _s === void 0 ? void 0 : _s[3]) !== undefined) {
                    __classPrivateFieldGet(Manage, _a, "f", _Manage_data).workspace = [];
                    this.addNodeToWorkspace((_u = (_t = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _t === void 0 ? void 0 : _t.children) === null || _u === void 0 ? void 0 : _u[2]);
                    this.addNodeToWorkspace((_w = (_v = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _v === void 0 ? void 0 : _v.children) === null || _w === void 0 ? void 0 : _w[3]);
                    (_0 = (_z = (_y = (_x = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _x === void 0 ? void 0 : _x.children) === null || _y === void 0 ? void 0 : _y[1]) === null || _z === void 0 ? void 0 : _z.children) === null || _0 === void 0 ? void 0 : _0.forEach(c => {
                        this.addNodeToWorkspace(c);
                    });
                    (_4 = (_3 = (_2 = (_1 = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _1 === void 0 ? void 0 : _1.children) === null || _2 === void 0 ? void 0 : _2[4]) === null || _3 === void 0 ? void 0 : _3.children) === null || _4 === void 0 ? void 0 : _4.forEach(c => {
                        this.addNodeToWorkspace(c);
                    });
                }
            }
        };
        this.setActive = (toggle, stage) => {
            var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            __classPrivateFieldSet(this, _Manage_stage, stage !== null ? stage : __classPrivateFieldGet(this, _Manage_stage, "f"), "f");
            if (toggle && __classPrivateFieldGet(this, _Manage_stage, "f") !== null) {
                this.initializePageData();
                this.setButtonVisibility([$('#editbtn')], false);
                const trsLayer = (_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c.filter(l => l.attrs.name === 'trs');
                const extraLayers = (_e = (_d = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e.filter(l => { var _b; return (_b = l.attrs.name) === null || _b === void 0 ? void 0 : _b.includes('extra-'); });
                if ((extraLayers === null || extraLayers === void 0 ? void 0 : extraLayers.length) === 0) {
                    const layer_1 = new Konva.Layer({
                        name: 'extra-fr',
                    });
                    const layer_2 = new Konva.Layer({
                        name: 'extra-bh',
                    });
                    (_f = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _f === void 0 ? void 0 : _f.add(layer_1);
                    (_g = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _g === void 0 ? void 0 : _g.add(layer_2);
                }
                if ((trsLayer === null || trsLayer === void 0 ? void 0 : trsLayer.length) === 0) {
                    const layer = new Konva.Layer({
                        name: 'trs',
                    });
                    (_h = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _h === void 0 ? void 0 : _h.add(layer);
                }
                const trs = (_j = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _j === void 0 ? void 0 : _j.findOne('.trs');
                const extraFr = (_k = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _k === void 0 ? void 0 : _k.findOne('.extra-fr');
                const extrasBh = (_l = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _l === void 0 ? void 0 : _l.findOne('.extra-bh');
                trs.setZIndex(5);
                extraFr.setZIndex(4);
                extrasBh.setZIndex(1);
            }
            else {
                this.deleteTransformers();
                this.removeDraggableFromEveryObject();
                this.setButtonVisibility([$('#left'), $('#right')], true);
                this.setButtonVisibility([$('#left-save'), $('#right-save')], false);
                this.setButtonVisibility([$('#z-down'), $('#z-up')], false);
                $('#workspace').empty();
                localStorage.setItem('temp_extra_data', JSON.stringify([
                    __classPrivateFieldGet(Manage, _a, "f", _Manage_data),
                    __classPrivateFieldGet(this, _Manage_stage, "f")
                ]));
                this.saveAllDataToPage([
                    __classPrivateFieldGet(Manage, _a, "f", _Manage_data),
                    __classPrivateFieldGet(this, _Manage_stage, "f")
                ]);
                __classPrivateFieldSet(this, _Manage_stage, null, "f");
            }
        };
        this.addTransformerToNode = (node) => {
            var _b, _c, _d;
            if (node.attrs.name) {
                const exist = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.findOne('.' + node.attrs.name + '-tr');
                if (exist === undefined) {
                    this.removeDraggableFromEveryObject();
                    this.deleteTransformers();
                    this.setButtonVisibility([$('#left'), $('#right')], true);
                    this.setButtonVisibility([$('#left-save'), $('#right-save')], false);
                    this.setButtonVisibility([$('#z-down'), $('#z-up')], false);
                    const tr = new Konva.Transformer({
                        nodes: [node],
                        name: node.attrs.name + '-tr',
                        boundBoxFunc: function (oldBox, newBox) {
                            newBox.width = Math.max(20, newBox.width);
                            return newBox;
                        },
                    });
                    (_d = (_c = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _c === void 0 ? void 0 : _c.children) === null || _d === void 0 ? void 0 : _d[5].add(tr);
                    node.setAttr('draggable', true);
                    node.on('dragmove', () => {
                        if (node.attrs.name.includes('character')) {
                            this.updateData(node.attrs.name.includes('character') ? 'c' : 'o', node.attrs.name, 'x', node.x());
                            this.updateData(node.attrs.name.includes('character') ? 'c' : 'o', node.attrs.name, 'y', node.y());
                        }
                    });
                    node.on('transform', () => {
                        if (node.attrs.name.includes('character')) {
                            this.updateData(node.attrs.name.includes('character') ? 'c' : 'o', node.attrs.name, 'scaleX', node.scaleX());
                            this.updateData(node.attrs.name.includes('character') ? 'c' : 'o', node.attrs.name, 'scaleY', node.scaleY());
                            this.updateData(node.attrs.name.includes('character') ? 'c' : 'o', node.attrs.name, 'rotation', node.rotation());
                            this.updateData(node.attrs.name.includes('character') ? 'c' : 'o', node.attrs.name, 'zIndex', node.zIndex());
                        }
                    });
                }
            }
        };
        this.deleteTransformers = () => {
            var _b, _c, _d;
            const layer = (_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[5];
            if (layer !== undefined) {
                (_d = layer === null || layer === void 0 ? void 0 : layer.children) === null || _d === void 0 ? void 0 : _d.forEach(c => {
                    c.destroy();
                });
            }
        };
        this.saveAllDataToPage = (dataArr) => {
            let id = $('#page_id').text();
            let type = $('#page_type').text();
            let url = `${window.location.origin}/creator/page/${id}/${type}`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') || ""
                },
                body: JSON.stringify(dataArr)
            }).then(res => { return; });
        };
        this.removeDraggableFromEveryObject = () => {
            var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            const chars = [(_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[2], (_e = (_d = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e[3]];
            const layer_1 = (_g = (_f = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g[2];
            const layer_2 = (_j = (_h = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _h === void 0 ? void 0 : _h.children) === null || _j === void 0 ? void 0 : _j[4];
            chars.forEach(c => {
                c === null || c === void 0 ? void 0 : c.setAttr('draggable', false);
                c === null || c === void 0 ? void 0 : c.off();
            });
            if (layer_1 !== undefined) {
                (_k = layer_1 === null || layer_1 === void 0 ? void 0 : layer_1.children) === null || _k === void 0 ? void 0 : _k.forEach(c => {
                    c.setAttr('draggable', false);
                    c === null || c === void 0 ? void 0 : c.off();
                });
            }
            if (layer_2 !== undefined) {
                (_l = layer_2 === null || layer_2 === void 0 ? void 0 : layer_2.children) === null || _l === void 0 ? void 0 : _l.forEach(c => {
                    c.setAttr('draggable', false);
                    c === null || c === void 0 ? void 0 : c.off();
                });
            }
        };
        this.deSelect = () => {
            Manage.selected = null;
            this.removeDraggableFromEveryObject();
            this.deleteTransformers();
            this.updateSelectedText("");
            $('#arm_setting').addClass("d-none");
            $('#leg_settings').addClass("d-none");
            $('#layering').addClass("d-none");
            $('#destruct_container').empty();
            $('#show_if_selected').addClass("d-none");
            $('#left').off();
            $('#right').off();
            $('#arm_type_left').off();
            $('#arm_type_right').off();
            $('#leg_type').off();
            $('#index-up').off();
            $('#index-down').off();
        };
        this.buttonState = (id, state) => {
            if (state) {
                $('#' + id).removeClass('disabled');
                $('#' + id).removeAttr('disabled');
            }
            else {
                $('#' + id).addClass('disabled');
                $('#' + id).attr('disabled');
            }
        };
        this.updateSelectedText = (text = "") => {
            if (text === "") {
                $('#selected_asset').html('<span class="text-danger">Nincs kiválasztva semmi!</span>');
            }
            else
                $('#selected_asset').html(text);
        };
        this.selectAsset = (node, type) => {
            this.deSelect();
            this.buttonState('reset_select', true);
            Manage.selected = node;
            $('#show_if_selected').removeClass("d-none");
            $('#layering').removeClass("d-none");
            this.listenIndexChangeButtons(node);
            if (type === 'character') {
                this.updateSelectedText(node.attrs.name);
                this.addTransformerToNode(node);
                this.allowDestruct(node);
                this.listenArmRotateButtons(node);
                this.listenLegTypeChange(node);
            }
            else if (type === 'copy') {
                this.updateSelectedText(node.attrs.name);
                this.addTransformerToNode(node);
                this.allowDestruct(node);
            }
            else if (type === 'konva node') {
                this.updateSelectedText(node.attrs.name);
                this.addTransformerToNode(node);
            }
        };
        this.getAllAsset = (node) => {
            /*let assets:string[] = [];
            node.children?.forEach((ch:any) => {
                ch?.children?.forEach(c => {
                    assets.push(c.attrs.name);
                })
            })
    
            assets.push('glasses');
            assets.push('eyelashes-1');
            assets.push('eyelashes-2');
            */
            let assetsToShow = [
                'hairbg',
                'hair',
                'body',
                'top',
                'bottom',
                'hip',
                'sit',
                'head',
                'eyes',
                'eyebrows',
                'beard',
                'mouth',
                'nose',
                'shoes-1',
                'shoes-2',
                'arms-1',
                'arms-2',
                'hands-1',
                'hands-2',
                'glasses',
                'eyelashes-1',
                'eyelashes-2',
            ];
            return assetsToShow;
        };
        this.isAssetVisible = (node, name) => {
            const asset = node.find('.' + name);
            if (asset.length === 0)
                return null;
            if (asset[0].attrs.visible === undefined) {
                asset[0].setAttr('visible', true);
            }
            return asset[0].attrs.visible;
        };
        this.allowDestruct = (node) => {
            if (__classPrivateFieldGet(Manage, _a, "f", _Manage_data) !== null) {
                $('#destruct_container').empty();
                const all = this.getAllAsset(node);
                all === null || all === void 0 ? void 0 : all.forEach(a => {
                    const radio = $(`<div class="form-check form-check-inline mr-1"><input class="form-check-input" id="${a}" type="checkbox" value="check1" ${this.isAssetVisible(node, a) ? 'checked' : ''}><label class="form-check-label" for="${a}">${a}</label></div>`);
                    radio.on('change', (e) => {
                        var _b, _c;
                        this.toggleAsset(node, e.target.id, e.target.checked);
                        const type = node.attrs.name === 'character_1' ? characterType.GIVER : node.attrs.name === 'character_2' ? characterType.GIVEN : characterType.COPY;
                        const prev = (_c = (_b = __classPrivateFieldGet(Manage, _a, "f", _Manage_data)) === null || _b === void 0 ? void 0 : _b.characters[type]) === null || _c === void 0 ? void 0 : _c['visible'];
                        let newData;
                        if (e.target.checked) {
                            prev === null || prev === void 0 ? void 0 : prev.push(e.target.id);
                            newData = prev;
                        }
                        else {
                            newData = prev === null || prev === void 0 ? void 0 : prev.filter(x => x !== e.target.id);
                        }
                        this.updateData('c', type === 0 ? 'character_1' : 'character_2', 'visible', newData);
                    });
                    $('#destruct_container').append(radio);
                });
            }
        };
        this.findBodyGroup = (char_name) => {
            var _b, _c;
            const character = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.find('.' + char_name)[0];
            let index = 0;
            (_c = character === null || character === void 0 ? void 0 : character.children) === null || _c === void 0 ? void 0 : _c.findIndex((ig, i) => {
                var _b;
                const find = (_b = ig === null || ig === void 0 ? void 0 : ig.children) === null || _b === void 0 ? void 0 : _b.find(g => g.attrs.name.includes('arms') || g.attrs.name.includes('hands'));
                if (find !== undefined)
                    index = i;
            });
            return index;
        };
        this.groupAssets = (nodes) => {
            var _b;
            if (nodes.length >= 2) {
                var group = new Konva.Group();
                //group.add((nodes[0] as Konva.Image))
                //group.add((nodes[1] as Konva.Image))
                this.findBodyGroup((_b = Manage.selected) === null || _b === void 0 ? void 0 : _b.attrs.name);
            }
        };
        this.toggleAsset = (node, assetId, toggle) => {
            const asset = node.findOne('.' + assetId);
            if (asset !== undefined)
                asset.setAttr('visible', toggle);
        };
        this.toggleNode = (node, toggle) => {
            node.setAttr('visible', toggle);
        };
        this.updateData = (what, assetName, key, value) => {
            if (what === 'c' && __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters.length > 0) {
                const assetIndex = __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters.findIndex(x => x.name === assetName);
                if (assetIndex !== -1) {
                    __classPrivateFieldGet(Manage, _a, "f", _Manage_data).characters[assetIndex][key] = value;
                }
            }
        };
        this.getNodeType = (node) => {
            if (node instanceof Konva.Node) {
                if (node.attrs.name.includes('copy_')) {
                    return 'copy';
                }
                else if (node.attrs.name.includes('character_')) {
                    return 'character';
                }
                else
                    return 'konva node';
            }
            else
                return 'unknown object';
        };
        this.addNodeToCanvas = (node, name, layer = null) => {
            var _b;
            if (__classPrivateFieldGet(this, _Manage_stage, "f") !== null) {
                node.name(name);
                node.setAttr('draggable', false);
                const type = this.getNodeType(node);
                if (node instanceof Konva.Layer) {
                    (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.add(node);
                }
                else if (node instanceof Konva.Image) {
                    if (layer !== null)
                        layer.add(node);
                }
                this.addNodeToWorkspace(node);
            }
        };
        __classPrivateFieldSet(this, _Manage_active, activeState, "f");
        Manage.selected = null;
        __classPrivateFieldSet(this, _Manage_stage, stage, "f");
        __classPrivateFieldSet(Manage, _a, load ? load : {
            initialized: false,
            extra: [],
            characters: [],
            workspace: [],
        }, "f", _Manage_data);
        this.files = [];
        if (__classPrivateFieldGet(this, _Manage_active, "f"))
            this.setActive(true, stage);
        $('#reset_select').on('click', () => {
            this.deSelect();
        });
        this.buttonState('reset_select', false);
        this.listenDropdownChange();
        Manage.splitUpbody((_c = (_b = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c[2]);
        Manage.splitUpbody((_e = (_d = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _d === void 0 ? void 0 : _d.children) === null || _e === void 0 ? void 0 : _e[3]);
        this.getFiles().then(res => {
            this.files = res;
        });
        const extraLayer = (_g = (_f = __classPrivateFieldGet(this, _Manage_stage, "f")) === null || _f === void 0 ? void 0 : _f.children) === null || _g === void 0 ? void 0 : _g.filter(l => l.attrs.name === 'extra-fr')[0];
        $('#search_files').on('keyup', (e) => {
            const filtered = this.files.filter(f => f.includes(e.target.value));
            $('#result').children().off();
            $('#result').empty();
            filtered.forEach(f => {
                const res = $(`<div class='result-file'>${f}</div>`);
                res.on('click', () => {
                    if (extraLayer !== undefined) {
                        const id = Math.round(Math.random() * 500);
                        Manage.addSVG(extraLayer, 'extra-' + id, f, -1, { x: 50, y: 50, scaleX: 1, scaleY: 1 }).then((res) => {
                            this.addNodeToCanvas(res, 'extra-' + id, extraLayer);
                        });
                    }
                });
                $('#result').append(res);
            });
        });
    }
    addNodeToWorkspace(node) {
        var _b, _c, _d, _e, _f, _g, _h;
        const type = this.getNodeType(node);
        const name = ((_b = node === null || node === void 0 ? void 0 : node.attrs) === null || _b === void 0 ? void 0 : _b.name) || 'unknown';
        const div = $(`<div class="wp-children d-flex flex-row align-items-center justify-content-between" id="wp-${name}"><span class="name">${name}</span><span class="type">${type}</span><div class="d-flex gap-2 actions"><span class="copy">${(_d = (_c = __classPrivateFieldGet(this, _Manage_addSVG, "f")) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.outerHTML}</span>${type !== 'character' ? '<span class="delete">' + ((_f = (_e = __classPrivateFieldGet(this, _Manage_removeSVG, "f")) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.outerHTML) + '</span>' : ''}<span class="visibility">${(_h = (_g = __classPrivateFieldGet(this, _Manage_visibleSVG, "f")) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.outerHTML}</span></div></div>`);
        const wp = {
            object: node,
            visible: true,
        };
        $('#workspace').append(div);
        $(`#wp-${name} > .name`).on('click', () => {
            this.selectAsset(node, type);
        });
        $(`#wp-${name} > .actions > .visibility`).on('click', () => {
            var _b, _c, _d, _e;
            wp.visible = !wp.visible;
            this.toggleNode(node, wp.visible);
            $(`#wp-${name} > .actions > .visibility > svg`).detach();
            $(`#wp-${name} > .actions > .visibility`).append(!wp.visible ? (_c = (_b = __classPrivateFieldGet(this, _Manage_hiddenSVG, "f")) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.outerHTML : (_e = (_d = __classPrivateFieldGet(this, _Manage_visibleSVG, "f")) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.outerHTML);
        });
        $(`#wp-${name} > .actions > .copy`).on('click', () => {
            const copy = node.clone();
            copy.setAttr('x', node.attrs.x + 5);
            copy.setAttr('y', node.attrs.y + 5);
            this.addNodeToCanvas(copy, 'copy_' + name);
        });
        $(`#wp-${name} > .actions > .delete`).on('click', () => {
            this.deSelect();
            node.off();
            node.destroy();
            const filtered = __classPrivateFieldGet(Manage, _a, "f", _Manage_data).workspace.filter(w => w.object.attrs.name !== name);
            __classPrivateFieldGet(Manage, _a, "f", _Manage_data).workspace = filtered;
            $(`#wp-${name}`).off();
            $(`#wp-${name}`).detach();
        });
        __classPrivateFieldGet(Manage, _a, "f", _Manage_data).workspace.push(wp);
    }
}
_a = Manage, _Manage_active = new WeakMap(), _Manage_stage = new WeakMap(), _Manage_visibleSVG = new WeakMap(), _Manage_hiddenSVG = new WeakMap(), _Manage_addSVG = new WeakMap(), _Manage_removeSVG = new WeakMap();
_Manage_data = { value: void 0 };
Manage.splitUpbody = (characterLayer) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d, _e, _f, _g, _h, _j;
        if (characterLayer === undefined)
            return;
        const isBodySplitted = !(((_c = (_b = characterLayer.children) === null || _b === void 0 ? void 0 : _b[0].children) === null || _c === void 0 ? void 0 : _c[0].attrs.name) === 'body' || ((_e = (_d = characterLayer.children) === null || _d === void 0 ? void 0 : _d[0].children) === null || _e === void 0 ? void 0 : _e[1].attrs.name) === 'body');
        if (!isBodySplitted) {
            const clonedGroup_1 = (_f = characterLayer.children) === null || _f === void 0 ? void 0 : _f[0].clone();
            const clonedGroup_2 = (_g = characterLayer.children) === null || _g === void 0 ? void 0 : _g[0].clone();
            const deleteA = (_h = characterLayer.children) === null || _h === void 0 ? void 0 : _h[0].children.filter(x => x.attrs.name.includes('arms') || x.attrs.name.includes('hands'));
            deleteA.forEach(d => {
                d.destroy();
            });
            const deleteB = clonedGroup_1.children.filter(x => !(x.attrs.name.includes('arms-1') || x.attrs.name.includes('hands-1')));
            deleteB.forEach(d => {
                d.destroy();
            });
            const deleteC = clonedGroup_2.children.filter(x => !(x.attrs.name.includes('arms-2') || x.attrs.name.includes('hands-2')));
            deleteC.forEach(d => {
                d.destroy();
            });
            clonedGroup_1.name('arms-group-1');
            clonedGroup_2.name('arms-group-2');
            /*clonedGroup_1.children[0].stroke('red');
            clonedGroup_1.children[0].strokeWidth(1);
            clonedGroup_2.children[0].stroke('red');
            clonedGroup_2.children[0].strokeWidth(1);*/
            const values = (_j = characterLayer.children) === null || _j === void 0 ? void 0 : _j[0].find('.body')[0].getAttrs();
            yield Manage.addSVG(characterLayer, 'body', values.src.replace('labakkal', 'labnelkul'), -1);
            characterLayer.add(clonedGroup_1);
            characterLayer.add(clonedGroup_2);
            resolve('body split');
        }
    }));
});
Manage.getNewUrlForLeg = (url, leg) => {
    let urls = [];
    const old = url.includes('allo') ? 'allo' : url.includes('ulo') ? 'ulo' : url.includes('futo_hajlitott') ? 'futo_hajlitott' : 'futo';
    const new_ = leg === 0 ? 'allo' : leg === 1 ? 'ulo' : leg === 2 ? 'futo' : 'futo_hajlitott'; //tancnal kett url-t kell returnolni
    if (new_ !== 'futo_hajlitott') {
        urls.push(url.replace(old, new_));
    }
    else {
        urls.push(url.replace(old, 'futo_hajlitott'));
        urls.push((url.replace(old, 'allo')).replace('.svg', '_egy.svg'));
    }
    return urls;
};
Manage.getNewUrlForShoe = (url, leg) => {
    let urls = [];
    const old_1 = url[0].includes('elol') ? 'elol' : 'oldal';
    const old_2 = url[1].includes('elol') ? 'elol' : 'oldal';
    const new_1 = leg === 0 ? 'elol' : leg === 1 ? 'oldal' : leg === 2 ? 'oldal' : 'oldal'; //tancnal kett url-t kell returnolni
    const new_2 = leg === 0 ? 'elol' : leg === 1 ? 'oldal' : leg === 2 ? 'oldal' : 'elol'; //tancnal kett url-t kell returnolni
    urls.push(url[0].replace(old_1, new_1));
    urls.push(url[1].replace(old_2, new_2));
    return urls;
};
Manage.getAssetPosition = (asset, group, body, gender) => {
    return new Promise((resolve) => {
        var _b;
        const charGender = gender !== undefined ? gender : (((_b = Manage.selected) === null || _b === void 0 ? void 0 : _b.attrs.name.includes('1')) ? 0 : 1);
        const charBody = body !== undefined ? body : 0;
        Creator.getDefaultPositions(charGender, charBody).then((res) => __awaiter(void 0, void 0, void 0, function* () {
            const custom_attrs = res;
            if (group !== -1) {
                const custom = custom_attrs.custom[asset === 'dance' ? 'bottom' : asset].filter(r => r.group === group);
                if (custom.length !== 0)
                    resolve(custom[0]);
            }
            resolve(res.default.filter(d => d.group === asset)[0]);
        }));
    });
};
Manage.getGroupBySrc = (src) => {
    return new Promise((resolve) => {
        let reqUrl = `${window.location.origin}/creator/asset/${btoa(src)}`;
        fetch(reqUrl).then((res) => __awaiter(void 0, void 0, void 0, function* () {
            let resJson = yield res.json();
            if (resJson === null)
                resolve(-1);
            resolve(resJson[0].group);
        }));
    });
};
//asset kókány
Manage.setArmAttributes = (layer, asset, type, gender, arm, attr, defaultLayer = null) => {
    const armgroup = layer.find('.arms-group-' + arm)[0];
    const hand = armgroup.find('.hands-' + arm)[0];
    const arms = armgroup.find('.arms-' + arm)[0];
    if (arm === 1) {
        if (type === 'str') {
            const defaultHand = defaultLayer.findOne('.hands-1');
            const defaultArm = defaultLayer.findOne('.arms-1');
            hand.rotation(defaultHand.attrs.rotation);
            hand.setX(defaultHand.attrs.x);
            hand.setY(defaultHand.attrs.y);
            hand.setScale({ x: defaultHand.attrs.scaleX, y: defaultHand.attrs.scaleY });
            asset.setX(defaultArm.attrs.x);
            asset.setY(defaultArm.attrs.y);
            asset.rotation(defaultArm.attrs.rotation);
            asset.setScale({ x: defaultArm.attrs.scaleX, y: defaultArm.attrs.scaleY });
        }
        else if (type === 'bnt') {
            asset.setX(attr.x);
            asset.setY(attr.y);
            asset.setScale({ x: 0.45, y: 0.45 });
            arms.setX(gender == 1 ? arms.attrs.x : (arms.attrs.x - 12));
            arms.setY(gender == 1 ? arms.attrs.y : (arms.attrs.y - 2));
            hand.rotation(gender === 1 ? -90 : 0);
            hand.setX(gender === 1 ? 54 : 75);
            hand.setY(gender === 1 ? 139 : 109);
            hand.setScale({ x: 0.10, y: 0.10 });
        }
        else if (type === 'bnt-1') {
            asset.setX(attr.x);
            asset.setY(attr.y);
            asset.setScale({ x: 0.45, y: 0.45 });
            arms.setX(gender == 1 ? arms.attrs.x : (arms.attrs.x - 12));
            arms.setY(gender == 1 ? arms.attrs.y : (arms.attrs.y - 2));
            hand.rotation(gender === 1 ? -55 : 0);
            hand.setX(gender === 1 ? 53 : 101);
            hand.setY(gender === 1 ? 140 : 112.5);
            hand.setScale({ x: 0.10, y: 0.10 });
        }
        else {
            asset.setX(attr.x);
            asset.setY(attr.y);
            asset.setScale({ x: 0.45, y: 0.45 });
            arms.setX(gender == 1 ? arms.attrs.x : (arms.attrs.x - 12));
            arms.setY(gender == 1 ? arms.attrs.y : (arms.attrs.y - 2));
            hand.rotation(gender === 1 ? -80 : 0);
            hand.setX(gender === 1 ? 96 : 101);
            hand.setY(gender === 1 ? 153 : 112.5);
            hand.setScale({ x: 0.10, y: 0.09 });
        }
    }
    else {
        if (type === 'str') {
            const defaultHand = defaultLayer.findOne('.hands-2');
            const defaultArm = defaultLayer.findOne('.arms-2');
            hand.rotation(defaultHand.attrs.rotation);
            hand.setX(defaultHand.attrs.x);
            hand.setY(defaultHand.attrs.y);
            hand.setScale({ x: defaultHand.attrs.scaleX, y: defaultHand.attrs.scaleY });
            asset.setX(defaultArm.attrs.x);
            asset.setY(defaultArm.attrs.y);
            asset.rotation(defaultArm.attrs.rotation);
            asset.setScale({ x: defaultArm.attrs.scaleX, y: defaultArm.attrs.scaleY });
        }
        else if (type === 'bnt') {
            asset.setX(attr.x);
            asset.setY(attr.y);
            asset.setScale({ x: 0.45, y: 0.45 });
            arms.setX(gender == 1 ? arms.attrs.x : (arms.attrs.x + 12));
            arms.setY(gender == 1 ? arms.attrs.y : (arms.attrs.y - 3));
            hand.rotation(gender === 1 ? -101 : 0);
            hand.setX(gender === 1 ? 134.5 : 152);
            hand.setY(gender === 1 ? 116.5 : 110.5);
            hand.setScale({ x: 0.10, y: 0.10 });
        }
        else if (type === 'bnt-1') {
            asset.setX(attr.x);
            asset.setY(attr.y);
            asset.setScale({ x: 0.45, y: 0.45 });
            arms.setX(gender == 1 ? arms.attrs.x : (arms.attrs.x + 12));
            arms.setY(gender == 1 ? arms.attrs.y : (arms.attrs.y - 2));
            hand.rotation(gender === 1 ? -85 : 0);
            hand.setX(gender === 1 ? 135.5 : 175);
            hand.setY(gender === 1 ? 118.5 : 114.5);
            hand.setScale({ x: 0.10, y: 0.10 });
        }
        else {
            asset.setX(attr.x);
            asset.setY(attr.y);
            asset.setScale({ x: 0.45, y: 0.45 });
            arms.setX(gender == 1 ? arms.attrs.x : (arms.attrs.x + 12));
            arms.setY(gender == 1 ? arms.attrs.y : (arms.attrs.y - 5));
            hand.rotation(gender === 1 ? -95 : 0);
            hand.setX(gender === 1 ? 178.5 : 172);
            hand.setY(gender === 1 ? 111.5 : 112);
            hand.setScale({ x: 0.10, y: 0.09 });
        }
    }
    //move hardcoded stuff here from add svg to use outside the class
};
Manage.addSVG = (layer, asset, src, group, attr = null, extra = false, armType = null, body = undefined, gender = undefined, extendAttr = null) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve) => __awaiter(void 0, void 0, void 0, function* () {
        const self = _a;
        let assets = layer.find(`.${asset}`);
        if (group === -1 && asset !== 'body' && asset !== 'arms-1' && asset !== 'arms-2') {
            group = yield Manage.getGroupBySrc(src);
        }
        if (attr === null) {
            attr = yield Manage.getAssetPosition(asset, group, body, gender);
        }
        const assetName = asset;
        src = src.includes('http') || src.includes('https') ? src : window.location.origin + '/' + src;
        if (assets[0] !== undefined) {
            let asset = assets[0];
            const char = parseInt(layer.attrs.name.split('_')[1]) - 1;
            let imageObj = new Image();
            imageObj.src = src;
            imageObj.onload = function () {
                asset.attrs.src = src;
                asset.image(imageObj);
                //XDDXDDDDDDDDDDDDDD
                let actualGender = (src.includes('noi') ? 1 : 0);
                //console.log(char.toString() + ": " + ((src.includes('noi') ? 1 : 0) === 0 ? 'male' : 'female'));
                const deflayer = Konva.Node.create(presets[actualGender === 0 ? 'male' : 'female']['thin']);
                if (asset.attrs.name === 'body') {
                    if (asset.attrs.src.includes('ffi')) {
                        asset.setX(3);
                        asset.setY(1.75);
                        asset.setScale({ x: 1.14, y: 1.14 });
                    }
                    else {
                        asset.setX(1);
                        asset.setY(0);
                        asset.setScale({ x: 1.15, y: 1.15 });
                    }
                }
                else if (asset.attrs.name.includes('arms-1')) {
                    Manage.setArmAttributes(layer, asset, armType === null ? __classPrivateFieldGet(self, _a, "f", _Manage_data).characters[char].armType_l : armType, actualGender === 0 ? 0 : 1, 1, attr, deflayer);
                }
                else if (asset.attrs.name.includes('arms-2')) {
                    Manage.setArmAttributes(layer, asset, armType === null ? __classPrivateFieldGet(self, _a, "f", _Manage_data).characters[char].armType_r : armType, actualGender === 0 ? 0 : 1, 2, attr, deflayer);
                }
                else {
                    if (extra) {
                        asset.setX(attr.x1);
                        asset.setY(attr.y1);
                        asset.setScale({ x: attr.scaleX1, y: attr.scaleY1 });
                        if (attr.rotation1 !== undefined && attr.rotation1 !== null) {
                            asset.rotation(attr.rotation1);
                        }
                    }
                    else {
                        let x = attr.x;
                        let y = attr.y;
                        if (extendAttr !== null) {
                            x = x + extendAttr.x;
                            y = y + extendAttr.y;
                        }
                        asset.setX(x);
                        asset.setY(y);
                        asset.setScale({ x: attr.scaleX, y: attr.scaleY });
                        if (attr.rotation !== undefined && attr.rotation !== null) {
                            asset.rotation(attr.rotation);
                        }
                    }
                }
                //if(da.attrs?.forceZIndex === true) {
                //    asset.setZIndex(da.attrs.zIndex);
                //}
                resolve(asset);
            };
        }
        else {
            Konva.Image.fromURL(src, (image) => {
                let x = attr.x;
                let y = attr.y;
                if (extendAttr !== null) {
                    x = x + extendAttr.x;
                    y = y + extendAttr.y;
                }
                image.setAttrs({
                    x: x,
                    y: y,
                    scaleX: attr === null || attr === void 0 ? void 0 : attr.scaleX,
                    scaleY: attr === null || attr === void 0 ? void 0 : attr.scaleY,
                    name: assetName,
                    rotation: 0,
                    src: src
                });
                if ((attr === null || attr === void 0 ? void 0 : attr.rotation) !== undefined && (attr === null || attr === void 0 ? void 0 : attr.rotation) !== null) {
                    image.rotation(attr.rotation);
                }
                resolve(image);
            });
        }
    }));
});
