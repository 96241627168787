var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _a, _Creator_canvas, _Creator_choose_box, _Creator_isAdmin, _Creator_data;
import $ from "cash-dom";
import Konva from "konva";
import Swiper, { Navigation } from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
var Gender;
(function (Gender) {
    Gender[Gender["MALE"] = 0] = "MALE";
    Gender[Gender["FEMALE"] = 1] = "FEMALE";
})(Gender || (Gender = {}));
;
var Body;
(function (Body) {
    Body[Body["THIN"] = 0] = "THIN";
    Body[Body["FAT"] = 1] = "FAT";
})(Body || (Body = {}));
;
var SkinTone;
(function (SkinTone) {
    SkinTone["LIGHT"] = "vilagos";
    SkinTone["MID_DARK"] = "kozepsotet";
    SkinTone["DARK"] = "sotet";
})(SkinTone || (SkinTone = {}));
var Group;
(function (Group) {
    Group["SKIN"] = "skins";
    Group["HEADS"] = "heads";
    Group["HAIRS"] = "hairs";
    Group["NOSES"] = "noses";
    Group["EYES"] = "eyes";
    Group["MOUTHS"] = "mouths";
    Group["EYEBROWS"] = "eyebrows";
    Group["BODIES"] = "bodies";
    Group["DRESSES"] = "dresses";
    Group["BEARDS"] = "beards";
    Group["PANTS"] = "pants";
    Group["SHOES"] = "shoes";
    Group["ARMS"] = "arms";
    Group["HANDS"] = "hands";
    Group["HIP"] = "hip";
    Group["HAIRBG"] = "hairbg";
    Group["GLASSES"] = "glasses";
    Group["EYELASHES"] = "eyelashes";
})(Group || (Group = {}));
var Color;
(function (Color) {
    Color["ABARNA"] = "aranybarna";
    Color["BARNA"] = "barna";
    Color["FEHER"] = "feher";
    Color["FEKETE"] = "fekete";
    Color["KEK"] = "kek";
    Color["NARANCS"] = "narancs";
    Color["RSZIN"] = "rozsaszin";
    Color["SBARNA"] = "sotet_barna";
    Color["SVOROS"] = "sotet_voros";
    Color["SZOKE"] = "szoke";
    Color["SZURKE"] = "szurke";
    Color["VBARNA"] = "vilagos_barna";
    Color["VOROS"] = "voros";
    Color["ZOLD"] = "zold";
    Color["CSILLAG"] = "csillag";
    Color["IKSZ"] = "iksz";
    Color["CSUKOTT"] = "csukott";
    Color["SZIVES"] = "szives";
})(Color || (Color = {}));
export default class Creator {
    constructor(canvas_id, choose_box_id, data, isAdmin = false) {
        _Creator_canvas.set(this, void 0);
        _Creator_choose_box.set(this, void 0);
        _Creator_isAdmin.set(this, void 0);
        _Creator_data.set(this, void 0);
        this.initialize = () => {
            if (__classPrivateFieldGet(this, _Creator_choose_box, "f")) {
                let buttons = __classPrivateFieldGet(this, _Creator_choose_box, "f").children('.btn');
                let groups = __classPrivateFieldGet(this, _Creator_choose_box, "f").children('.group');
                const self = this;
                buttons.each((index, element) => {
                    $(element).on('click', (target) => {
                        if (groups[index]) {
                            let element = $(groups[index]);
                            self.closeAllChooseBox();
                            element.toggle();
                            buttons.removeClass('active');
                            $(target.target).addClass('active');
                            let group = Group[Creator.getEnumKeyByValue(Group, element.attr('id') || '')];
                            let groupElement = $('#' + group);
                            if (groupElement.attr('data-init') === "false" || group === Group.BEARDS) {
                                this.createImageLoaders(group, null);
                                this.getAssets(group).then(res => {
                                    let groupElement = $('#' + group);
                                    let needColor = this.needsDefaultColor.find(g => g === group) ? true : false;
                                    if (needColor)
                                        this.setColor(group, group === Group.EYES ? Color.KEK : Color.ABARNA, false);
                                    groupElement.attr('data-init', 'true');
                                    this.correctPlaceholdersAmount(group, this.pagination[group].max);
                                    this.loadImage(group, this.pagination[group].from, this.pagination[group].to, this.pagination[group].data);
                                    if (__classPrivateFieldGet(this, _Creator_isAdmin, "f") && group === Group.BEARDS) {
                                        this.getAssets(group, 1).then(res => {
                                            this.loadImage(group, this.pagination[group].from, this.pagination[group].to, this.pagination[group].data);
                                        });
                                    }
                                });
                            }
                            self.zoomIfNeccessary(group);
                        }
                    });
                });
                if (__classPrivateFieldGet(this, _Creator_isAdmin, "f")) {
                    this.disableEditorButtons(true);
                    $('#save-asset').on('click', () => {
                        if (this.transformState.enabled && this.transformState.group !== null && this.transformState.data !== null) {
                            const attributes = this.canvasGetDefaultAttributes(this.transformState.group);
                            if (attributes !== undefined && attributes !== null) {
                                let asset = [];
                                if (attributes && (attributes === null || attributes === void 0 ? void 0 : attributes.length) > 1) {
                                    attributes.forEach(attr => {
                                        asset.push(this.stage.find(`.${attr.attrs.name}`)[0]);
                                    });
                                }
                                else {
                                    asset = [this.stage.find(`.${attributes[0].attrs.name}`)[0]];
                                }
                                if (asset.length >= 1) {
                                    asset.forEach((a) => {
                                        var _b;
                                        let groupId = ((_b = this.getAssetAttributes(a.attrs.src)) === null || _b === void 0 ? void 0 : _b.group) || -1;
                                        this.saveAssetPosition(a.attrs.name, groupId, a.attrs);
                                        /*
                                        if(this.transformState.group === Group.EYELASHES) {
                                            groupId = this.stage.findOne('.eyes').attrs.groupid;
                                            this.saveEyelashes(a.attrs, groupId);
                                        }else{
                                    #        this.saveAssetPosition(a.attrs.name, groupId, a.attrs);
                                        }
                                    */
                                    });
                                }
                            }
                        }
                    });
                    $('#save-extra').on('click', () => {
                        if (this.transformState.enabled && this.transformState.group !== null && this.transformState.data !== null) {
                            const attributes = this.canvasGetDefaultAttributes(this.transformState.group);
                            if (attributes !== undefined && attributes !== null) {
                                let asset = [];
                                if (attributes && (attributes === null || attributes === void 0 ? void 0 : attributes.length) > 1) {
                                    attributes.forEach(attr => {
                                        asset.push(this.stage.find(`.${attr.attrs.name}`)[0]);
                                    });
                                }
                                else {
                                    asset = [this.stage.find(`.${attributes[0].attrs.name}`)[0]];
                                }
                                if (asset.length >= 1) {
                                    asset.forEach((a) => {
                                        var _b;
                                        const groupId = ((_b = this.getAssetAttributes(a.attrs.src)) === null || _b === void 0 ? void 0 : _b.group) || -1;
                                        if (this.transformState.data !== null) {
                                            this.saveAssetPosition(a.attrs.name, groupId, { x1: a.attrs.x, y1: a.attrs.y, scaleX1: a.attrs.scaleX, scaleY1: a.attrs.scaleY, rotation1: a.attrs.rotation });
                                        }
                                    });
                                }
                            }
                        }
                    });
                    $('#z-index-down').on('click', () => {
                        if (this.transformState.group !== null) {
                            const attributes = this.canvasGetDefaultAttributes(this.transformState.group);
                            if (attributes !== undefined && attributes !== null) {
                                if (attributes && (attributes === null || attributes === void 0 ? void 0 : attributes.length) > 1) {
                                    //prob shoe
                                }
                                else {
                                    const asset = this.stage.find(`.${attributes[0].attrs.name}`);
                                    asset[0].moveDown();
                                    if (this.transformState.data !== null && this.transformState.data.zIndex !== null && this.transformState.data.zIndex !== undefined) {
                                        this.transformState.data.zIndex--;
                                        this.updateTransformerText();
                                    }
                                }
                            }
                        }
                    });
                    $('#z-index-up').on('click', () => {
                        if (this.transformState.group !== null) {
                            const attributes = this.canvasGetDefaultAttributes(this.transformState.group);
                            if (attributes !== undefined && attributes !== null) {
                                if (attributes && (attributes === null || attributes === void 0 ? void 0 : attributes.length) > 1) {
                                    //prob shoe
                                }
                                else {
                                    const asset = this.stage.find(`.${attributes[0].attrs.name}`);
                                    asset[0].moveUp();
                                    if (this.transformState.data !== null && this.transformState.data.zIndex !== null && this.transformState.data.zIndex !== undefined) {
                                        this.transformState.data.zIndex++;
                                        this.updateTransformerText();
                                    }
                                }
                            }
                        }
                    });
                    $('#toggle-arms').on('click', () => {
                        const arms = [this.stage.find('.arms-1')[0], this.stage.find('.arms-2')[0], this.stage.find('.hands-1')[0], this.stage.find('.hands-2')[0]];
                        arms.forEach(arm => {
                            arm.setAttr('visible', !this.transformState.arms);
                        });
                        this.transformState.arms = !this.transformState.arms;
                        $('#toggle-arms').addClass(this.transformState.arms ? 'btn-outline-success' : 'btn-outline-danger');
                    });
                    $('#scale-up').on('click', () => {
                        const scaleSpan = $('#scale-value');
                        const scaleVal = parseFloat(scaleSpan.html());
                        scaleSpan.html('' + Math.round(((scaleVal + 0.05) + Number.EPSILON) * 100) / 100);
                        const shoes = [this.stage.find('.shoes-1')[0], this.stage.find('.shoes-2')[0]];
                        shoes[0].setAttr('scaleX', (shoes[0].getAttr('scale').x < 0 ? -1 : 1) * (scaleVal + 0.05));
                        shoes[0].setAttr('scaleY', (shoes[0].getAttr('scale').y < 0 ? -1 : 1) * (scaleVal + 0.05));
                        shoes[1].setAttr('scaleX', (shoes[1].getAttr('scale').x < 0 ? -1 : 1) * (scaleVal + 0.05));
                        shoes[1].setAttr('scaleY', (shoes[1].getAttr('scale').x < 0 ? -1 : 1) * (scaleVal + 0.05));
                    });
                    $('#scale-down').on('click', () => {
                        const scaleSpan = $('#scale-value');
                        const scaleVal = parseFloat(scaleSpan.html());
                        scaleSpan.html('' + Math.round(((scaleVal - 0.05) + Number.EPSILON) * 100) / 100);
                        const shoes = [this.stage.find('.shoes-1')[0], this.stage.find('.shoes-2')[0]];
                        shoes[0].setAttr('scaleX', (shoes[0].getAttr('scale').x < 0 ? -1 : 1) * (scaleVal - 0.05));
                        shoes[0].setAttr('scaleY', (shoes[0].getAttr('scale').y < 0 ? -1 : 1) * (scaleVal - 0.05));
                        shoes[1].setAttr('scaleX', (shoes[1].getAttr('scale').x < 0 ? -1 : 1) * (scaleVal - 0.05));
                        shoes[1].setAttr('scaleY', (shoes[1].getAttr('scale').x < 0 ? -1 : 1) * (scaleVal - 0.05));
                    });
                    $('#mirror-x').on('click', () => {
                        var _b;
                        const shoe = this.stage.find('.' + ((_b = this.transformState.data) === null || _b === void 0 ? void 0 : _b.name))[0];
                        shoe.scaleX(-shoe.scaleX());
                    });
                    $('#mirror-y').on('click', () => {
                        var _b;
                        const shoe = this.stage.find('.' + ((_b = this.transformState.data) === null || _b === void 0 ? void 0 : _b.name))[0];
                        shoe.scaleY(-shoe.scaleY());
                    });
                    $('#toggle-eyelashes').on('click', () => {
                        const eyes = this.stage.findOne('.eyes');
                        let customAttrs = this.canvasGetCustomAttributes(Group.EYELASHES, eyes.attrs.groupid);
                        let attrs = (customAttrs === null) ? this.canvasGetDefaultAttributes(Group.EYES) : customAttrs;
                        let canvasGr = this.getCanvasGroupByAssetGroup(Group.EYELASHES);
                        let customAttr = [];
                        if (attrs !== null)
                            customAttr = customAttrs === null ? [{
                                    assetGroup: Group.EYELASHES, image: null,
                                    attrs: Object.assign(Object.assign({}, attrs[0].attrs), { name: 'eyelashes-1', scaleX: 0.075, scaleY: 0.075, y: attrs[0].attrs.y - 25 })
                                }, {
                                    assetGroup: Group.EYELASHES, image: null,
                                    attrs: Object.assign(Object.assign({}, attrs[0].attrs), { name: 'eyelashes-2', scaleX: -0.075, scaleY: 0.075, x: attrs[0].attrs.x + 30, y: attrs[0].attrs.y - 25 })
                                }] : customAttrs;
                        if (attrs !== null) {
                            this.canvasAddSVG(`${window.location.origin}/images/eyelashes/szempilla_nagy.svg`, customAttr, canvasGr, undefined).then((ass) => {
                                if (customAttrs === null) {
                                    ass.rotation(0);
                                }
                                this.toggleTransformByGroup(true, Group.EYELASHES);
                            });
                        }
                    });
                }
                else {
                    $('#save_and_exit').on('click', () => {
                        this.resetZoom();
                        this.toggleCanvasLoader(true, '#0C5039');
                        let canvasData = this.stage.toJSON();
                        let layer = JSON.parse(canvasData).children[0];
                        __classPrivateFieldGet(this, _Creator_data, "f").canvas = canvasData;
                        localStorage.setItem('character_' + __classPrivateFieldGet(this, _Creator_data, "f").character, JSON.stringify(__classPrivateFieldGet(this, _Creator_data, "f")));
                        localStorage.setItem('character_layer_' + __classPrivateFieldGet(this, _Creator_data, "f").character, JSON.stringify(layer));
                        window.location.href = (origin + "/" + window.location.pathname.split("/")[1]);
                    });
                }
                let gender = __classPrivateFieldGet(this, _Creator_data, "f").gender === 0 ? Gender.MALE : Gender.FEMALE;
                let body = __classPrivateFieldGet(this, _Creator_data, "f").body === 0 ? Body.THIN : Body.FAT;
                this.fetchSavedPositions(gender, body).then((res) => __awaiter(this, void 0, void 0, function* () {
                    this.setGender(gender);
                    this.toggleCanvasLoader(true, '#0C5039');
                    yield this.initializeCanvas(__classPrivateFieldGet(this, _Creator_data, "f").canvas).then((res) => {
                        this.setBodyFigure(body);
                        //ASDF
                        if (__classPrivateFieldGet(this, _Creator_data, "f").canvas && (location.pathname.split('/')[2] === 'edit' || __classPrivateFieldGet(this, _Creator_isAdmin, "f"))) {
                            const loadedData = JSON.parse(__classPrivateFieldGet(this, _Creator_data, "f").canvas);
                            const selectedBodyAsset = loadedData.children[0].children[0].children.find(x => x.attrs.name === 'body');
                            const skinTone = SkinTone[Creator.getEnumKeyByValue(SkinTone, selectedBodyAsset.attrs.src.split('_')[1])];
                            this.setSkinTone(skinTone);
                            this.constructCharacter(loadedData).finally(() => this.toggleCanvasLoader(false));
                            this.attachAdditionalAsset(Group.ARMS, null);
                        }
                        else {
                            this.setSkinTone(SkinTone.LIGHT);
                            this.selectAsset(Group.BODIES, this.getDefault(Group.BODIES));
                            this.selectAsset(Group.HEADS, this.getDefault(Group.HEADS));
                            this.toggleCanvasLoader(false, null);
                        }
                        //this.attachAdditionalAsset(Group.ARMS, this.selectedAssets.assets.DRESSES || null);
                        this.attachAdditionalAsset(Group.HANDS);
                        this.initializeSwipers();
                    });
                }));
            }
        };
        this.fetchSavedPositions = (gender, body) => {
            return new Promise((resolve, reject) => {
                Creator.getDefaultPositions(gender, body).then((res) => __awaiter(this, void 0, void 0, function* () {
                    var _b;
                    this.customAssetAttributes = [];
                    for (const [, value] of Object.entries(res.custom)) {
                        const values = value;
                        if (values.length >= 1) {
                            values.forEach(v => {
                                let group = Group[Creator.getEnumKeyByValue(Group, Creator.canvasIdMap[v.asset])];
                                this.customAssetAttributes.push({
                                    assetGroup: group,
                                    groupId: v.group,
                                    image: null,
                                    attrs: {
                                        name: v.asset,
                                        x: v.x,
                                        y: v.y,
                                        scaleX: v.scaleX,
                                        scaleY: v.scaleY,
                                        zIndex: v.zIndex,
                                        rotate: v.rotation,
                                    }
                                });
                            });
                        }
                    }
                    (_b = res === null || res === void 0 ? void 0 : res.default) === null || _b === void 0 ? void 0 : _b.map(x => {
                        let group = Group[Creator.getEnumKeyByValue(Group, Creator.canvasIdMap[x.group])];
                        this.defaultAssetAttributes.push({
                            assetGroup: group,
                            image: null,
                            attrs: {
                                name: x.group,
                                x: x.x,
                                y: x.y,
                                scaleX: x.scaleX,
                                scaleY: x.scaleY,
                                zIndex: x.zIndex,
                                rotate: x.rotation
                            }
                        });
                    });
                    resolve('pos loaded');
                })).catch(err => reject(err));
            });
        };
        this.toggleCanvasLoader = (toggle, bg = null) => {
            const loaderContainer = $('.canvas-loader');
            if (bg !== null)
                loaderContainer.css('background-color', bg);
            if (toggle) {
                loaderContainer.removeClass('d-none');
                loaderContainer.addClass('d-flex');
            }
            else {
                loaderContainer.removeClass('d-flex');
                loaderContainer.addClass('d-none');
            }
        };
        /*
        saveEyelashes = (data:any, eyeGroupId:number) => {
            let url = `${window.location.origin}/creator/save/eyelashes/${eyeGroupId}`;
    
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') || ""
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(r => {
                this.endAssetTransform();
            })
        }
        */
        this.saveAssetPosition = (group, groupId, data) => {
            let url = `${window.location.origin}/creator/save/`;
            fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') || ""
                },
                body: JSON.stringify(Object.assign(Object.assign({}, data), { group: groupId, asset: group, gender: this.selectedAssets.gender, body: this.selectedAssets.bodyFigure }))
            })
                .then(response => response.json())
                .then(r => {
                this.endAssetTransform();
            });
        };
        this.endAssetTransform = () => {
            if (this.transformState.group !== null)
                this.toggleTransformByGroup(false, this.transformState.group);
            this.updateTransformerText("", "Position saved!");
            if (this.selectedAssets.gender !== null && this.selectedAssets.bodyFigure !== null) {
                this.fetchSavedPositions(this.selectedAssets.gender, this.selectedAssets.bodyFigure);
            }
        };
        this.initializeCanvas = (savedData) => __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                var _b, _c;
                let width = 350;
                let height = ((_b = $(__classPrivateFieldGet(this, _Creator_canvas, "f"))[0]) === null || _b === void 0 ? void 0 : _b.clientHeight) || 400;
                let headGroup = new Konva.Group({
                    x: width / 2 - 47.5,
                    y: 0
                });
                let bodyGroup = new Konva.Group({
                    x: width / 2 - (this.selectedAssets && this.selectedAssets.gender === Gender.MALE ? 55 : 52.5),
                    y: 40
                });
                let legGroup = new Konva.Group({
                    x: (this.selectedAssets && this.selectedAssets.gender === Gender.MALE ? 50 : 52.5),
                    y: 120
                });
                let feetGroup = new Konva.Group({
                    x: (this.selectedAssets && this.selectedAssets.gender === Gender.MALE ? 55 : 52.5),
                    y: 50
                });
                this.canvasGroups = [
                    {
                        group: bodyGroup,
                        name: 'body',
                    },
                    {
                        group: legGroup,
                        name: 'leg',
                    },
                    {
                        group: headGroup,
                        name: 'head',
                    },
                    {
                        group: feetGroup,
                        name: 'feet',
                    },
                ];
                if (savedData !== null && savedData !== undefined) {
                    this.stage = Konva.Node.create(savedData, 'canvas');
                    if (__classPrivateFieldGet(this, _Creator_isAdmin, "f")) {
                        this.stage.setAttrs({
                            width: 698,
                            height: 798,
                            scaleY: 2,
                            scaleX: 2,
                        });
                    }
                }
                else {
                    var stage = new Konva.Stage({
                        container: 'canvas',
                        height: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? 850 : height,
                        width: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? 698 : width,
                        scaleY: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? 2 : 1,
                        scaleX: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? 2 : 1,
                    });
                    var layer = new Konva.Layer();
                    stage.add(layer);
                    this.stage = stage;
                    this.canvasGroups.map(gr => layer.add(gr.group));
                    let grouppedAssets = this.getGrouppedAssetGroups();
                    for (let i = 0; i < grouppedAssets.length; ++i) {
                        for (let j = 0; j < grouppedAssets[i].length; ++j) {
                            if (grouppedAssets[i][j] !== undefined) {
                                //get custom attributes if exist
                                let attrs = this.canvasGetDefaultAttributes(grouppedAssets[i][j]) || null;
                                if (attrs !== null) {
                                    yield this.canvasAddSVG(`${window.location.origin}/images/transparent.png`, attrs, (_c = this.canvasGroups) === null || _c === void 0 ? void 0 : _c[i]);
                                }
                            }
                        }
                    }
                }
                resolve('kesz init');
            }));
        });
        this.constructCharacter = (data) => __awaiter(this, void 0, void 0, function* () {
            if (data) {
                return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                    var _d;
                    let loadedAssets = (_d = Creator.getLoadedAssets(data)) !== null && _d !== void 0 ? _d : {};
                    let entries = Object.entries(loadedAssets);
                    for (let i = 0; i < entries.length; i++) {
                        if (entries[i][1].group && entries[i][1].src && entries[i][1].group !== Group.DRESSES) {
                            yield this.selectAsset(entries[i][1].group, entries[i][1].src, entries[i][1].posGroupId);
                        }
                    }
                    resolve('kesz char load');
                }));
            }
        });
        this.canvasGetDefaultAttributes = (assetGroup) => {
            if (assetGroup === undefined)
                return null;
            let defaultAttribute = [];
            let defAttr = this.defaultAssetAttributes.find(x => x.assetGroup === assetGroup);
            if (defAttr !== undefined)
                defaultAttribute.push(defAttr);
            if (assetGroup === Group.SHOES || assetGroup === Group.ARMS || assetGroup === Group.HANDS || assetGroup === Group.EYELASHES) {
                let attr_1 = this.defaultAssetAttributes.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-1`);
                let attr_2 = this.defaultAssetAttributes.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-2`);
                if (attr_1 && attr_2) {
                    defaultAttribute = [];
                    defaultAttribute.push(attr_1);
                    defaultAttribute.push(attr_2);
                }
            }
            return defaultAttribute.length === 0 ? null : defaultAttribute;
        };
        this.canvasGetCustomAttributes = (assetGroup, groupId) => {
            if (assetGroup === undefined)
                return null;
            let attribute = [];
            let defAttr = this.customAssetAttributes.find(x => x.assetGroup === assetGroup && x.groupId === groupId);
            if (defAttr !== undefined)
                attribute.push(defAttr);
            if (assetGroup === Group.SHOES || assetGroup === Group.ARMS || assetGroup === Group.HANDS || assetGroup === Group.EYELASHES) {
                let attr_1 = this.customAssetAttributes.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-1` && x.groupId === groupId);
                let attr_2 = this.customAssetAttributes.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-2` && x.groupId === groupId);
                if (attr_1 && attr_2) {
                    attribute = [];
                    attribute.push(attr_1);
                    attribute.push(attr_2);
                }
            }
            return attribute.length === 0 ? null : attribute;
        };
        this.canvasRemoveSVG = (assetName) => __awaiter(this, void 0, void 0, function* () {
            let asset = this.stage.findOne(`.${assetName}`);
            const transparentURL = `${window.location.origin}/images/transparent.png`;
            if (asset !== undefined && asset !== null) {
                let imageObj = new Image();
                imageObj.src = transparentURL;
                imageObj.onload = function () {
                    asset.attrs.src = transparentURL;
                    asset.image(imageObj);
                };
                let group = Group[Creator.getEnumKeyByValue(Group, Creator.canvasIdMap[asset.attrs.name])];
                this.selectedAssets.assets[group === null || group === void 0 ? void 0 : group.toUpperCase()] = transparentURL;
                if (__classPrivateFieldGet(this, _Creator_isAdmin, "f")) {
                    this.toggleTransformByGroup(false, group);
                }
                this.deSelectAsset(group);
                if (group === Group.PANTS) {
                    this.removeAdditionalAsset(Group.HIP);
                }
                if (group === Group.DRESSES) {
                    this.removeAdditionalAsset(Group.ARMS);
                }
                if (group === Group.HAIRS) {
                    this.removeAdditionalAsset(Group.HAIRBG);
                }
            }
        });
        this.removeAdditionalAsset = (group) => {
            this.selectedAssets.assets[group === null || group === void 0 ? void 0 : group.toUpperCase()] = "";
            if (group === Group.ARMS) {
                this.attachAdditionalAsset(Group.ARMS, null, null);
            }
            else {
                this.canvasRemoveSVG(group);
            }
        };
        this.deSelectAsset = (category) => {
            let elements = $(`#${category}-swiper > .swiper-wrapper .swiper-slide`);
            elements.each((i, e) => { var _b; return (_b = $(e)) === null || _b === void 0 ? void 0 : _b.children().first().removeClass('selected'); });
        };
        this.getImage = (url) => {
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = url;
            });
        };
        this.canvasAddSVG = (url, defaultAttrs, canvasGroup, posGroupId = undefined, extraPos = false) => {
            const self = this;
            return new Promise((resolve) => {
                defaultAttrs.map((da) => __awaiter(this, void 0, void 0, function* () {
                    var _b;
                    let assets = this.stage.find(`.${da.attrs.name}`);
                    if (canvasGroup.name === 'head' && da.assetGroup === Group.HEADS && this.selectedAssets.gender === Gender.MALE) {
                        let head = url.split('/')[url.split('/').length - 1];
                        let headNum = parseInt(head.split('_')[0][head.split('_')[0].length - 1]);
                        if (headNum === 3) {
                            canvasGroup.group.setAttr('y', canvasGroup.group.y() + 5);
                        }
                        else {
                            canvasGroup.group.setAttr('y', 0);
                        }
                    }
                    if (assets[0] !== undefined) {
                        let asset = assets[0];
                        let groupid = posGroupId || ((_b = this.getAssetAttributes(url)) === null || _b === void 0 ? void 0 : _b.group);
                        let imageObj = new Image();
                        imageObj.src = url;
                        imageObj.onload = function () {
                            var _b;
                            asset.attrs.src = url;
                            asset.attrs.groupid = groupid;
                            asset.image(imageObj);
                            if (extraPos) {
                                asset.setX(da.attrs.x1);
                                asset.setY(da.attrs.y1);
                                asset.setScale({ x: da.attrs.scaleX1, y: da.attrs.scaleY1 });
                                if (da.attrs.rotate1 !== undefined && da.attrs.rotate1 !== null) {
                                    asset.rotation(da.attrs.rotate1);
                                }
                            }
                            else {
                                asset.setX(da.attrs.x);
                                asset.setY(da.attrs.y);
                                asset.setScale({ x: da.attrs.scaleX, y: da.attrs.scaleY });
                                if (((_b = da.attrs) === null || _b === void 0 ? void 0 : _b.forceZIndex) === true) {
                                    asset.setZIndex(da.attrs.zIndex);
                                }
                                if (da.attrs.rotate !== undefined && da.attrs.rotate !== null) {
                                    asset.rotation(da.attrs.rotate);
                                }
                                // NOTE: i need to kill myself
                                /*if(da.attrs.name === 'eyelashes-1' && !url.includes('nagy')) {
                                    /*asset.offsetX(asset.width() * 0.075);
                                    asset.offsetY(asset.height() * 0.075);
    
                                    if(url.includes('kicsi')) {
                                        var circle = new Konva.Circle({
                                            x: asset.width() * 0.075,
                                            y: asset.height() * 0.075,
                                            radius: 1,
                                            fill: 'red',
                                        });
    
                                        canvasGroup.group.add(circle);
                                        asset.setScale({x: 0.025, y: 0.025});
    
                                        //asset.y(asset.y() - (asset.height() * 0.025));
                                    }else if(url.includes('kozepes')){
                                        var circle = new Konva.Circle({
                                            x: asset.width() * 0.075,
                                            y: asset.height() * 0.075,
                                            radius: 1,
                                            fill: 'red',
                                        });
    
                                        canvasGroup.group.add(circle);
                                        asset.setScale({x: 0.05, y: 0.05});
                                        //asset.y(asset.y() + (asset.height() * 0.05));
                                    }
                                    var circle = new Konva.Circle({
                                        x: asset.width() * 0.075,
                                        y: asset.height() * 0.075,
                                        radius: 1,
                                        fill: 'red',
                                    });
    
                                    canvasGroup.group.add(circle);
                                }else if(da.attrs.name === 'eyelashes-2' && !url.includes('nagy')) {
                                    asset.offsetX(0);
                                    asset.offsetY(0);
                                    if(url.includes('kicsi')) {
                                        asset.setScale({x: -0.025, y: 0.025});
                                    }else if(url.includes('kozepes')){
                                        asset.setScale({x: -0.05, y: 0.05});
                                    }
                                }*/
                            }
                            resolve(asset);
                        };
                    }
                    else {
                        if (!url.includes('transparent'))
                            return;
                        Konva.Image.fromURL(url, (image) => {
                            image.setAttrs({
                                x: da.attrs.x,
                                y: da.attrs.y,
                                scaleX: da.attrs.scaleX,
                                scaleY: da.attrs.scaleY,
                                name: da.attrs.name,
                                rotation: 0,
                                zIndex: null,
                                src: url
                            });
                            canvasGroup.group.add(image);
                            if (da.attrs.rotate !== undefined && da.attrs.rotate !== null) {
                                image.rotation(da.attrs.rotate);
                            }
                            if (da.attrs.name === 'hairbg') {
                                image.moveToBottom();
                            }
                            resolve(image);
                        });
                    }
                }));
            });
        };
        this.canvasAddAssetToGroup = (group, assetGroup, url) => {
            var _b, _c;
            let iteration = 1;
            let defaultAttribute = [];
            let defAttr = this.defaultAssetAttributes.find(x => x.assetGroup === assetGroup);
            defaultAttribute.push(defAttr);
            let exist = ((_b = this.canvasAssets) === null || _b === void 0 ? void 0 : _b.find(as => as.assetGroup === assetGroup)) ? true : false;
            if (assetGroup === Group.SHOES || assetGroup === Group.ARMS || assetGroup === Group.HANDS) {
                iteration = 2;
                let attr_1 = this.defaultAssetAttributes.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-1`);
                let attr_2 = this.defaultAssetAttributes.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-2`);
                if (attr_1 && attr_2) {
                    defaultAttribute = [];
                    defaultAttribute.push(attr_1);
                    defaultAttribute.push(attr_2);
                    exist = ((_c = this.canvasAssets) === null || _c === void 0 ? void 0 : _c.filter(as => as.assetGroup === assetGroup && as.attrs.name.includes(assetGroup)).length) === 2;
                }
            }
            let canvasGroup = this.canvasGroups.find(x => x.group === group) || null;
            for (let i = 0; i < iteration; i++) {
                Konva.Image.fromURL(url, (image) => {
                    var _b;
                    let attributes = (_b = defaultAttribute[i]) === null || _b === void 0 ? void 0 : _b.attrs;
                    image.setAttrs(Object.assign(Object.assign({}, attributes), { rotate: null, rotation: null, src: url }));
                    if (canvasGroup && attributes) {
                        group.add(image);
                        if (attributes.rotate !== undefined && attributes.rotate !== null) {
                            image.rotation(attributes.rotate);
                        }
                        if (!exist) {
                            this.canvasAssets.push({ canvasGroup,
                                assetGroup,
                                attrs: attributes,
                                image
                            });
                        }
                        else {
                            if (iteration === 2) {
                                let pair = this.canvasAssets.filter(x => x.assetGroup === assetGroup);
                                if (pair.length === 2 && pair[0].attrs.name.includes(assetGroup) && pair[1].attrs.name.includes(assetGroup)) {
                                    let asset = pair.find(x => { var _b; return x.attrs.name === ((_b = defaultAttribute[i]) === null || _b === void 0 ? void 0 : _b.attrs.name); });
                                    if (asset && asset.image) {
                                        asset.image.remove();
                                        asset.image = image;
                                    }
                                }
                            }
                            else {
                                let asset = this.canvasAssets.find(x => x.assetGroup === assetGroup);
                                if (asset && asset.image) {
                                    asset.image.remove();
                                    asset.image = image;
                                }
                            }
                        }
                    }
                });
            }
        };
        //?DONE:
        //TODO: Additional pants assets - done
        //TODO: FEMALE additional asset attaching - done
        //TODO: Body change when creating - done
        //TODO: ARMS SHOULD BE ABOVE THE PANTS - done
        //TODO: zIndex mess needs to be fixed - done
        //TODO: static function to check the canvas object is finished or not - done
        //TODO: zooming when some assets openend - done
        //TODO: allow only one asset group to be opened - done
        //TODO: additional hairs - done
        //TODO: loading harbg jumps to position instead of rendering on the default pos - done
        //TODO: fat positions - doing
        //TODO: get beards based on head shape - done
        //TODO: loaded character not getting empty arms - done
        //TODO: Shoes randmoly not rendering - done
        //TODO: HairBG index - done
        //TODO: Add selected cover to floatingViewer (page?) - done
        //TODO: Cover color select - done
        //! TODO: Not listing beards (admin) - (laravel backend) - done
        //! TODO: If beard equipped and head changed change the beard randomly - done (removing beard)
        //! TODO: After head change if beard selected select another - done (removing beard)
        //! TODO: Heads not loading asset changer (admin) - done
        //! TODO: Add title to cover when editing + remove(empty = no title) - done
        //! TODO: Add button to asset changer to be able to save (admin) -> disable transform (maybe reset group default pos??) - half done
        //! TODO: Add button to asset changer to change zIndex (admin) - done
        //! TODO: Floating viewer opened to fullscreen scale up canvases and arrange them in a column (scroll view) - done
        //! TODO: Update asset positions after save => override default if new pos saved - done
        //! TODO: Fat default characters where almost everything looks good (!!!) - done
        //! TODO: show/hide arms while editing - done
        //! TODO: After deselecting, remove additional assets too - done
        //! TODO: Shoes position setting in admin: two transformer and save button will save the two shoes - done
        //?DO-ABLE:
        //! TODO: Hip position setting in admin: after selecting pants, add another transformer to HIP + button to save HIP -> remove HIP transformer if saved
        //! Cover/Page managing selecting:
        //!     + Make characters movable (transformer)
        //!     + Remove component (asset) (tickbox)
        //!     + Arm positioning (transformer)
        //!     + Select each character leg preset (???) (dropdown): I hope if I change the src image it will look good |no need to readjust|
        //! TODO: Page selecting: same as the cover select, add pagination like in admin
        //! TODO: Instead of default values laod the custom asset positions (data-group on HTML element)
        this.attachAdditionalAsset = (group, src = null, ver = null) => __awaiter(this, void 0, void 0, function* () {
            const self = this;
            if (this.selectedAssets.skinTone) {
                return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                    var _e, _f, _g, _h;
                    try {
                        let attr = this.canvasGetDefaultAttributes(group);
                        let canvasGr = this.getCanvasGroupByAssetGroup(group);
                        if (src === null) {
                            this.getAssets(group).then((res) => __awaiter(this, void 0, void 0, function* () {
                                if (attr !== null)
                                    yield this.canvasAddSVG(window.location.origin + '/' + res.data[0].path, attr, canvasGr);
                            }));
                        }
                        else {
                            let color = ((_e = this.getAssetAttributes(src)) === null || _e === void 0 ? void 0 : _e.color) || (yield this.getColor(src)) || null;
                            if (this.selectedAssets.gender === Gender.MALE) {
                                if (group === Group.ARMS) {
                                    let type = ((_f = this.getAssetAttributes(src)) === null || _f === void 0 ? void 0 : _f.type) || (yield this.getType(src));
                                    if (type !== 'atleta') {
                                        if (type === 'polo') {
                                            this.getArm(color, true).then((res) => {
                                                var _b;
                                                let arm = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.find(x => x.path.includes(type));
                                                if (attr !== null)
                                                    this.canvasAddSVG(window.location.origin + '/' + arm.path, attr, canvasGr);
                                            });
                                        }
                                        else {
                                            this.getArm(color, false).then((res) => {
                                                var _b;
                                                let arm = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.find(x => !x.path.includes(type));
                                                if (attr !== null)
                                                    this.canvasAddSVG(window.location.origin + '/' + arm.path, attr, canvasGr);
                                            });
                                        }
                                    }
                                    else {
                                        this.getAssets(group).then((res) => __awaiter(this, void 0, void 0, function* () {
                                            if (attr !== null)
                                                yield this.canvasAddSVG(window.location.origin + '/' + res.data[0].path, attr, canvasGr);
                                        }));
                                    }
                                }
                                else if (group === Group.HIP) {
                                    this.getPantsTop(color, ver || 1).then((res) => {
                                        let ind = 0;
                                        if (res.length === 2) {
                                            ind = Math.round(Math.random());
                                        }
                                        if (attr !== null)
                                            this.canvasAddSVG(window.location.origin + '/' + res[ind].path, attr, canvasGr);
                                    });
                                }
                            }
                            else {
                                // woman arms
                                console.log('warm');
                                console.log(group);
                                if (group === Group.ARMS) {
                                    let type = (_g = this.getAssetAttributes(src)) === null || _g === void 0 ? void 0 : _g.type;
                                    console.log('asd', this.getAssetAttributes(src));
                                    console.log('arm src', src);
                                    console.log(type);
                                    if (type !== 'hastop' && type !== 'top' && type !== 'allo_ruha' && !src.includes('allo')) {
                                        if (type === 'polo') {
                                            this.getArm(color, true).then((res) => {
                                                var _b;
                                                let arm = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.find(x => x.path.includes(type));
                                                if (attr !== null)
                                                    this.canvasAddSVG(window.location.origin + '/' + arm.path, attr, canvasGr);
                                            });
                                        }
                                        else if (type === 'haspolo') {
                                            this.getArm(color, true).then((res) => {
                                                let arm = res === null || res === void 0 ? void 0 : res.data[1];
                                                if ((res === null || res === void 0 ? void 0 : res.data.length) === 1) {
                                                    arm = res === null || res === void 0 ? void 0 : res.data[0];
                                                }
                                                if (attr !== null)
                                                    this.canvasAddSVG(window.location.origin + '/' + arm.path, attr, canvasGr);
                                            });
                                        }
                                        else {
                                            this.getArm(color, false).then((res) => {
                                                var _b;
                                                let arm = (_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.find(x => !x.path.includes(type));
                                                if (attr !== null)
                                                    this.canvasAddSVG(window.location.origin + '/' + arm.path, attr, canvasGr);
                                            });
                                        }
                                        /*if(type === 'polo') {
                                            this.canvasAddAssetToGroup(self.canvasGroups[0].group, group,  window.location.origin + '/' + data.path);
                                        }*/
                                    }
                                    else {
                                        this.getAssets(group).then((res) => {
                                            if (attr !== null)
                                                this.canvasAddSVG(window.location.origin + '/' + res.data[0].path, attr, canvasGr);
                                        });
                                    }
                                }
                                else if (group === Group.HIP) {
                                    this.getPantsTop(color, ver || 1).then((res) => __awaiter(this, void 0, void 0, function* () {
                                        if (attr !== null) {
                                            attr[0].attrs.zIndex = 2;
                                            attr[0].attrs.forceZIndex = true;
                                            yield this.canvasAddSVG(window.location.origin + '/' + res[0].path, attr, self.canvasGroups[0]);
                                        }
                                    }));
                                }
                                else if (group === Group.HAIRBG) {
                                    let needBg = ((_h = this.getAssetAttributes(src)) === null || _h === void 0 ? void 0 : _h.need_bg) || (yield this.getNeedHairBg(src));
                                    if (needBg) {
                                        this.getHairBg(color).then((res) => __awaiter(this, void 0, void 0, function* () {
                                            if (attr !== null)
                                                yield this.canvasAddSVG(window.location.origin + '/' + res[0].path, attr, canvasGr);
                                        }));
                                    }
                                    else {
                                        if (attr !== null && attr[0] !== null)
                                            this.canvasRemoveSVG(attr[0].attrs.name);
                                    }
                                }
                            }
                        }
                        resolve('additional done');
                    }
                    catch (error) {
                        reject(error);
                    }
                }));
            }
        });
        this.zoomIfNeccessary = (group) => {
            const zoomWhen = [Group.EYES, Group.EYEBROWS, Group.HEADS, Group.MOUTHS, Group.NOSES, Group.HAIRS, Group.BEARDS, Group.EYELASHES, Group.GLASSES];
            if (zoomWhen.includes(group)) {
                this.stage.scale({ x: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? 5 : 2.5, y: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? 5 : 2.5 });
                this.stage.position({
                    x: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? -500 : -250,
                    y: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? 200 : 50
                });
            }
            else {
                this.resetZoom();
            }
        };
        this.resetZoom = () => {
            this.stage.scale({ x: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? 2 : 1, y: __classPrivateFieldGet(this, _Creator_isAdmin, "f") ? 2 : 1 });
            this.stage.position({
                x: 0, y: 0
            });
        };
        this.getAssetDataBySrc = (url) => {
            if (url.includes('transparent'))
                return null;
            return new Promise((resolve) => {
                let reqUrl = `${window.location.origin}/creator/asset/${btoa(url)}`;
                fetch(reqUrl).then((res) => __awaiter(this, void 0, void 0, function* () {
                    let resJson = yield res.json();
                    resolve(resJson[0]);
                }));
            });
        };
        this.getColor = (url) => __awaiter(this, void 0, void 0, function* () {
            if (url.includes('transparent'))
                return null;
            let asset = yield this.getAssetDataBySrc(url);
            return asset === null || asset === void 0 ? void 0 : asset.color;
        });
        this.getType = (url) => __awaiter(this, void 0, void 0, function* () {
            if (url.includes('transparent'))
                return null;
            let asset = yield this.getAssetDataBySrc(url);
            return asset === null || asset === void 0 ? void 0 : asset.type;
        });
        this.getNeedHairBg = (url) => __awaiter(this, void 0, void 0, function* () {
            if (url.includes('transparent'))
                return null;
            let asset = yield this.getAssetDataBySrc(url);
            return asset === null || asset === void 0 ? void 0 : asset.is_required_hair_background;
        });
        //re fetch current page
        this.fetchGroup = (group) => __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                this.createImageLoaders(group, null);
                this.getAssets(group).then(res => {
                    let groupElement = $('#' + group);
                    let needColor = this.needsDefaultColor.find(g => g === group) ? true : false;
                    if (needColor)
                        this.setColor(group, group === Group.EYES ? Color.KEK : Color.ABARNA, false);
                    groupElement.attr('data-init', 'true');
                    this.correctPlaceholdersAmount(group, this.pagination[group].max);
                    this.loadImage(group, this.pagination[group].from, this.pagination[group].to, this.pagination[group].data);
                    resolve('group fetched');
                });
            });
        });
        this.closeAllChooseBox = () => {
            let groups = __classPrivateFieldGet(this, _Creator_choose_box, "f").children('.group');
            groups.each((index, gr) => {
                $(gr).hide();
            });
        };
        this.initializeSwipers = () => __awaiter(this, void 0, void 0, function* () {
            let swipers = $('.swiper');
            let nextBtns = $('.swiper-button-next');
            let prevBtns = $('.swiper-button-prev');
            nextBtns.each((i, btn) => {
                $(btn).on('click', this.onNextButtonClicked);
            });
            prevBtns.each((i, btn) => {
                $(btn).on('click', this.onPrevButtonClicked);
            });
            swipers.each((index, sw) => __awaiter(this, void 0, void 0, function* () {
                let id = $(sw).attr('id') || '';
                if (id !== '') {
                    let paginate = window.outerWidth < 990 ? 3 : 5;
                    let swiper = new Swiper('#' + id, {
                        modules: [Navigation],
                        slidesPerView: paginate,
                        spaceBetween: 5,
                        slidesPerGroup: paginate,
                        allowTouchMove: false,
                        navigation: {
                            nextEl: ".swiper-button-next",
                            prevEl: ".swiper-button-prev",
                        },
                    });
                    this.swipers.push({ id, swiper });
                }
            }));
        });
        this.createImageLoaders = (group, max = null) => {
            let sw = this.swipers.find(x => x.id === group + '-swiper');
            $(`#${group}-swiper > .swiper-wrapper`).empty();
            let paginate = window.outerWidth < 990 ? 3 : 5;
            max = max === null ? paginate : max;
            for (let i = 0; i < (max || this.pagination[group].max); i++) {
                let image = `<img id="${group}-${i}" src="${window.location.origin}/images/loader.gif" width="50px" height="50px" />`;
                $(`#${group}-swiper > .swiper-wrapper`).append(`<div class="swiper-slide">${image}</div>`);
                sw === null || sw === void 0 ? void 0 : sw.swiper.updateSlides();
            }
            this.bindSelection(group);
        };
        this.getDefault = (group) => {
            let returnSrc = "";
            let skin = this.selectedAssets.skinTone === SkinTone.MID_DARK ? 'kozepsotet' : this.selectedAssets.skinTone;
            switch (group) {
                case Group.BODIES:
                    returnSrc = 'bodies/' + (this.selectedAssets.gender === Gender.MALE ? `${this.selectedAssets.bodyFigure === Body.THIN ? 'v' : 'd'}ffitest_${skin}_labakkal.svg` : `${this.selectedAssets.bodyFigure === Body.THIN ? 'v' : 'd'}noitest_${skin}_labakkal.svg`);
                    break;
                case Group.HEADS:
                    returnSrc = 'heads/' + (`fej1_${skin}.svg`);
                    break;
            }
            return window.location.origin + '/images/' + returnSrc;
        };
        this.setGroupDisabled = (group, toggle) => {
            if (toggle) {
                $('#btn-' + group).addClass('disabled');
            }
            else {
                if (!this.forceDisabled.includes(group)) {
                    $('#btn-' + group).removeClass('disabled');
                }
            }
        };
        this.getAssetAttributes = (src) => {
            return $('img[src="' + src + '"]').data();
        };
        this.loadImage = (group, from, to, data) => {
            let images = $(`#${group}-swiper > .swiper-wrapper .swiper-slide`).children();
            const self = this;
            data === null || data === void 0 ? void 0 : data.map((d, i) => {
                let img = $(images[i + from - 1]);
                // Leaving this here cuz idk if I broke it or not
                //if(img.attr('loaded') !== 'true') {
                let tempImg = new Image();
                tempImg.src = window.location.origin + '/' + d.path;
                tempImg.onload = function () {
                    img.attr('src', window.location.origin + '/' + d.path);
                    img.attr('loaded', 'true');
                    img.attr('data-src', window.location.origin + '/' + d.path);
                    if (d.group) {
                        img.attr('data-group', d.group);
                    }
                    if (!__classPrivateFieldGet(self, _Creator_isAdmin, "f") && group === Group.PANTS) {
                        img.attr('data-version', $('img[src="' + window.location.origin + '/' + d.path + '"]').length !== 1 ? '' + 2 : '' + 1);
                    }
                    if (d.color) {
                        img.attr('data-color', d.color);
                    }
                    if (d.type) {
                        img.attr('data-type', d.type);
                    }
                    if (d.is_required_hair_background) {
                        img.attr('data-need_bg', d.is_required_hair_background);
                    }
                    img.addClass('cursor');
                    if (tempImg.src === self.selectedAssets.assets[Creator.getEnumKeyByValue(Group, group)]) {
                        img.addClass('selected');
                    }
                };
                //}
            });
        };
        this.setButtonVisibility = (cashObj, t) => {
            if (t) {
                cashObj.forEach(c => c.removeClass('d-none'));
            }
            else {
                cashObj.forEach(c => c.addClass('d-none'));
            }
        };
        this.onNextButtonClicked = (e) => __awaiter(this, void 0, void 0, function* () {
            var _j;
            let parent = $(e.target.parentElement);
            let id = ((_j = parent.attr('id')) === null || _j === void 0 ? void 0 : _j.split('-')[0]) || '';
            let group = Group[Creator.getEnumKeyByValue(Group, id)];
            this.pagination[group].current++;
            try {
                this.getAssets(group, this.pagination[group].current)
                    .then(() => {
                    this.loadImage(group, this.pagination[group].from, this.pagination[group].to, this.pagination[group].data);
                });
            }
            catch (error) {
                console.error(error);
            }
        });
        this.onPrevButtonClicked = (e) => __awaiter(this, void 0, void 0, function* () {
            var _k;
            let parent = $(e.target.parentElement);
            let id = ((_k = parent.attr('id')) === null || _k === void 0 ? void 0 : _k.split('-')[0]) || '';
            let group = Group[Creator.getEnumKeyByValue(Group, id)];
            if (this.pagination[group].current > 1) {
                this.pagination[group].current--;
                try {
                    let result = yield this.getAssets(group, this.pagination[group].current);
                    this.loadImage(group, result.from, result.to, result.data);
                }
                catch (error) {
                    console.error(error);
                }
            }
        });
        this.getArm = (color, isShort) => __awaiter(this, void 0, void 0, function* () {
            let skincolor = '';
            switch (this.selectedAssets.skinTone) {
                case SkinTone.LIGHT:
                    skincolor = 'vilagos';
                    break;
                case SkinTone.MID_DARK:
                    skincolor = 'kozep_sotet';
                    break;
                case SkinTone.DARK:
                    skincolor = 'sotet';
                    break;
            }
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                let url = `${window.location.origin}/creator/arm/${this.selectedAssets.gender === 0 ? 'ferfi' : 'no'}/${isShort ? skincolor : SkinTone.LIGHT}/allo/${color}`;
                fetch(url).then((res) => __awaiter(this, void 0, void 0, function* () {
                    let resJson = yield res.json();
                    resolve(resJson);
                }));
            }));
        });
        this.getPantsTop = (color, version) => __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                let url = `${window.location.origin}/creator/pants_top/${this.selectedAssets.gender === 0 ? 'ferfi' : 'no'}/${this.selectedAssets.bodyFigure === 0 ? 'thin' : 'fat'}/${color}/${version}`;
                fetch(url).then((res) => __awaiter(this, void 0, void 0, function* () {
                    let resJson = yield res.json();
                    resolve(resJson);
                }));
            }));
        });
        this.getHairBg = (color) => __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                let url = `${window.location.origin}/creator/hair_backgroud/${color}`;
                fetch(url).then((res) => __awaiter(this, void 0, void 0, function* () {
                    let resJson = yield res.json();
                    resolve(resJson);
                }));
            }));
        });
        this.getAssets = (group, page = null) => __awaiter(this, void 0, void 0, function* () {
            const self = this;
            return new Promise((resolve, reject) => {
                var _b, _c, _d, _e;
                let color = (_b = this.selectedAssets.colors.find(g => g.groupName === group)) === null || _b === void 0 ? void 0 : _b.color;
                let fetchPage = page ? page : this.pagination[group] ? this.pagination[group].current : 1;
                let headType = parseInt((_e = (_d = (_c = this.selectedAssets.assets.HEADS) === null || _c === void 0 ? void 0 : _c.split('fej')) === null || _d === void 0 ? void 0 : _d[1]) === null || _e === void 0 ? void 0 : _e[0]) || 1;
                let requestUrl;
                let paginate = window.outerWidth < 990 ? 3 : 5;
                if (__classPrivateFieldGet(this, _Creator_isAdmin, "f") && (group !== Group.ARMS && group !== Group.HANDS && group !== Group.HAIRBG && group !== Group.HEADS && group !== Group.BEARDS)) {
                    requestUrl = `${window.location.origin}/creator/assets/group/${group}/${this.selectedAssets.gender === 0 ? 'ferfi' : 'no'}/${this.selectedAssets.bodyFigure === 0 ? 'thin' : 'fat'}/${headType}?paginate=${paginate}&page=${fetchPage}`;
                }
                else {
                    requestUrl = `${window.location.origin}/creator/assets/${group}/${this.selectedAssets.gender === 0 ? 'ferfi' : 'no'}/${color}/${this.selectedAssets.skinTone === 'kozepsotet' ? 'kozep_sotet' : this.selectedAssets.skinTone}/${this.selectedAssets.bodyFigure === 0 ? 'thin' : 'fat'}/${headType}?paginate=${paginate}&page=${fetchPage}`;
                }
                fetch(requestUrl).then((res) => __awaiter(this, void 0, void 0, function* () {
                    let resJson = yield res.json();
                    //head + beard kotrás
                    if (group === Group.BEARDS) {
                        resJson.data.map((d) => {
                            d.group = (resJson.current_page * 100) + (headType * 10) + d.group;
                        });
                    }
                    if (__classPrivateFieldGet(this, _Creator_isAdmin, "f") && (group !== Group.ARMS && group !== Group.HANDS && group !== Group.HAIRBG && group !== Group.HEADS && group !== Group.BEARDS)) {
                        self.setPaginationData(group, resJson);
                    }
                    else {
                        let { path, total, data, from, to } = resJson;
                        let pagination = { current: fetchPage, url: path, max: total, from, to, data };
                        self.setPagination(group, pagination);
                    }
                    self.setGroupDisabled(group, false);
                    resolve(resJson);
                })).catch(err => reject(err));
            });
        });
        this.setPagination = (group, data) => {
            this.pagination[group] = Object.assign({}, data);
        };
        this.setPaginationData = (group, data) => {
            const keys = Object.keys(data.data);
            const levelup = keys.map(k => data.data[k][0]);
            this.pagination[group] = {
                data: levelup,
                from: data.from,
                max: data.total,
                current: data.current
            };
        };
        this.correctPlaceholdersAmount = (group, total) => {
            let sw = this.swipers.find(x => x.id === group + '-swiper');
            let slides = $(`#${group}-swiper > .swiper-wrapper`).children();
            if (total < slides.length) {
                slides.splice(total, 75 - total);
                $(`#${group}-swiper > .swiper-wrapper`).empty();
                $(`#${group}-swiper > .swiper-wrapper`).append(slides);
                sw === null || sw === void 0 ? void 0 : sw.swiper.updateSlides();
            }
            else {
                this.createImageLoaders(group, total);
            }
        };
        this.toggleTransformByGroup = (toggle, group) => {
            const self = this;
            const transformable = [Group.BEARDS, Group.DRESSES, Group.EYEBROWS, Group.EYES, Group.HAIRS, Group.MOUTHS, Group.NOSES, Group.PANTS, Group.SHOES, Group.HIP, Group.GLASSES, Group.EYELASHES];
            if (this.transformState.enabled === toggle && this.transformState.group === group)
                return console.info('transform is the same');
            if (!transformable.includes(group))
                return console.error('transform is not allowed on ' + group);
            if (this.transformState.enabled && this.transformState.group !== group && this.transformState.group !== null) {
                this.toggleTransformByGroup(false, this.transformState.group);
                //turn off transfrom if targeted another group
            }
            this.transformState.group = toggle ? group : null;
            this.transformState.enabled = toggle;
            //get custom asset if exist
            const attributes = this.canvasGetDefaultAttributes(group);
            const transformableAssets = [];
            if (attributes !== undefined && attributes !== null) {
                if (attributes && (attributes === null || attributes === void 0 ? void 0 : attributes.length) > 1) {
                    attributes.map(attr => {
                        const a = this.stage.find(`.${attr.attrs.name}`);
                        transformableAssets.push(a);
                        if (group === Group.SHOES)
                            this.toggleScaleRange(true, attr.attrs.scaleY);
                    });
                }
                else {
                    transformableAssets.push(this.stage.find(`.${attributes[0].attrs.name}`));
                    this.toggleScaleRange(false, 0);
                }
            }
            this.enableEyelashes(true);
            /*
            if(group === Group.EYES || group === Group.EYELASHES) {
                this.enableEyelashes(true);
            }else{
                this.enableEyelashes(false);
            }
            */
            if (toggle && transformable.includes(group)) {
                this.disableEditorButtons(false);
                this.transformState.data = {
                    x: 0,
                    y: 0,
                    scaleX: 0,
                    scaleY: 0,
                    name: '',
                };
                transformableAssets.map(asset => {
                    asset[0].setAttr('draggable', true);
                    asset[0].moveToTop();
                    const tr = new Konva.Transformer({
                        nodes: [asset[0]],
                        name: asset[0].attrs.name + '-tr',
                        enabledAnchors: group === Group.SHOES || group === Group.EYELASHES ? [] : ['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'],
                        boundBoxFunc: function (oldBox, newBox) {
                            newBox.width = Math.max(30, newBox.width);
                            return newBox;
                        },
                        rotateEnabled: group !== Group.EYELASHES
                    });
                    // NOTE: eyelash bug could be here?
                    this.stage.children[0].add(tr);
                    if (this.transformState.data !== null) {
                        this.transformState.data.x = asset[0].x();
                        this.transformState.data.y = asset[0].y();
                        this.transformState.data.scaleX = asset[0].scaleX();
                        this.transformState.data.scaleY = asset[0].scaleY();
                        this.transformState.data.rotation = asset[0].rotation();
                        this.transformState.data.zIndex = asset[0].zIndex();
                        this.transformState.data.name = asset[0].name();
                    }
                    self.updateTransformerText("Asset moved to top! After position make sure to move back to its default zIndex!", "");
                    asset[0].on('transformstart', () => {
                        asset[0].moveToTop();
                    });
                    asset[0].on('dragmove', () => {
                        if (this.transformState.data !== null) {
                            this.transformState.data.x = asset[0].x();
                            this.transformState.data.y = asset[0].y();
                            this.transformState.data.scaleX = asset[0].scaleX();
                            this.transformState.data.scaleY = asset[0].scaleY();
                            this.transformState.data.rotation = asset[0].rotation();
                            this.transformState.data.zIndex = asset[0].zIndex();
                            self.updateTransformerText();
                        }
                    });
                    asset[0].on('transform', () => {
                        if (this.transformState.data !== null) {
                            this.transformState.data.scaleX = asset[0].scaleX();
                            this.transformState.data.scaleY = asset[0].scaleY();
                            this.transformState.data.rotation = asset[0].rotation();
                            this.transformState.data.zIndex = asset[0].zIndex();
                            self.updateTransformerText();
                        }
                    });
                    asset[0].on('click', () => {
                        if (this.transformState.data !== null) {
                            this.transformState.data.x = asset[0].x();
                            this.transformState.data.y = asset[0].y();
                            this.transformState.data.scaleX = asset[0].scaleX();
                            this.transformState.data.scaleY = asset[0].scaleY();
                            this.transformState.data.rotation = asset[0].rotation();
                            this.transformState.data.zIndex = asset[0].zIndex();
                            this.transformState.data.name = asset[0].name();
                            self.updateTransformerText();
                        }
                    });
                });
            }
            else {
                this.disableEditorButtons(true);
                this.updateTransformerText("", "");
                this.toggleScaleRange(false, 0);
                this.transformState.data = null;
                transformableAssets.map(asset => {
                    asset[0].setAttr('draggable', false);
                    asset[0].off('click');
                    const transformers = this.stage.find(`.${asset[0].attrs.name + '-tr'}`);
                    transformers.map(tr => {
                        tr.destroy();
                    });
                });
            }
        };
        this.toggleScaleRange = (toggle, initialScale) => {
            if (toggle) {
                $('#scale-range').removeClass('d-none');
                $('#scale-range').addClass('d-flex');
                $('#scale-value').html('' + initialScale);
            }
            else {
                $('#scale-range').addClass('d-none');
                $('#scale-range').removeClass('d-flex');
            }
        };
        this.enableEyelashes = (enable) => {
            if (enable) {
                $('#toggle-eyelashes').removeClass('d-none');
            }
            else {
                $('#toggle-eyelashes').addClass('d-none');
            }
        };
        this.disableEditorButtons = (b) => {
            if (b) {
                $('#save-asset').addClass('disabled');
                $('#save-asset').attr('disabled', 'true');
                $('#z-index-down').addClass('disabled');
                $('#z-index-down').attr('disabled', 'true');
                $('#z-index-up').addClass('disabled');
                $('#z-index-up').attr('disabled', 'true');
            }
            else {
                $('#save-asset').removeClass('disabled');
                $('#save-asset').removeAttr('disabled');
                $('#z-index-down').removeClass('disabled');
                $('#z-index-down').removeAttr('disabled');
                $('#z-index-up').removeClass('disabled');
                $('#z-index-up').removeAttr('disabled');
            }
        };
        this.updateTransformerText = (warning = null, success = null) => {
            const posX = $('.pos-x');
            const posY = $('.pos-y');
            const scaleX = $('.scale-x');
            const scaleY = $('.scale-y');
            const zIndex = $('.zind');
            const rotation = $('.rot');
            const warn = $('.warning-msg');
            const scc = $('.success-msg');
            const selected = $('.selected-name');
            if (this.transformState.data !== null) {
                posX.html(this.transformState.data.x !== null && this.transformState.data.x !== undefined ? "" + this.transformState.data.x : posX.html());
                posY.html(this.transformState.data.y !== null && this.transformState.data.y !== undefined ? "" + this.transformState.data.y : posY.html());
                scaleX.html(this.transformState.data.scaleX !== null && this.transformState.data.scaleX !== undefined ? "" + this.transformState.data.scaleX : scaleX.html());
                scaleY.html(this.transformState.data.scaleY !== null && this.transformState.data.scaleY !== undefined ? "" + this.transformState.data.scaleY : scaleY.html());
                zIndex.html(this.transformState.data.zIndex !== null && this.transformState.data.zIndex !== undefined ? "" + this.transformState.data.zIndex : zIndex.html());
                rotation.html(this.transformState.data.rotation !== null && this.transformState.data.rotation !== undefined ? "" + this.transformState.data.rotation : rotation.html());
                selected.html(this.transformState.data.name !== null && this.transformState.data.name !== undefined ? this.transformState.data.name : selected.html());
            }
            if (warning !== null) {
                warn.html(warning);
            }
            if (success !== null) {
                scc.html(success);
            }
        };
        this.getDefaultBodySrc = () => {
            return $(`img[data-gender="${this.selectedAssets.gender === 0 ? 'ferfi' : 'no'}"][data-figure="${this.selectedAssets.bodyFigure === 0 ? 'thin' : 'fat'}"][data-color="${this.selectedAssets.skinTone}"]`).attr('data-src') || '';
        };
        this.bindSelection = (groupName) => {
            let selectedGroup = $('#' + groupName);
            let colorContainer = selectedGroup === null || selectedGroup === void 0 ? void 0 : selectedGroup.find('#colors');
            let group = Creator.getEnumKeyByValue(Group, groupName);
            if (colorContainer) {
                colorContainer.children().each((i, color) => {
                    let colorDiv = $(color);
                    colorDiv.on('click', (e) => {
                        if (Group[group] === Group.SKIN && !colorDiv.hasClass('disable')) {
                            let toneKey = Creator.getEnumKeyByValue(SkinTone, colorDiv.attr('data-color') || '');
                            let tone = SkinTone[toneKey];
                            this.setSkinColorNotSelectable(true);
                            this.setSkinTone(tone !== null && tone !== void 0 ? tone : 'kozepsotet').then(res => { }).catch(err => { }).finally(() => {
                                this.setSkinColorNotSelectable(false);
                            });
                        }
                        else {
                            let color = Color[Creator.getEnumKeyByValue(Color, colorDiv.attr('data-color') || '')];
                            this.setColor(Group[group], color);
                        }
                    });
                });
            }
            selectedGroup.find(`#${Group[group]}-swiper > .swiper-wrapper`).children().each((i, slide) => {
                let asset = $(slide).find('img');
                asset.on('click', (e) => {
                    var _b;
                    let src = asset.attr('src') || '';
                    this.selectAsset(Group[group], src, undefined, true, e);
                    if (Group[group] === Group.BODIES) {
                        let body = ((_b = asset === null || asset === void 0 ? void 0 : asset.attr('src')) === null || _b === void 0 ? void 0 : _b.includes('bodies/d')) ? Body.FAT : Body.THIN;
                        this.setBodyFigure(body);
                    }
                });
            });
        };
        this.setColor = (group, color, needAssetFetch = true) => __awaiter(this, void 0, void 0, function* () {
            var _l, _m;
            let colors = (_l = this.selectedAssets) === null || _l === void 0 ? void 0 : _l.colors;
            let existing = colors === null || colors === void 0 ? void 0 : colors.filter((cl) => cl.groupName === group);
            if (colors !== undefined) {
                let tempPrevColor = null;
                if (existing.length > 0) {
                    if (existing[0].color !== color) {
                        for (const cl of colors) {
                            if (cl.groupName === group) {
                                tempPrevColor = cl.color;
                                cl.color = color;
                                break;
                            }
                        }
                    }
                }
                else {
                    (_m = this.selectedAssets) === null || _m === void 0 ? void 0 : _m.colors.push({ groupName: group, color });
                }
                if (existing.length === 0 || (tempPrevColor !== null && tempPrevColor !== color)) {
                    this.createImageLoaders(group, this.pagination[group].max);
                    this.selectColor(group, color);
                    if (needAssetFetch) {
                        try {
                            let data = yield this.getAssets(group, this.pagination[group].current);
                            let paginate = window.outerWidth < 990 ? 3 : 5;
                            if (data.data.length < paginate) {
                                yield this.getAssets(group, this.pagination[group].current - 1);
                                this.loadImage(group, data.from, data.to, data.data);
                            }
                            this.loadImage(group, this.pagination[group].from, this.pagination[group].to, this.pagination[group].data);
                        }
                        catch (error) {
                            console.error(error);
                        }
                    }
                }
                //forceUpdate && this.updateGroup(group);
            }
            else {
                console.error('Colors object not exist on selectedAssets');
            }
        });
        this.getGrouppedAssetGroups = () => {
            return [[Group.BODIES, Group.HAIRBG, this.selectedAssets.gender === Gender.FEMALE ? Group.HIP : undefined, Group.DRESSES, Group.ARMS, Group.HANDS], [Group.PANTS, this.selectedAssets.gender === Gender.MALE ? Group.HIP : undefined], [Group.HEADS, Group.EYES, Group.EYEBROWS, Group.EYELASHES, Group.HAIRS, Group.BEARDS, Group.MOUTHS, Group.NOSES, Group.GLASSES], [Group.SHOES]];
        };
        this.getCanvasGroupByAssetGroup = (group) => {
            let groups = this.getGrouppedAssetGroups();
            let groupIndex = -1;
            groups.every((gr) => {
                groupIndex++;
                let found = gr.findIndex(x => x === group);
                if (found > -1) {
                    return false;
                }
                return true;
            });
            return this.canvasGroups[groupIndex];
        };
        this.selectColor = (group, color) => {
            let element = $('#' + group).find('#colors');
            if (element) {
                element.children().each((i, div) => {
                    let colorDiv = $(div);
                    if (colorDiv.attr('data-color') === color) {
                        colorDiv.addClass('selected');
                        //i have no idea why does this work but it does
                        if (group === Group.SKIN) {
                            return;
                        }
                        this.getAssets(group, this.pagination[group].current)
                            .then(() => {
                            this.loadImage(group, this.pagination[group].from, this.pagination[group].to, this.pagination[group].data);
                        });
                    }
                    else
                        colorDiv.removeClass('selected');
                });
            }
            else
                console.error('No color picker in this group.');
        };
        this.setSkinTone = (tone) => __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                let oldS = (this.selectedAssets.skinTone === SkinTone.MID_DARK ? 'kozepsotet' : this.selectedAssets.skinTone) || '';
                let newS = tone === SkinTone.MID_DARK ? 'kozepsotet' : tone;
                if (oldS !== newS) {
                    const headURL = this.selectedAssets.assets['HEADS'].replace(oldS, newS);
                    const bodyURL = this.selectedAssets.assets['BODIES'].replace(oldS, newS);
                    this.selectedAssets.skinTone = tone;
                    this.selectColor(Group.SKIN, tone);
                    yield this.fetchGroup(Group.HEADS);
                    yield this.selectAsset(Group.BODIES, bodyURL, undefined, false);
                    yield this.selectAsset(Group.HEADS, headURL, undefined, false);
                    yield this.attachAdditionalAsset(Group.ARMS, this.selectedAssets.assets.DRESSES || null);
                    yield this.attachAdditionalAsset(Group.HANDS);
                    resolve('skin changed');
                }
                else
                    resolve('Same color selected!');
            }));
        });
        this.setSkinColorNotSelectable = (t) => {
            const divs = $('#skins > #colors > div');
            if (t)
                divs.addClass('disable');
            else
                divs.removeClass('disable');
        };
        this.setGender = (gender) => {
            this.selectedAssets.gender = gender;
            //this.updateAllGroup();
            // this.selectAsset(Group.HAIRS, '');
            //this.selectAsset(Group.BEARDS, '');
            //this.selectAsset(Group.DRESSES, '');
            //this.selectAsset(Group.PANTS, '');
            //this.selectAsset(Group.SHOES, '');
            let defaultBodySrc = this.getDefaultBodySrc();
            //this.selectAsset(Group.BODIES, defaultBodySrc);
            if (gender === Gender.FEMALE) {
                this.forceDisabled.push(Group.BEARDS);
                this.setGroupDisabled(Group.BEARDS, true);
            }
        };
        this.setBodyFigure = (figure) => {
            this.selectedAssets.bodyFigure = figure;
            this.fetchGroup(Group.DRESSES);
            this.fetchGroup(Group.PANTS);
        };
        this.setEyelashes = (src) => __awaiter(this, void 0, void 0, function* () {
            const eyes = this.stage.findOne('.eyes');
            let canvasGr = this.getCanvasGroupByAssetGroup(Group.EYELASHES);
            yield this.canvasAddSVG(src, [{
                    canvasGroup: canvasGr,
                    assetGroup: Group.EYELASHES,
                    attrs: {
                        x: eyes.attrs.x,
                        y: eyes.attrs.y,
                        name: 'eyelashes-1',
                        scaleX: src.includes('nagy') ? 0.075 : src.includes('kozepes') ? 0.05 : 0.025,
                        scaleY: src.includes('nagy') ? 0.075 : src.includes('kozepes') ? 0.05 : 0.025,
                    },
                    image: null
                }], canvasGr, undefined);
        });
        this.selectAsset = (category, assetSrc, group = undefined, allowDeselect = true, e = null) => __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                var _o, _p, _q, _r, _s, _t;
                console.log('selectAsset', category, assetSrc, group, allowDeselect, e);
                let groupId = group || ((_o = this.getAssetAttributes(assetSrc)) === null || _o === void 0 ? void 0 : _o.group);
                if (__classPrivateFieldGet(this, _Creator_isAdmin, "f") && category === Group.PANTS && ((_p = this.getAssetAttributes(assetSrc)) === null || _p === void 0 ? void 0 : _p.type) === 'teteje') {
                    category = Group.HIP;
                }
                /*
                if(category === Group.EYELASHES) {
                    const eyes = this.stage.findOne('.eyes');
                    groupId = eyes.attrs.src.includes('transparent') ? -1 : eyes.attrs.groupid;
                }
    
                if(category === Group.EYELASHES && groupId === -1) {
                    resolve('');
                    return;
                }
                */
                let customAttrs = this.canvasGetCustomAttributes(category, groupId);
                let attrs = (customAttrs === null) ? this.canvasGetDefaultAttributes(category) : customAttrs;
                let selectedVersion = (_r = (_q = e === null || e === void 0 ? void 0 : e.target) === null || _q === void 0 ? void 0 : _q.dataset) === null || _r === void 0 ? void 0 : _r.version;
                let currVersion = this.selectedAssets.assets.HIP;
                if (this.selectedAssets.assets[category === null || category === void 0 ? void 0 : category.toUpperCase()] !== assetSrc || (!__classPrivateFieldGet(this, _Creator_isAdmin, "f") && category === Group.PANTS && selectedVersion !== currVersion && this.selectedAssets.gender === Gender.MALE)) {
                    if (__classPrivateFieldGet(this, _Creator_isAdmin, "f")) {
                        this.toggleTransformByGroup(true, category);
                        this.setButtonVisibility([$('#save-extra')], category === Group.SHOES);
                    }
                    let canvasGrp = this.getCanvasGroupByAssetGroup(category);
                    if (category === Group.DRESSES) {
                        this.attachAdditionalAsset(Group.ARMS, assetSrc);
                    }
                    if (category === Group.PANTS && ((_s = this.getAssetAttributes(assetSrc)) === null || _s === void 0 ? void 0 : _s.type) !== 'teteje') {
                        this.attachAdditionalAsset(Group.HIP, assetSrc, selectedVersion);
                    }
                    if (category === Group.HAIRS) {
                        this.attachAdditionalAsset(Group.HAIRBG, assetSrc);
                    }
                    if (category === Group.DRESSES) {
                        if (assetSrc.includes("noiruha")) {
                            this.setGroupDisabled(Group.PANTS, true);
                        }
                        else {
                            this.setGroupDisabled(Group.PANTS, false);
                        }
                    }
                    if (attrs !== null) {
                        if (category === Group.EYELASHES) {
                            const scaleSize = assetSrc.includes('kicsi') ? 0.025 : assetSrc.includes('kozepes') ? 0.05 : 0.075;
                            const old = attrs;
                            attrs = this.scaleDownEyelashesDefault(attrs, scaleSize);
                        }
                        yield this.canvasAddSVG(assetSrc, attrs, canvasGrp, groupId);
                    }
                    /*if(category === Group.EYELASHES || category === Group.EYES && this.selectedAssets.assets[Group.EYELASHES.toUpperCase()] !== '') {
                        const src = category === Group.EYES ? this.selectedAssets.assets[Group.EYELASHES.toUpperCase()] : assetSrc;
                        this.setEyelashes(src);
                    }*/
                    //this.canvasAddAssetToGroup(canvasGroup, category, assetSrc);
                    this.selectedAssets.assets[category === null || category === void 0 ? void 0 : category.toUpperCase()] = assetSrc;
                    if (__classPrivateFieldGet(this, _Creator_isAdmin, "f") && category === Group.HIP && ((_t = this.getAssetAttributes(assetSrc)) === null || _t === void 0 ? void 0 : _t.type) === 'teteje') {
                        category = Group.PANTS;
                    }
                    let elements = $(`#${category}-swiper > .swiper-wrapper .swiper-slide`).children();
                    elements.each((i, e) => {
                        let el = $(e);
                        if (el.attr('data-src') === assetSrc && category !== Group.PANTS || el.attr('data-src') === assetSrc && parseInt(selectedVersion) === parseInt(el.attr('data-version') || '-1')) {
                            el.addClass('selected');
                        }
                        else {
                            el.removeClass('selected');
                        }
                    });
                }
                else {
                    if (allowDeselect && attrs !== null) {
                        attrs.forEach(attr => {
                            this.canvasRemoveSVG(attr.attrs.name);
                        });
                    }
                }
                resolve('asset selected');
            }));
        });
        this.scaleDownEyelashesDefault = (attrs, size) => {
            if (attrs !== null) {
                return attrs.map((x, i) => {
                    return Object.assign(Object.assign({}, x), { attrs: Object.assign(Object.assign({}, x.attrs), { scaleX: (i === 1 ? -size : size), scaleY: size }) });
                });
            }
            else
                return [];
        };
        this.removeBeard = () => {
            const beardSelected = this.selectedAssets.assets.BEARDS;
            if (beardSelected === "" || beardSelected === null || beardSelected === undefined || beardSelected.includes('transparent'))
                return;
            this.canvasRemoveSVG('beard');
        };
        this.filterItem = (e, group, rule) => {
            let show = true;
            const color = $(e).attr('data-color') || '';
            const gender = $(e).attr('data-gender') === 'ferfi' ? Gender.MALE : Gender.FEMALE;
            const body = $(e).attr('data-figure') === 'fat' ? Body.FAT : Body.THIN;
            if (rule.includes('color')) {
                let selectedColor = this.selectedAssets.colors.filter(cl => cl.groupName === group)[0];
                if (color !== selectedColor.color) {
                    show = false;
                }
            }
            if (rule.includes('skin')) {
                let skinTone = this.selectedAssets.skinTone || SkinTone.LIGHT;
                if (color !== skinTone) {
                    show = false;
                }
            }
            if (rule.includes('gender')) {
                let selectedGender = this.selectedAssets.gender || Gender.MALE;
                if (gender !== selectedGender) {
                    show = false;
                }
            }
            if (rule.includes('body')) {
                let selectedBody = this.selectedAssets.bodyFigure || Body.THIN;
                if (body !== selectedBody) {
                    show = false;
                }
            }
            return show;
        };
        this.updateAllGroup = () => {
            let keys = Object.keys(Group);
            keys.forEach((key) => {
                //this.updateGroup(Group[key], false);
            });
        };
        this.updateGroup = (group, loadImages = true) => {
            const COLOR_FILTER = [Group.HAIRS, Group.EYES, Group.EYEBROWS, Group.BEARDS];
            const SKIN_FILTER = [Group.HEADS, Group.BODIES];
            const GENDER_FILTER = [Group.BODIES, Group.HAIRS, Group.BEARDS, Group.DRESSES, Group.PANTS, Group.SHOES];
            const BODY_FIGURE = [Group.DRESSES, Group.PANTS, Group.SHOES];
            let id = `#${group}`;
            let filter_rules = [];
            COLOR_FILTER.map(g => {
                if (g === group) {
                    filter_rules.push('color');
                }
            });
            GENDER_FILTER.map(g => {
                if (g === group) {
                    filter_rules.push('gender');
                }
            });
            SKIN_FILTER.map(g => {
                if (g === group) {
                    filter_rules.push('skin');
                }
            });
            BODY_FIGURE.map(g => {
                if (g === group) {
                    filter_rules.push('body');
                }
            });
            $(id).children().each((i, e) => {
                let show = this.filterItem(e, group, filter_rules);
                if (show) {
                    $(e).removeClass('hidden');
                }
                else {
                    $(e).addClass('hidden');
                }
                if (loadImages) {
                    let img = $(e);
                    if (img.attr('loaded') !== 'true') {
                        var newimg = new Image();
                        newimg.src = img.attr('data-src') || '';
                        newimg.onload = function () {
                            img.attr('src', newimg.src);
                            img.attr('loaded', 'true');
                        };
                    }
                }
            });
        };
        __classPrivateFieldSet(this, _Creator_isAdmin, isAdmin, "f");
        __classPrivateFieldSet(this, _Creator_choose_box, $(`#${choose_box_id}`), "f");
        __classPrivateFieldSet(this, _Creator_canvas, document.getElementById(canvas_id), "f");
        __classPrivateFieldSet(this, _Creator_data, data, "f");
        this.pagination = {};
        this.canvasAssets = [];
        this.canvasGroups = [];
        this.stage = null;
        this.defaultAssetAttributes = [];
        this.customAssetAttributes = [];
        this.needsDefaultColor = [Group.HAIRS, Group.EYEBROWS, Group.BEARDS, Group.EYES];
        this.selectedAssets = {
            gender: null,
            skinTone: null,
            bodyFigure: null,
            colors: [],
            //Contains selected assets, identified with its URL, hence its unique
            assets: {
                HEADS: '',
                HAIRS: '',
                NOSES: '',
                EYES: '',
                MOUTHS: '',
                EYEBROWS: '',
                BODIES: '',
                DRESSES: '',
                BEARDS: '',
                PANTS: '',
                SHOES: '',
                HIP: 0,
                EYELASHES: '',
                GLASSES: ''
            }
        };
        this.swipers = [];
        this.transformState = {
            enabled: false,
            group: null,
            data: null,
            arms: true
        };
        this.forceDisabled = [];
    }
}
_a = Creator, _Creator_canvas = new WeakMap(), _Creator_choose_box = new WeakMap(), _Creator_isAdmin = new WeakMap(), _Creator_data = new WeakMap();
//group to canvas id
Creator.canvasIdMap = {
    "head": "heads",
    "hair": "hairs",
    "nose": "noses",
    "eyes": "eyes",
    "mouth": "mouths",
    "eyebrows": "eyebrows",
    "body": "bodies",
    "beard": "beards",
    "shoes-1": "shoes",
    "shoes-2": "shoes",
    "arms-1": "arms",
    "arms-2": "arms",
    "bottom": "pants",
    "top": "dresses",
    "hands-1": "hands",
    "hands-2": "hands",
    "hip": "hip",
    "hairbg": "hairbg",
    "glasses": "glasses",
    "eyelashes-1": "eyelashes",
    "eyelashes-2": "eyelashes"
};
Creator.getLoadedAssets = (canvasObj, exclude = true) => {
    const excludeGroup = [Group.HIP, Group.ARMS, Group.HANDS];
    let loadedAssets = {};
    if (canvasObj) {
        canvasObj.children[0].children.forEach(grp => {
            grp.children.forEach(asset => {
                let group = Group[Creator.getEnumKeyByValue(Group, Creator.canvasIdMap[asset.attrs.name])];
                if (!exclude || exclude && !excludeGroup.includes(group)) {
                    loadedAssets[group] = { src: asset.attrs.src, group, posGroupId: asset.attrs.groupid };
                }
            });
        });
        return loadedAssets;
    }
    else
        return null;
};
Creator.checkCanvas = (data) => {
    var _b;
    let neccessaryGroups = [Group.BODIES, Group.HEADS, Group.EYES, Group.HANDS, Group.NOSES, Group.SHOES, Group.ARMS, Group.MOUTHS];
    let returnObject = { isValid: false, missingAssets: [] };
    if (data && (data === null || data === void 0 ? void 0 : data.canvas)) {
        returnObject.isValid = true;
        let canvasData = JSON.parse(data === null || data === void 0 ? void 0 : data.canvas);
        let loadedAssets = (_b = Creator.getLoadedAssets(canvasData, false)) !== null && _b !== void 0 ? _b : {};
        neccessaryGroups.map(gr => {
            if (loadedAssets[gr] === undefined || loadedAssets[gr].src.includes('transparent')) {
                returnObject.missingAssets.push(gr);
                returnObject.isValid = false;
            }
        });
        return returnObject;
    }
    else
        return returnObject;
};
//! TODO: DETERMINE SVG SIZE BASED ON CHROME'S CALCULATION
Creator.getModifiedImageFrom = (url) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        fetch(url).then((res) => __awaiter(void 0, void 0, void 0, function* () {
            var _b;
            var parser = new DOMParser();
            let text = yield res.text();
            var result = parser.parseFromString(text, 'text/xml');
            var inlineSVG = result.getElementsByTagName("svg")[0];
            const viewbox = (_b = inlineSVG.getAttribute('viewBox')) === null || _b === void 0 ? void 0 : _b.split(' ');
            const view_1 = Math.round(parseFloat((viewbox === null || viewbox === void 0 ? void 0 : viewbox[2]) || ''));
            const view_2 = Math.round(parseFloat((viewbox === null || viewbox === void 0 ? void 0 : viewbox[3]) || ''));
            const ratio = view_1 / view_2;
            let height = 150;
            let width = height * ratio;
            if (width >= 300) {
                width = 300;
                height = width / ratio;
            }
            inlineSVG.setAttribute('height', '' + height + 'px');
            inlineSVG.setAttribute('width', '' + width + 'px');
            var svg64 = btoa(new XMLSerializer().serializeToString(inlineSVG));
            var image64 = 'data:image/svg+xml;base64,' + svg64;
            resolve(image64);
        })).catch(err => reject(err));
    });
});
Creator.getDefaultPositions = (gender, body) => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve, reject) => {
        let requestUrl = `${window.location.origin}/creator/positions/${gender === 0 ? 'ferfi' : 'no'}/${body === 0 ? 'thin' : 'fat'}`;
        fetch(requestUrl).then((res) => __awaiter(void 0, void 0, void 0, function* () {
            let resJson = yield res.json();
            resolve(resJson);
        })).catch(err => reject(err));
    });
});
Creator.getEnumKeyByValue = (enumName, value) => {
    let keys = Object.keys(enumName).filter(x => enumName[x] === value);
    return keys.length > 0 ? keys[0] : null;
};
