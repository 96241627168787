require("./bootstrap");
import Creator from "./creator";
import Canvas from "./canvas";
import Pages from "./pages";
require("./create");
import $ from "cash-dom";
import FloatingViewer from "./floatingViewer";

$(function () {
    $(document).scroll(function () {
        var $nav = $(".fixed-top");
        $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
    });
});

const getFloatingInstace = (callback) => {
    return new FloatingViewer(callback, null, true);
};

const loadCanvases = () => {
    let data_1 = JSON.parse(localStorage.getItem("character_1")) || null;
    let data_2 = JSON.parse(localStorage.getItem("character_2")) || null;
    //determine if there is a finished character
    new Canvas("canvas-1", data_1, "character");
    new Canvas("canvas-2", data_2, "character");

    let finished_1 = Creator.checkCanvas(data_1);
    let finished_2 = Creator.checkCanvas(data_2);
    setState(1, finished_1.isValid);
    setState(2, finished_2.isValid);

    if (finished_1.isValid && finished_2.isValid) {
        document.querySelector("#cover_select").classList.remove("disabled");
        document.querySelector("#cover_select").disabled = false;

        document
            .querySelector("#cover_select")
            .addEventListener("click", () => {
                go("covers");
            });
    }
};

const setState = (canvasId, state) => {
    let check = document.querySelector("#canvas-" + canvasId + "-text .check");
    let cross = document.querySelector("#canvas-" + canvasId + "-text .cross");
    if (state) {
        check.classList.remove("hidden");
        cross.classList.add("hidden");
    } else {
        check.classList.add("hidden");
        cross.classList.remove("hidden");
    }
};

const initializeCreator = () => {
    let character = parseInt(window.location.pathname.split("/")[3]);
    let data = JSON.parse(localStorage.getItem("character_" + character));
    if (data) {
        if (parseInt(data.character) === character && data.gender !== null) {
            if (character === 1 || character === 2) {
                let creator = new Creator("canvas", "choose_box", data);
                creator.initialize();
            }
        } else {
            goBack();
        }
    } else {
        goBack();
    }
};

const initializeCoverSelection = () => {
    let layer_1 = JSON.parse(localStorage.getItem("character_layer_1")) || null;
    let layer_2 = JSON.parse(localStorage.getItem("character_layer_2")) || null;

    if (layer_1 !== null && layer_2 !== null) {
        new Pages([layer_1, layer_2], true, true, false, "covers");
    } else goBack();
};

const initializePageSelection = () => {
    let layer_1 = JSON.parse(localStorage.getItem("character_layer_1")) || null;
    let layer_2 = JSON.parse(localStorage.getItem("character_layer_2")) || null;
    if (layer_1 !== null && layer_2 !== null) {
        new Pages([layer_1, layer_2], true, true, false);
    } else goBack();
};

const showBookOverview = () => {
    $("#book_overview").append(
        '<div class="w-100 d-flex h-100 align-items-center justify-content-center flex-column w-lg-50"><h4>Könyved előnézete generálás alatt!</h4><br /><div class="spinner-border" role="status"></div><h5>Kis türelmedet kérjük!</h5></div>'
    );
    const fl = getFloatingInstace(() => {
        const images = fl.getPages("big");
        $("#book_overview").empty();
        images.forEach((img) => {
            if (img === null || img === undefined) {
                return;
            }
            $("#book_overview").append(
                '<img class="w-100 mb-2" src="' + img + '" />'
            );
        });
    });

    const status = window.location.search.split("status=")[1];
    if (status === "Canceled") {
        $("#error").removeClass("d-none");
        $("#error").html("Fizetés megszakítva!");
        window.history.replaceState(null, "", "/szeretlek-konyv/overview");
    }
};

const goBack = () =>
    window.location.replace(
        origin + "/" + window.location.pathname.split("/")[1]
    );

const go = (route) => {
    const needSlash =
        window.location.pathname[window.location.pathname.length - 1] !== "/";
    let redirectURL = `${window.location.pathname}${
        needSlash ? "/" : ""
    }${route}`;
    window.location.href = redirectURL;
};

const goHome = () => {
    window.location.replace(origin + "/" + "szeretlek-konyv");
};

const handlePaymentLikeRoutes = (route) => {
    if (route[1] === "fizetes") {
        getFloatingInstace((valid) => {
            if (!valid) goHome();
        });
    }
};

const checkBrowser = () => {
    let safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (safari) return false;

    return !!window.chrome;
};

window.onload = function () {
    let route = window.location.pathname.split("/");
    handlePaymentLikeRoutes(route);

    const valid = checkBrowser();
    if (valid || route[1] !== "szeretlek-konyv") {
        document.getElementById("ch-cover")?.classList?.add("d-none");
        switch (route[2]) {
            case "create":
                initializeCreator();
                break;
            case "edit":
                initializeCreator();
                break;
            case "covers":
                initializeCoverSelection();
                break;
            case "pages":
                initializePageSelection();
                break;
            case "overview":
                showBookOverview();
                break;
            default:
                loadCanvases();
                break;
        }
    } else {
        //const m = new bootstrap.Modal('#myModal', {})
        const b = document.getElementById("chromium_modal_btn");
        b.click();
    }
};
