export const presets = {
    male: {
        thin: {
            attrs: {},
            className: "Layer",
            children: [
                {
                    attrs: { x: 120, y: 40 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "body",
                                scaleX: 2,
                                scaleY: 2,
                                src: "https://szeretlekkonyv.hu/images/bodies/vffitest_vilagos_labakkal.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "top",
                                x: 15,
                                y: 51,
                                scaleX: 0.7,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/dresses/vffiatleta_sszurke.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "arms-2",
                                x: 81,
                                y: 59,
                                scaleX: 0.7,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/arms/ffikar_vilagos_egyenes_ruhanelkuli.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "arms-1",
                                x: -5,
                                y: 60,
                                scaleX: 0.7,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/arms/ffikar_vilagos_egyenes_ruhanelkuli.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hands-2",
                                x: 103,
                                y: 164,
                                scaleX: 0.12,
                                scaleY: 0.12,
                                src: "https://szeretlekkonyv.hu/images/hands/ffikezfej_vilagos.svg",
                                rotation: 90,
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hands-1",
                                x: 16.5,
                                y: 164,
                                scaleX: 0.12,
                                scaleY: 0.12,
                                src: "https://szeretlekkonyv.hu/images/hands/ffikezfej_vilagos.svg",
                                rotation: 90,
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 50, y: 120 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "bottom",
                                x: 93,
                                y: 93,
                                scaleX: 0.85,
                                scaleY: 0.85,
                                src: "https://szeretlekkonyv.hu/images/pants/vffinadrag_allo_nrncs.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hip",
                                x: 93.4,
                                y: 64,
                                scaleX: 0.23,
                                scaleY: 0.25,
                                src: "https://szeretlekkonyv.hu/images/pants/vffinadrag_teteje_nrncs.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 127.5 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "head",
                                x: 12,
                                y: 12,
                                scaleX: 0.5,
                                scaleY: 0.5,
                                src: "https://szeretlekkonyv.hu/images/heads/fej1_vilagos.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "eyes",
                                x: 18.5,
                                y: 36,
                                scaleX: 0.17,
                                scaleY: 0.17,
                                src: "https://szeretlekkonyv.hu/images/eyes/szemek2_kek.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "eyebrows",
                                x: 20,
                                y: 29,
                                scaleX: 0.16,
                                scaleY: 0.15,
                                src: "https://szeretlekkonyv.hu/images/eyebrows/szemoldok3_abrn.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hair",
                                scaleX: 0.4,
                                scaleY: 0.4,
                                src: "https://szeretlekkonyv.hu/images/hairs/haj3_abarna.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "beard",
                                x: 18,
                                y: 58,
                                scaleX: 0.19,
                                scaleY: 0.19,
                                src: "https://szeretlekkonyv.hu/images/transparent.png",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "mouth",
                                x: 35,
                                y: 65,
                                scaleX: 0.08,
                                scaleY: 0.08,
                                src: "https://szeretlekkonyv.hu/images/mouths/szaj4_nevetesfogak.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "nose",
                                x: 38,
                                y: 46,
                                scaleX: 0.12,
                                scaleY: 0.12,
                                src: "https://szeretlekkonyv.hu/images/noses/orr3.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 50, y: 50 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "shoes-1",
                                x: 118,
                                y: 285,
                                scaleX: -0.19999999999999996,
                                scaleY: 0.2,
                                src: "https://szeretlekkonyv.hu/images/shoes/fficipo3_elol.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "shoes-2",
                                x: 123,
                                y: 285,
                                scaleX: 0.2,
                                scaleY: 0.2,
                                src: "https://szeretlekkonyv.hu/images/shoes/fficipo3_elol.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
            ],
        },
        fat: {
            attrs: {},
            className: "Layer",
            children: [
                {
                    attrs: { x: 120, y: 40 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "body",
                                scaleX: 2,
                                scaleY: 2,
                                src: "https://szeretlekkonyv.hu/images/bodies/dffitest_vilagos_labakkal.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "top",
                                x: 15,
                                y: 53,
                                scaleX: 0.655,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/dresses/dffifelso_fkt.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "arms-2",
                                x: 88,
                                y: 60,
                                scaleX: 0.7,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/arms/ffikar_egyenes_fekete.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "arms-1",
                                x: -8,
                                y: 60,
                                scaleX: 0.7,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/arms/ffikar_egyenes_fekete.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hands-2",
                                x: 110,
                                y: 164,
                                scaleX: 0.125,
                                scaleY: 0.125,
                                src: "https://szeretlekkonyv.hu/images/hands/ffikezfej_vilagos.svg",
                                rotation: 90,
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hands-1",
                                x: 14,
                                y: 164.5,
                                scaleX: 0.125,
                                scaleY: 0.125,
                                src: "https://szeretlekkonyv.hu/images/hands/ffikezfej_vilagos.svg",
                                rotation: 90,
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 50, y: 120 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "bottom",
                                x: 87,
                                y: 96,
                                scaleX: 0.82,
                                scaleY: 0.82,
                                src: "https://szeretlekkonyv.hu/images/pants/dffinadrag_allo_nrncs.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hip",
                                x: 85,
                                y: 69,
                                scaleX: 0.26,
                                scaleY: 0.25,
                                src: "https://szeretlekkonyv.hu/images/pants/dffinadrag_teteje_nrncs.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 127.5 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "head",
                                x: 12,
                                y: 12,
                                scaleX: 0.5,
                                scaleY: 0.5,
                                src: "https://szeretlekkonyv.hu/images/heads/fej1_vilagos.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "eyes",
                                x: 18.5,
                                y: 36,
                                scaleX: 0.17,
                                scaleY: 0.17,
                                src: "https://szeretlekkonyv.hu/images/eyes/szemek3_kek.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "eyebrows",
                                x: 20,
                                y: 29,
                                scaleX: 0.16,
                                scaleY: 0.15,
                                src: "https://szeretlekkonyv.hu/images/transparent.png",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hair",
                                scaleX: 0.4,
                                scaleY: 0.4,
                                src: "https://szeretlekkonyv.hu/images/hairs/haj3_abarna.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "beard",
                                x: 18,
                                y: 58,
                                scaleX: 0.19,
                                scaleY: 0.19,
                                src: "https://szeretlekkonyv.hu/images/transparent.png",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "mouth",
                                x: 35,
                                y: 65,
                                scaleX: 0.08,
                                scaleY: 0.08,
                                src: "https://szeretlekkonyv.hu/images/mouths/szaj1_felmosoly.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "nose",
                                x: 38,
                                y: 46,
                                scaleX: 0.125,
                                scaleY: 0.125,
                                src: "https://szeretlekkonyv.hu/images/noses/orr3.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 50, y: 50 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "shoes-1",
                                x: 118,
                                y: 285,
                                scaleX: -0.19999999999999996,
                                scaleY: 0.2,
                                src: "https://szeretlekkonyv.hu/images/shoes/fficipo3_elol.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "shoes-2",
                                x: 123,
                                y: 285,
                                scaleX: 0.2,
                                scaleY: 0.2,
                                src: "https://szeretlekkonyv.hu/images/shoes/fficipo3_elol.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
            ],
        },
    },
    female: {
        thin: {
            attrs: {},
            className: "Layer",
            children: [
                {
                    attrs: { x: 120, y: 40 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "hairbg",
                                x: 25,
                                y: -10,
                                scaleX: 0.4,
                                scaleY: 0.4,
                                src: "https://szeretlekkonyv.hu/images/hair_backgrounds/hajhatter_abrn.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "body",
                                scaleX: 2,
                                scaleY: 2,
                                src: "https://szeretlekkonyv.hu/images/bodies/vnoitest_vilagos_labakkal.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hip",
                                x: 14,
                                y: 136.5,
                                scaleX: 0.27,
                                scaleY: 0.25,
                                src: "https://szeretlekkonyv.hu/images/pants/vnoinadrag_teteje_fkt.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "top",
                                x: 10,
                                y: 54,
                                scaleX: 0.75,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/dresses/vnoifelso_fhr.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "arms-2",
                                x: 67,
                                y: 61,
                                scaleX: 0.7,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/arms/noikar_egyenes_feher.svg",
                                rotation: -10,
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "arms-1",
                                x: 15,
                                y: 59,
                                scaleX: 0.7,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/arms/noikar_egyenes_feher.svg",
                                rotation: 15,
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hands-1",
                                x: -8,
                                y: 160,
                                scaleX: 0.12,
                                scaleY: 0.12,
                                src: "https://szeretlekkonyv.hu/images/hands/noikezfej_vilagos.svg",
                                rotation: 10,
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hands-2",
                                x: 89,
                                y: 162,
                                scaleX: 0.12,
                                scaleY: 0.12,
                                src: "https://szeretlekkonyv.hu/images/hands/noikezfej_vilagos.svg",
                                rotation: -15,
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 50, y: 120 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "bottom",
                                x: 84,
                                y: 86,
                                scaleX: 0.93,
                                scaleY: 0.93,
                                src: "https://szeretlekkonyv.hu/images/pants/vnoinadrag_allo_fkt.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 127.5 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "head",
                                x: 9,
                                y: 12,
                                scaleX: 0.5,
                                scaleY: 0.5,
                                src: "https://szeretlekkonyv.hu/images/heads/fej1_vilagos.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "eyes",
                                x: 11,
                                y: 38,
                                scaleX: 0.2,
                                scaleY: 0.2,
                                src: "https://szeretlekkonyv.hu/images/eyes/szemek4_kek.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "eyebrows",
                                x: 17,
                                y: 29,
                                scaleX: 0.16,
                                scaleY: 0.15,
                                src: "https://szeretlekkonyv.hu/images/eyebrows/szemoldok3_abrn.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hair",
                                x: 2,
                                y: 3,
                                scaleX: 0.65,
                                scaleY: 0.65,
                                src: "https://szeretlekkonyv.hu/images/hairs/haj59h_abarna.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "mouth",
                                x: 30,
                                y: 65,
                                scaleX: 0.08,
                                scaleY: 0.08,
                                src: "https://szeretlekkonyv.hu/images/mouths/szaj5_ajkakpiros.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "nose",
                                x: 35,
                                y: 46,
                                scaleX: 0.12,
                                scaleY: 0.12,
                                src: "https://szeretlekkonyv.hu/images/noses/orr3.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 50, y: 50 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "shoes-2",
                                x: 119,
                                y: 285,
                                scaleX: 0.2,
                                scaleY: 0.2,
                                src: "https://szeretlekkonyv.hu/images/shoes/noicipo2_rsz_elol.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "shoes-1",
                                x: 118,
                                y: 285,
                                scaleX: -0.19999999999999996,
                                scaleY: 0.2,
                                src: "https://szeretlekkonyv.hu/images/shoes/noicipo2_rsz_elol.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
            ],
        },
        fat: {
            attrs: {},
            className: "Layer",
            children: [
                {
                    attrs: { x: 120, y: 40 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "hairbg",
                                x: 25,
                                y: -10,
                                scaleX: 0.4,
                                scaleY: 0.4,
                                src: "https://szeretlekkonyv.hu/images/hair_backgrounds/hajhatter_abrn.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "body",
                                scaleX: 2,
                                scaleY: 2,
                                src: "https://szeretlekkonyv.hu/images/bodies/dnoitest_vilagos_labakkal.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hip",
                                x: 7,
                                y: 136.5,
                                scaleX: 0.27,
                                scaleY: 0.25,
                                src: "https://szeretlekkonyv.hu/images/pants/dnoinadrag_teteje_sbrn.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "top",
                                x: 9,
                                y: 53,
                                scaleX: 0.6,
                                scaleY: 0.6,
                                src: "https://szeretlekkonyv.hu/images/dresses/dnoifelso_fhr.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "arms-2",
                                x: 67,
                                y: 61,
                                scaleX: 0.7,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/arms/noikar_egyenes_feher.svg",
                                rotation: -10,
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "arms-1",
                                x: 15,
                                y: 59,
                                scaleX: 0.7,
                                scaleY: 0.7,
                                src: "https://szeretlekkonyv.hu/images/arms/noikar_egyenes_feher.svg",
                                rotation: 15,
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hands-2",
                                x: 89,
                                y: 162,
                                scaleX: 0.125,
                                scaleY: 0.125,
                                src: "https://szeretlekkonyv.hu/images/hands/noikezfej_vilagos.svg",
                                rotation: -15,
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hands-1",
                                x: -8,
                                y: 160,
                                scaleX: 0.125,
                                scaleY: 0.125,
                                src: "https://szeretlekkonyv.hu/images/hands/noikezfej_vilagos.svg",
                                rotation: 10,
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 50, y: 120 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "bottom",
                                x: 78,
                                y: 86,
                                scaleX: 0.9,
                                scaleY: 0.9,
                                src: "https://szeretlekkonyv.hu/images/pants/dnoinadrag_allo_sbrn.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 127.5 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "head",
                                x: 12,
                                y: 12,
                                scaleX: 0.5,
                                scaleY: 0.5,
                                src: "https://szeretlekkonyv.hu/images/heads/fej1_vilagos.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "eyes",
                                x: 18.5,
                                y: 36,
                                scaleX: 0.17,
                                scaleY: 0.17,
                                src: "https://szeretlekkonyv.hu/images/eyes/szemek2_kek.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "eyebrows",
                                x: 17,
                                y: 29,
                                scaleX: 0.16,
                                scaleY: 0.15,
                                src: "https://szeretlekkonyv.hu/images/eyebrows/szemoldok4_abrn.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "hair",
                                x: 2,
                                y: 3,
                                scaleX: 0.65,
                                scaleY: 0.65,
                                src: "https://szeretlekkonyv.hu/images/hairs/haj58h_abarna.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "mouth",
                                x: 30,
                                y: 65,
                                scaleX: 0.08,
                                scaleY: 0.08,
                                src: "https://szeretlekkonyv.hu/images/mouths/szaj5_ajkakpiros.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "nose",
                                x: 35,
                                y: 46,
                                scaleX: 0.125,
                                scaleY: 0.125,
                                src: "https://szeretlekkonyv.hu/images/noses/orr3.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
                {
                    attrs: { x: 50, y: 50 },
                    className: "Group",
                    children: [
                        {
                            attrs: {
                                name: "shoes-1",
                                x: 118,
                                y: 285,
                                scaleX: -0.19999999999999996,
                                scaleY: 0.2,
                                src: "https://szeretlekkonyv.hu/images/shoes/noicipo2_fkt_elol.svg",
                            },
                            className: "Image",
                        },
                        {
                            attrs: {
                                name: "shoes-2",
                                x: 119,
                                y: 285,
                                scaleX: 0.2,
                                scaleY: 0.2,
                                src: "https://szeretlekkonyv.hu/images/shoes/noicipo2_fkt_elol.svg",
                            },
                            className: "Image",
                        },
                    ],
                },
            ],
        },
    },
};
