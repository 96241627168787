var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _Canvas_MAX_CHARACTER_TITLE;
import $ from "cash-dom";
import Konva from "konva";
import Manage from "./admin/manage";
import Creator from "./creator";
export default class Canvas {
    constructor(canvas_id, initialData, type, adminEdit = false, bg = null, loadEndFC = null, pageObject = null, fixZ = false) {
        var _a;
        this.loadable = ['glasses', 'eyelashes-1', 'eyelashes-2', 'dance', 'head', 'body', 'arms-1', 'arms-2', 'hands-1', 'hands-2', 'hair', 'mouth', 'beard', 'eyes', 'shoes-1', 'shoes-2', 'top', 'bottom', 'eyebrows', 'nose', 'hip', 'hairbg', 'bg'];
        _Canvas_MAX_CHARACTER_TITLE.set(this, void 0);
        this.attributes = [];
        this.assetGroups = [];
        this.loadAssets = (layer = 0, name = null, all = false) => __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                const self = this;
                if (this.stage.children[layer] !== undefined) {
                    if (name !== null) {
                        let data = self.stage.children[layer].findOne(`.${name}`);
                        if (data !== undefined) {
                            const img = yield this.loadImage(data.attrs.src);
                            data.image(img);
                        }
                    }
                    else {
                        for (let i = 0; i < this.loadable.length; i++) {
                            if (self.stage !== undefined) {
                                let data = self.stage.children[layer].findOne(`.${this.loadable[i]}`);
                                if (data !== undefined) {
                                    const img = yield this.loadImage(data.attrs.src);
                                    data.image(img);
                                }
                            }
                        }
                        if (all === true && this.stage.children[layer].attrs.name && this.stage.children[layer].attrs.name.includes('extra')) {
                            const extras = this.stage.children[layer].find(node => {
                                return node.attrs.name.includes('extra');
                            });
                            for (let i = 0; i < extras.length; i++) {
                                if (extras[i] !== undefined) {
                                    const img = yield this.loadImage(extras[i].attrs.src);
                                    extras[i].image(img);
                                }
                            }
                        }
                    }
                }
                resolve('assets loaded');
            }));
        });
        this.loadImage = (src) => {
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.onload = () => resolve(img);
                img.onerror = reject;
                img.src = src;
                img.crossOrigin = "Anonymous";
            });
        };
        this.getAssetPosition = (gender, body) => {
            return new Promise((resolve) => {
                Creator.getDefaultPositions(gender, body).then((res) => __awaiter(this, void 0, void 0, function* () {
                    resolve(res);
                }));
            });
        };
        this.getGender = (layer) => {
            const body = layer.findOne('.body');
            return body.attrs.src.includes('noi') ? 1 : 0; // ha van benne noi akkor 1 ha nincs akkor 0 -> genderek
        };
        this.getBody = (layer) => {
            const body = layer.findOne('.body');
            const gender = this.getGender(layer);
            return body.attrs.src.includes('dnoi') && gender === 1 || body.attrs.src.includes('dffi') && gender === 0 ? 1 : 0; //ha dagadt noi||dagadt ferfi benne van akkor 1 ha nem akkor 0 -> body typeok
        };
        this.getColor = (layer) => {
            const body = layer.findOne('.body');
            return body.attrs.src.includes('vilagos') ? 'vilagos' : body.attrs.src.includes('kozepsotet') ? 'kozepsotet' : 'sotet';
        };
        this.getGroupBySrc = (src) => __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                let reqUrl = `${window.location.origin}/creator/asset/${btoa(src)}`;
                fetch(reqUrl).then((res) => __awaiter(this, void 0, void 0, function* () {
                    let resJson = yield res.json();
                    if (resJson !== null)
                        resolve(resJson[0].group);
                    else
                        resolve(-1);
                }));
            });
        });
        this.canvasGetDefaultAttributes = (assetGroup, char) => {
            if (assetGroup === undefined)
                return null;
            let defaultAttribute = [];
            let defAttr = this.attributes[char].default.find(x => x.assetGroup === assetGroup);
            if (defAttr !== undefined)
                defaultAttribute.push(defAttr);
            if (assetGroup === 'shoes' || assetGroup === 'arms' || assetGroup === 'hands') {
                let attr_1 = this.attributes[char].default.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-1`);
                let attr_2 = this.attributes[char].default.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-2`);
                if (attr_1 && attr_2) {
                    defaultAttribute = [];
                    defaultAttribute.push(attr_1);
                    defaultAttribute.push(attr_2);
                }
            }
            return defaultAttribute.length === 0 ? null : defaultAttribute;
        };
        this.canvasGetCustomAttributes = (assetGroup, groupId, char) => {
            if (assetGroup === undefined || groupId === -1)
                return null;
            let attribute = [];
            let defAttr = this.attributes[char].custom.find(x => x.assetGroup === assetGroup && x.groupId === groupId);
            if (defAttr !== undefined)
                attribute.push(defAttr);
            if (assetGroup === 'shoes' || assetGroup === 'arms' || assetGroup === 'hands') {
                let attr_1 = this.attributes[char].custom.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-1` && x.groupId === groupId);
                let attr_2 = this.attributes[char].custom.find(x => x.assetGroup === assetGroup && x.attrs.name === `${assetGroup}-2` && x.groupId === groupId);
                if (attr_1 && attr_2) {
                    attribute = [];
                    attribute.push(attr_1);
                    attribute.push(attr_2);
                }
            }
            return attribute.length === 0 ? null : attribute;
        };
        this.replaceAssets = (characterLayers, pageData) => {
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const layers = this.stage.children.length - 1;
                for (let i = 0; i < layers; i++) {
                    for (let y = 0; y < this.loadable.length; y++) {
                        const asset = this.stage.children[i].findOne('.' + this.loadable[y]);
                        if (asset !== undefined) {
                            const layer_name = (_a = this.stage.children[i]) === null || _a === void 0 ? void 0 : _a.attrs.name;
                            if (layer_name !== undefined) {
                                const char = layer_name.includes('character_1') ? 0 : 1;
                                if (layer_name.includes('character_1') || layer_name.includes('character_2')) {
                                    const character_layer = characterLayers[char];
                                    const search_asset = character_layer.findOne('.' + this.loadable[y]) || character_layer.findOne('.bottom');
                                    let src = search_asset.attrs.src;
                                    if (asset.attrs.name === 'body') {
                                        src = src.replace('labakkal', 'labnelkul');
                                    }
                                    if (pageData.characters[char][asset.attrs.name === 'arms-1' ? 'armType_l' : 'armType_r'] === 'bnt') {
                                        src = src.replace('egyenes', 'hajlitott');
                                    }
                                    else if (pageData.characters[char][asset.attrs.name === 'arms-1' ? 'armType_l' : 'armType_r'] === 'bnt-1') {
                                        src = src.replace('egyenes', 'hajlitott2');
                                    }
                                    else if (pageData.characters[char][asset.attrs.name === 'arms-1' ? 'armType_l' : 'armType_r'] === 'bnt-2') {
                                        src = src.replace('egyenes', 'hajlitott3');
                                    }
                                    if (asset.attrs.name === 'mouth' || asset.attrs.name === 'eyes') {
                                        src = pageData.characters[char][asset.attrs.name] !== "" ? window.location.origin + '/' + pageData.characters[char][asset.attrs.name] : src;
                                    }
                                    if (pageData.characters[char].legType !== 0) {
                                        if (search_asset.attrs.name === 'bottom') {
                                            const pantSrcs = Manage.getNewUrlForLeg(search_asset.attrs.src, pageData.characters[char].legType);
                                            src = pantSrcs[0];
                                            if (pageData.characters[char].legType === 3 && asset.attrs.name === 'dance') {
                                                src = pantSrcs[1];
                                            }
                                        }
                                        if (search_asset.attrs.name === 'shoes-1' || search_asset.attrs.name === 'shoes-2') {
                                            src = Manage.getNewUrlForShoe([search_asset.attrs.src, search_asset.attrs.src], pageData.characters[char].legType)[search_asset.attrs.name === 'shoes-1' ? 0 : 1];
                                        }
                                    }
                                    if (!navigator.userAgent.includes('Chrome')) {
                                        yield Creator.getModifiedImageFrom(src).then(res => {
                                            src = res;
                                        });
                                    }
                                    let imageObj = new Image();
                                    imageObj.onload = function () {
                                        asset.attrs.src = src;
                                        asset.image(imageObj);
                                        if (asset.attrs.name === 'hip') {
                                            asset.setX(search_asset.attrs.x);
                                            asset.setY(search_asset.attrs.y);
                                            asset.setScale({ x: search_asset.attrs.scaleX, y: search_asset.attrs.scaleY });
                                        }
                                        if (asset.attrs.name === 'bottom' && pageData.characters[char].legType === 0) {
                                            asset.setX(search_asset.attrs.x);
                                            asset.setY(search_asset.attrs.y);
                                            asset.setScale({ x: search_asset.attrs.scaleX, y: search_asset.attrs.scaleY });
                                        }
                                        if (asset.attrs.name === 'top') {
                                            asset.setX(search_asset.attrs.x);
                                            asset.setY(search_asset.attrs.y);
                                            asset.setScale({ x: search_asset.attrs.scaleX, y: search_asset.attrs.scaleY });
                                        }
                                        if (asset.attrs.name === 'body') {
                                            asset.setX(1);
                                            asset.setY(0);
                                            asset.setScale({ x: 1.15, y: 1.15 });
                                        }
                                        asset.setX(asset.attrs.x);
                                        asset.setY(asset.attrs.y);
                                        asset.setScale({ x: asset.attrs.scaleX, y: asset.attrs.scaleY });
                                        if (asset.attrs.rotation !== undefined && asset.attrs.rotation !== null) {
                                            asset.rotation(asset.attrs.rotation);
                                        }
                                    };
                                    imageObj.src = src;
                                }
                            }
                        }
                    }
                }
                resolve('replaced');
            }));
        };
        this.createCharacterPreview = (canvas_id, initialData) => {
            if (initialData.canvas) {
                this.stage = Konva.Node.create(initialData.canvas, canvas_id);
            }
        };
        this.removeLoader = (canvasId) => {
            const container = $('#' + canvasId);
            container.removeClass('hide');
            $(container).siblings('.loader').addClass('hide');
        };
        this.insertUserCharacter = (charactersData, userCharacters) => __awaiter(this, void 0, void 0, function* () {
            this.stage.findOne('.character_1').destroy();
            this.stage.findOne('.character_2').destroy();
            charactersData.forEach((data, i) => {
                //console.log('insertUserCharacter > charactersData.forEach > data');
                //console.log(data);
                //console.log('end insertUserCharacter > charactersData.forEach > data');
                /*const iHateCarreerProgrammers = [ 'arms-1', 'arms-2', 'hands-1', 'hands-2' ];
                iHateCarreerProgrammers.forEach(x => {
                    if (!data.visible.includes(x))
                        data.visible.push(x);
                });*/ // kaletaa nem tud programozni...
                userCharacters[i].setAttrs({
                    name: data.name,
                    x: data.x !== -1 ? data.x : 50,
                    y: data.y !== -1 ? data.y : 50,
                    scaleX: data.scaleX !== -1 ? data.scaleX : 0.5,
                    scaleY: data.scaleY !== -1 ? data.scaleY : 0.5,
                    rotation: data.rotation !== -1 ? data.rotation : 1,
                    draggable: false,
                });
                this.loadable.forEach(l => {
                    const bodyPart = userCharacters[i].findOne('.' + l);
                    if (bodyPart !== undefined) {
                        if (data.visible.includes(l))
                            bodyPart.show();
                        else
                            bodyPart.hide();
                    }
                });
            });
            //console.log('insertUserCharacter > userCharacters');
            //console.log(userCharacters);
            //console.log('end insertUserCharacter > userCharacters');
            this.stage.add(userCharacters[0]);
            this.stage.add(userCharacters[1]);
            userCharacters[0].setZIndex(2);
            userCharacters[1].setZIndex(3);
            const char1Split = yield Manage.splitUpbody(userCharacters[0]);
            const char2Split = yield Manage.splitUpbody(userCharacters[1]);
            return Promise.all([char1Split, char2Split]);
        });
        this.replaceFaceExpression = (data) => {
            const layers = [Konva.Layer.create(JSON.parse(data.workspace[0].object)), Konva.Layer.create(JSON.parse(data.workspace[1].object))];
            const characters = [this.stage.findOne('.character_1'), this.stage.findOne('.character_2')];
            characters.forEach((ch, i) => {
                if (data.characters[i].mouth !== "") {
                    const mouth = characters[i].findOne('.mouth');
                    const newMouth = layers[i].findOne('.mouth');
                    mouth.setAttrs(newMouth.attrs);
                }
                if (data.characters[i].eyes !== "") {
                    const eyes = characters[i].findOne('.eyes');
                    const newEyes = layers[i].findOne('.eyes');
                    eyes.setAttrs(newEyes.attrs);
                }
            });
        };
        this.setArms = (charactersData, fixZ) => {
            const shortToSvgFragment = {
                'str': 'egyenes',
                'bnt': 'hajlitott',
                'bnt-1': 'hajlitott2',
                'bnt-2': 'hajlitott3',
            };
            const characters = [this.stage.findOne('.character_1'), this.stage.findOne('.character_2')];
            characters.forEach((ch, i) => {
                var _a;
                const arms_group_l = ch.findOne('.arms-group-1');
                const arms_group_r = ch.findOne('.arms-group-2');
                if (charactersData[i].armType_l !== 'str') {
                    const newUrl = (_a = arms_group_l.findOne('.arms-1')) === null || _a === void 0 ? void 0 : _a.attrs.src.replace('egyenes', shortToSvgFragment[charactersData[i].armType_l]);
                    Manage.addSVG(ch, 'arms-1', newUrl, -1, null, false, charactersData[i].armType_l);
                }
                if (charactersData[i].armType_r !== 'str') {
                    // NOTE: the result of findOne is null for whatever reason, but it's only null sometimes?????
                    const newUrl = arms_group_r.findOne('.arms-2').attrs.src.replace('egyenes', shortToSvgFragment[charactersData[i].armType_r]);
                    Manage.addSVG(ch, 'arms-2', newUrl, -1, null, false, charactersData[i].armType_r);
                }
                if (charactersData[i].arms[0] !== null)
                    arms_group_l.setAttrs(Object.assign(Object.assign({}, charactersData[i].arms[0]), { draggable: false }));
                if (charactersData[i].arms[1] !== null) {
                    arms_group_r.setAttrs(Object.assign(Object.assign({}, charactersData[i].arms[1]), { draggable: false }));
                }
                //hair z swap
                if (fixZ) {
                    const order = ["dress", "arms-group-1", "arms-group-2", "hairlayer"];
                    ch.children.sort((a, b) => {
                        return order.indexOf(a.attrs.name) - order.indexOf(b.attrs.name);
                    });
                }
            });
        };
        this.setLegs = (data) => {
            const characters = [this.stage.findOne('.character_1'), this.stage.findOne('.character_2')];
            data.forEach((ch, i) => {
                switch (ch.legType) {
                    case 0:
                        this.setCharacterStanding(characters[i]);
                        break;
                    case 1:
                        this.setCharacterSitting(characters[i]);
                        break;
                    case 2:
                        this.setCharacterRunning(characters[i]);
                        break;
                    case 3:
                        this.setCharacterDancing(characters[i]);
                        break;
                }
            });
        };
        this.setCharacterStanding = (layer) => __awaiter(this, void 0, void 0, function* () {
            const pants = layer.findOne('.bottom');
            const color = this.getColor(layer);
            const url = pants.attrs.src.replace('allo', 'futo');
            const gender = this.getGender(layer);
            const body = this.getBody(layer);
            const legs_url = `${window.location.origin}/images/foots/${body === 0 ? 'v' : 'd'}${gender === 0 ? 'ffi' : 'noi'}labak_${color}_egyenes.svg`;
            const leg_group = yield Manage.getGroupBySrc(window.location.origin + '/images/pants/dffinadrag_allo_fhr.svg');
            if (gender === 1 && url.includes('transparent')) {
                Manage.addSVG(layer, 'bottom', legs_url, leg_group, null, false, null, body, gender);
            }
        });
        this.setCharacterRunning = (layer) => __awaiter(this, void 0, void 0, function* () {
            const pants = layer.findOne('.bottom');
            const shoes = [layer.findOne('.shoes-1').attrs.src, layer.findOne('.shoes-2').attrs.src];
            const url = pants.attrs.src.replace('allo', 'futo');
            const gender = this.getGender(layer);
            const body = this.getBody(layer);
            const new_shoes = Manage.getNewUrlForShoe(shoes, 2);
            if (url.includes('transparent')) {
                const color = this.getColor(layer);
                const legs_url = `${window.location.origin}/images/foots/${body === 0 ? 'v' : 'd'}${gender === 0 ? 'ffi' : 'noi'}labak_${color}_futo.svg`;
                const dress_url = layer.findOne('.top').attrs.src.replace('allo', 'futo');
                const leg_group = yield Manage.getGroupBySrc(window.location.origin + (body === 0 ? '/images/pants/vnoinadrag_futo_fhr.svg' : '/images/pants/dnoinadrag_futo_fhr.svg'));
                Manage.addSVG(layer, 'bottom', legs_url, leg_group, null, false, null, body, gender, { x: 2, y: -3 });
                Manage.addSVG(layer, 'top', dress_url, -1, null, false, null, body, gender, { x: 70, y: -10 });
                layer.children[0].setZIndex(1);
                layer.children[1].setZIndex(1);
                Manage.addSVG(layer, 'shoes-1', new_shoes[0], -1, null, false, null, body, gender);
                Manage.addSVG(layer, 'shoes-2', new_shoes[1], -1, null, false, null, body, gender);
            }
            else {
                Manage.addSVG(layer, 'bottom', url, -1, null, false, null, body, gender);
                Manage.addSVG(layer, 'shoes-1', new_shoes[0], -1, null, false, null, body, gender);
                Manage.addSVG(layer, 'shoes-2', new_shoes[1], -1, null, false, null, body, gender);
            }
        });
        this.setCharacterSitting = (layer) => __awaiter(this, void 0, void 0, function* () {
            const pants = layer.findOne('.bottom');
            const shoes = [layer.findOne('.shoes-1').attrs.src, layer.findOne('.shoes-2').attrs.src];
            const url = pants.attrs.src.replace('allo', 'ulo');
            const gender = this.getGender(layer);
            const body = this.getBody(layer);
            const new_shoes = Manage.getNewUrlForShoe(shoes, 1);
            if (url.includes('transparent')) {
                const color = this.getColor(layer);
                const legs_url = `${window.location.origin}/images/foots/${body === 0 ? 'v' : 'd'}${gender === 0 ? 'ffi' : 'noi'}labak_${color}_ulo.svg`;
                const dress_url = layer.findOne('.top').attrs.src.replace('allo', 'ulo');
                const leg_group = yield Manage.getGroupBySrc(window.location.origin + (body === 0 ? '/images/pants/vnoinadrag_ulo_fhr.svg' : '/images/pants/dnoinadrag_ulo_fhr.svg'));
                Manage.addSVG(layer, 'bottom', legs_url, leg_group, null, false, null, body, gender);
                Manage.addSVG(layer, 'top', dress_url, -1, null, false, null, body, gender, { x: 70, y: -10 });
                layer.children[0].setZIndex(1);
                layer.children[1].setZIndex(1);
                Manage.addSVG(layer, 'shoes-1', new_shoes[0], -1, null, true, null, body, gender);
                Manage.addSVG(layer, 'shoes-2', new_shoes[1], -1, null, true, null, body, gender);
            }
            else {
                Manage.addSVG(layer, 'bottom', url, -1, null, false, null, body, gender);
                Manage.addSVG(layer, 'shoes-1', new_shoes[0], -1, null, true, null, body, gender);
                Manage.addSVG(layer, 'shoes-2', new_shoes[1], -1, null, true, null, body, gender);
            }
        });
        this.setCharacterDancing = (layer) => __awaiter(this, void 0, void 0, function* () {
            const pants = layer.findOne('.bottom');
            const shoes = [layer.findOne('.shoes-1').attrs.src, layer.findOne('.shoes-2').attrs.src];
            const urls = Manage.getNewUrlForLeg(pants.attrs.src, 3);
            const gender = this.getGender(layer);
            const body = this.getBody(layer);
            const new_shoes = Manage.getNewUrlForShoe(shoes, 3);
            if (urls[0].includes('transparent') || urls[1].includes('transparent')) {
                const color = this.getColor(layer);
                const leg_1_url = `${window.location.origin}/images/foots/${body === 0 ? 'v' : 'd'}${gender === 0 ? 'ffi' : 'noi'}lab_${color}_futo_hajlitott.svg`;
                const leg_2_url = `${window.location.origin}/images/foots/${body === 0 ? 'v' : 'd'}${gender === 0 ? 'ffi' : 'noi'}lab_${color}_egyenes_egy.svg`;
                const leg_1_group = yield Manage.getGroupBySrc(window.location.origin + (body === 0 ? '/images/pants/vnoinadrag_futo_hajlitott_fhr.svg' : '/images/pants/dnoinadrag_futo_hajlitott_fhr.svg'));
                const leg_2_group = yield Manage.getGroupBySrc(window.location.origin + (body === 0 ? '/images/pants/vnoinadrag_allo_fkt_egy.svg' : '/images/pants/dnoinadrag_allo_fkt_egy.svg'));
                Manage.addSVG(layer, 'bottom', leg_1_url, leg_1_group, null, false, null, body, gender);
                Manage.addSVG(layer, 'dance', leg_2_url, leg_2_group, null, false, null, body, gender).then(res => {
                    const b_layer = layer.children.filter(c => c.find('.bottom').length !== 0);
                    // here comes the gzsolt fix 
                    if (!body) {
                        res.attrs.x += 36;
                        if (!gender)
                            res.attrs.x -= 5;
                        res.attrs.y -= 2;
                    }
                    else {
                        res.attrs.x += 41;
                        res.attrs.y -= 2;
                    }
                    b_layer[0].add(res);
                });
                Manage.addSVG(layer, 'shoes-1', new_shoes[0], -1, null, false, null, body, gender, { x: 4.5, y: -3 });
                Manage.addSVG(layer, 'shoes-2', new_shoes[1], -1, null, false, null, body, gender);
            }
            else {
                Manage.addSVG(layer, 'bottom', urls[0], -1, null, false, null, body, gender);
                Manage.addSVG(layer, 'shoes-1', new_shoes[0], -1, null, false, null, body, gender, { x: -3, y: body === 0 ? 6 : 9 });
                Manage.addSVG(layer, 'shoes-2', new_shoes[1], -1, null, false, null, body, gender);
                Manage.addSVG(layer, 'dance', urls[1], -1, null, false, null, body, gender).then(res => {
                    const b_layer = layer.children.filter(c => c.find('.bottom').length !== 0);
                    // here comes the gzsolt fix 
                    if (!body) {
                        res.attrs.x += 36;
                        if (!gender)
                            res.attrs.x -= 5;
                        res.attrs.y -= 2;
                    }
                    else {
                        res.attrs.x += 41;
                        res.attrs.y -= 2;
                    }
                    b_layer[0].add(res);
                });
            }
        });
        this.createCover = (canvas_id, initialData, bg, pageObject = null, fixZ = false) => __awaiter(this, void 0, void 0, function* () {
            var _b, _c;
            if (initialData[0] !== undefined && initialData[1] !== undefined) {
                const charaterLayers = [Konva.Node.create(initialData[0]), Konva.Node.create(initialData[1])];
                if (pageObject !== null && pageObject[1] !== null) {
                    this.stage = Konva.Node.create(pageObject[1], canvas_id);
                    if (this.adminEdit !== true) {
                        this.stage.setAttrs({
                            width: this.width,
                            height: this.height,
                            scaleY: 1,
                            scaleX: 1,
                        });
                        this.replaceAssets(charaterLayers, pageObject[0]).then(res => {
                            var _a;
                            const container = $('#' + canvas_id);
                            container.removeClass('hide');
                            $(container).siblings('.loader').addClass('hide');
                            if (this.adminEdit)
                                this.manage = new Manage(false, this.stage, pageObject[0]);
                            (_a = this.loadEnfFC) === null || _a === void 0 ? void 0 : _a.call(this);
                        });
                        this.insertUserCharacter(pageObject[0].characters, charaterLayers).then((res) => __awaiter(this, void 0, void 0, function* () {
                            yield this.setArms(pageObject[0].characters, fixZ);
                            yield this.replaceFaceExpression(pageObject[0]);
                            yield this.setLegs(pageObject[0].characters);
                            yield this.loadAssets(0, null, false);
                            yield this.loadAssets(1, null, true);
                            yield this.loadAssets(2, null, false);
                            yield this.loadAssets(3, null, false);
                            yield this.loadAssets(4, null, true);
                            yield this.loadAssets(5, null, true);
                        }));
                        //this.manage = new Manage(false, this.stage, pageObject[0]);
                        this.removeLoader(canvas_id);
                        (_b = this.loadEnfFC) === null || _b === void 0 ? void 0 : _b.call(this);
                    }
                    else {
                        yield this.loadAssets(0, null, false);
                        yield this.loadAssets(1, null, true);
                        yield this.loadAssets(2, null, false);
                        yield this.loadAssets(3, null, false);
                        yield this.loadAssets(4, null, true);
                        //await this.loadAssets(5, null, true);
                        this.manage = new Manage(false, this.stage, pageObject[0]);
                        this.removeLoader(canvas_id);
                        (_c = this.loadEnfFC) === null || _c === void 0 ? void 0 : _c.call(this);
                    }
                }
                else {
                    this.stage = new Konva.Stage({
                        container: canvas_id,
                        width: this.width,
                        height: this.height,
                    });
                    this.stage.add(charaterLayers[0]);
                    this.stage.add(charaterLayers[1]);
                    this.stage.children[0].setScale({ x: 0.5, y: 0.5 });
                    this.stage.children[1].setScale({ x: 0.5, y: 0.5 });
                    yield this.loadAssets(0);
                    yield this.loadAssets(1);
                    if (bg !== null) {
                        this.setBackground(bg).then(() => {
                            var _a;
                            const container = $('#' + canvas_id);
                            container.removeClass('hide');
                            $(container).siblings('.loader').addClass('hide');
                            if (this.adminEdit)
                                this.manage = new Manage(false, this.stage);
                            (_a = this.loadEnfFC) === null || _a === void 0 ? void 0 : _a.call(this);
                        });
                    }
                }
                if (this.adminEdit) {
                    $('#editbtn').removeClass('hidden');
                    $('#editbtn').on('click', () => this.toggleAdminEdit(true));
                    $('#savebtn').on('click', () => this.toggleAdminEdit(false));
                }
            }
        });
        this.loadPage = (data, container) => __awaiter(this, void 0, void 0, function* () {
            if (data !== undefined && data !== null) {
                this.stage = Konva.Node.create(data, container);
                yield Promise.all([
                    this.loadAssets(0, 'bg'),
                    this.loadAssets(1, null, true),
                    this.loadAssets(2),
                    this.loadAssets(3),
                    this.loadAssets(4, null, true),
                    this.loadAssets(5)
                ]);
                return;
            }
        });
        this.getCharacter = () => {
            return this.stage.children[0];
        };
        this.getStage = () => {
            return this.stage;
        };
        this.setBackground = (url) => __awaiter(this, void 0, void 0, function* () {
            const layer = new Konva.Layer();
            return new Promise((resolve) => __awaiter(this, void 0, void 0, function* () {
                let imagesrc = null;
                if (!navigator.userAgent.includes('Chrome')) {
                    yield Creator.getModifiedImageFrom(url).then(res => {
                        imagesrc = res;
                    });
                }
                Konva.Image.fromURL(url, (imageNode) => {
                    layer.add(imageNode);
                    imageNode.setAttrs({
                        width: this.width,
                        height: this.height,
                        src: imagesrc === null ? url : imagesrc,
                        name: 'bg'
                    });
                    imageNode.cache();
                    this.stage.add(layer);
                    this.stage.children[2].setZIndex(0);
                    //this.stage.children[1].setAttrs({ x: 200, y: 200 }); // MOZGATOM A KARAKTERT XD
                    resolve(layer);
                });
            }));
        });
        this.toggleAdminEdit = (toggle) => {
            if (toggle) {
                this.stage.setAttrs({
                    width: 698,
                    height: 798,
                    scaleY: 2,
                    scaleX: 2,
                });
                $('#editbtn').addClass('d-none');
                $('#savebtn').removeClass('d-none');
                $('.editCard > .card-body').removeClass('d-none');
                $('#edittext').html('SzerkesztÃ©s folyamatban!');
                $('#edittext').addClass('text-danger');
                if (this.manage !== null)
                    this.manage.setActive(true, this.stage);
            }
            else {
                $('.editCard > .card-body').addClass('d-none');
                $('#editbtn').removeClass('d-none');
                $('#savebtn').addClass('d-none');
                $('#edittext').html('SzerkesztÃ©s megkezdÃ©se a gombra kattintva!');
                $('#edittext').removeClass('text-danger');
                if (this.manage !== null)
                    this.manage.setActive(false, null);
            }
        };
        this.editMode = (toggle, container, scale, floatingViewer, onSavePressed) => {
            var _a, _b, _c, _d;
            if (toggle) {
                this.editClone = this.stage.clone();
                (_a = this.editClone) === null || _a === void 0 ? void 0 : _a.setContainer(container);
                if (scale) {
                    (_b = this.editClone) === null || _b === void 0 ? void 0 : _b.setAttrs(Object.assign({}, scale));
                }
                const text = this.addEditableText(container);
                (_c = this.editClone) === null || _c === void 0 ? void 0 : _c.add(text);
                text.moveToTop();
                $('.select_cover_btn').on('click', () => {
                    if (floatingViewer !== null)
                        if (this.editClone) {
                            this.addPageToFloatingViewer(this.editClone, floatingViewer, true);
                            onSavePressed === null || onSavePressed === void 0 ? void 0 : onSavePressed();
                        }
                });
            }
            else {
                (_d = this.editClone) === null || _d === void 0 ? void 0 : _d.destroy();
                this.editClone = null;
            }
        };
        this.getClone = (type) => {
            if (type === 'page') {
                const clone = this.stage.clone();
                return clone;
            }
        };
        this.addPageToFloatingViewer = (stage, floatingViewer, isCover = false) => {
            floatingViewer.addPage(stage, isCover);
        };
        this.addEditableText = (canvas_id) => {
            let layer = new Konva.Layer();
            var textNode = new Konva.Text({
                text: '',
                x: 50,
                y: 80,
                fontSize: 20,
                draggable: true,
                width: 200,
            });
            layer.add(textNode);
            // make the transformer
            var tr = new Konva.Transformer({
                nodes: [textNode],
                enabledAnchors: ['middle-left', 'middle-right'],
                boundBoxFunc: function (oldBox, newBox) {
                    newBox.width = Math.max(30, newBox.width);
                    return newBox;
                },
            });
            textNode.on('transform', function () {
                // reset scale, so only with is changing by transformer
                textNode.setAttrs({
                    width: textNode.width() * textNode.scaleX(),
                    scaleX: 1,
                });
            });
            layer.add(tr);
            tr.hide();
            const inputDiv = $('#' + canvas_id).siblings()[0];
            const input = $(inputDiv).children()[0];
            $(input).val("");
            if (input !== undefined) {
                $(input).on('change keyup', (e) => this.checkTitle(e, e.target.value, textNode, tr));
            }
            return layer;
        };
        this.checkTitle = (e = null, text, textNode, tr) => {
            if (text !== " " && text !== "" && text !== undefined && text !== null && text.length < __classPrivateFieldGet(this, _Canvas_MAX_CHARACTER_TITLE, "f")) {
                textNode.text(text);
                tr.show();
            }
            else {
                if ((text === null || text === void 0 ? void 0 : text.length) === 0) {
                    textNode.text(text);
                    tr.hide();
                }
                if (e !== null)
                    e === null || e === void 0 ? void 0 : e.preventDefault();
            }
        };
        this.width = 350;
        this.height = ((_a = $(`#${canvas_id}`)[0]) === null || _a === void 0 ? void 0 : _a.clientHeight) || 400;
        this.editClone = null;
        this.adminEdit = adminEdit;
        this.loadEnfFC = loadEndFC;
        __classPrivateFieldSet(this, _Canvas_MAX_CHARACTER_TITLE, 32, "f");
        this.manage = null;
        this.pageObject = pageObject;
        if (initialData !== null && initialData !== undefined) {
            switch (type) {
                case "cover":
                    //XDXDDXDDXDDDXDXDXDXDXDXD
                    let bringToFrontObjects = ["top", "arms-1", "arms-2"];
                    if (fixZ) {
                        let data = initialData;
                        for (let i = 0; i < data.length; i++) {
                            let layerWithoutfixed = [];
                            let bringToFrontObjectsFrom = data[i].children[0].children;
                            for (let j = 0; j < bringToFrontObjectsFrom.length; j++) {
                                let fixed = false;
                                for (let k = 0; k < bringToFrontObjects.length; k++) {
                                    if (bringToFrontObjectsFrom[j].attrs.name === bringToFrontObjects[k]) {
                                        if (bringToFrontObjectsFrom[j].attrs.name == "top" && bringToFrontObjectsFrom[j].attrs.src.includes("noi")) {
                                            bringToFrontObjectsFrom[j].attrs.x += bringToFrontObjectsFrom[j].attrs.src.includes("noi") ? 70 : 65;
                                            bringToFrontObjectsFrom[j].attrs.y -= 10;
                                            data[i].children[data[i].children.length - 1].children[data[i].children[data[i].children.length - 1].children.length] = bringToFrontObjectsFrom[j];
                                            fixed = true;
                                        }
                                    }
                                }
                                if (!fixed) {
                                    layerWithoutfixed.push(bringToFrontObjectsFrom[j]);
                                }
                            }
                            if (data[i].children[3].attrs.name === undefined) {
                                data[i].children[3].attrs.name = "dress";
                            }
                            data[i].children[0].children = layerWithoutfixed;
                            bringToFrontObjectsFrom = data[i].children[2].children;
                            layerWithoutfixed = [];
                            for (let j = 0; j < bringToFrontObjectsFrom.length; j++) {
                                let fixed = false;
                                if (bringToFrontObjectsFrom[j].attrs.name === "hair") {
                                    data[i].children[4] = {
                                        attrs: {
                                            x: 127.5,
                                            y: 0,
                                        },
                                        className: "Group",
                                        children: [bringToFrontObjectsFrom[j]]
                                    };
                                    fixed = true;
                                }
                                if (!fixed) {
                                    layerWithoutfixed.push(bringToFrontObjectsFrom[j]);
                                }
                            }
                            data[i].children[2].children = layerWithoutfixed;
                            data[i].children[4].attrs.name = "hairlayer";
                        }
                    }
                    this.createCover(canvas_id, initialData, bg, this.pageObject, fixZ);
                    break;
                case "character":
                    this.createCharacterPreview(canvas_id, initialData);
                    this.loadAssets();
                    break;
                case "preview":
                    this.loadPage(initialData, canvas_id).then((res) => {
                        if (this.loadEnfFC !== null)
                            this.loadEnfFC();
                    });
                    break;
            }
        }
        else {
            this.stage = new Konva.Stage({
                container: canvas_id,
                width: this.width,
                height: this.height,
            });
            let layer = new Konva.Layer();
            var noCharacter = new Konva.Text({
                x: this.stage.width() / 2,
                y: this.stage.height() / 2,
                text: 'Nincs karakter!',
                fontSize: 20,
                fontFamily: 'Calibri',
                fill: 'red',
            });
            noCharacter.offsetX(noCharacter.width() / 2);
            layer.add(noCharacter);
            this.stage.add(layer);
        }
    }
}
_Canvas_MAX_CHARACTER_TITLE = new WeakMap();
Canvas.canvasIdMap = {
    "head": "heads",
    "hair": "hairs",
    "nose": "noses",
    "eyes": "eyes",
    "mouth": "mouths",
    "eyebrows": "eyebrows",
    "body": "bodies",
    "beard": "beards",
    "shoes-1": "shoes",
    "shoes-2": "shoes",
    "arms-1": "arms",
    "arms-2": "arms",
    "bottom": "pants",
    "top": "dresses",
    "hands-1": "hands",
    "hands-2": "hands",
    "hip": "hip",
    "hairbg": "hairbg",
    "glasses": "glasses",
    "eyelashes-1": "eyelashes",
    "eyelashes-2": "eyelashes",
};
